import React from "react";
import { Drawer as AntDrawer, DrawerProps as AntDrawerProps } from "antd";

import "./drawer.scss";

const Drawer = ({ children, ...props }: AntDrawerProps) => {
  return (
    <AntDrawer className="drawer" closable={false} {...props}>
      {children}
    </AntDrawer>
  );
};

export default Drawer;
