import React, { FC, useEffect } from "react";
import { User as UserModel } from "models/user.model";
import { Popover, Tooltip } from "antd";
import UserList, { UserListProps } from "../UserList";
import { Add, User } from "shared/icons";
import useToggle from "shared/hooks/useToggle";
import "./addUser.scss";
import { UserRole } from "enums/userRole";

interface AddUserProps extends UserListProps {
  tooltipTitle?: string;
  selectedUser?: UserModel;
}

const AddUser: FC<AddUserProps> = ({
  selectedUser,
  onSelect,
  tooltipTitle,
  ...props
}) => {
  const { isToggled, toggle, toggleOff } = useToggle();
  const user: UserModel = JSON.parse(localStorage.getItem('user') ?? '{}');

  const handleSelect: UserListProps["onSelect"] = (user) => {
    onSelect(user);
    toggleOff();
  };

  const isUserSelected = !!selectedUser?.id;

  return (
    <div className="add-user">
      {user?.role === UserRole.VENDOR ?
        <div
          className={"add-user__button".concat(
            selectedUser?.id ? " add-user__button--selected" : ""
          )}
        >
          <span className="add-user__button__icon">
            <User className="icon--user-profile" />
            {!isUserSelected && <Add className="icon-primary icon--add" />}
          </span>
          {isUserSelected && <span>{selectedUser?.name}</span>}
        </div> :
        <Popover
          content={
            <UserList
              onSelect={handleSelect}
              selectedUser={selectedUser}
              {...props}
            />
          }
          onOpenChange={toggle}
          overlayClassName="add-user__popover"
          placement="bottomRight"
          open={isToggled}
          trigger="click"
          arrow={false}
        >
          <Tooltip title={!isUserSelected && tooltipTitle} placement="right">
            <div
              className={"add-user__button".concat(
                selectedUser?.id ? " add-user__button--selected" : ""
              )}
            >
              <span className="add-user__button__icon">
                <User className="icon--user-profile" />
                {!isUserSelected && <Add className="icon-primary icon--add" />}
              </span>
              {isUserSelected && <span>{selectedUser?.name}</span>}
            </div>
          </Tooltip>
        </Popover>}
    </div>
  );
};

export default AddUser;
