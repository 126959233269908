import React, { FC, useEffect } from "react";
import Label from "shared/components/Label";
import Dropdown, { DropdownWithFormikProps } from "shared/components/DropdownField";
import { useFormikContext } from "formik";
import { ProductParams } from "models/Product/product.model";
import set from "lodash.set";
import "./numberRangeInput.scss";
import Slider from "shared/components/Slider";
import { SliderMarks } from "antd/es/slider";
import get from "lodash.get";

interface NumberRangeInputProps {
  fromName: string;
  toName: string;
  label?: string;
  maxVal?: number;
  symbol: string;
  selectProps?: DropdownWithFormikProps<string>;
}

const NumberRangeInput: FC<NumberRangeInputProps> = ({
  fromName,
  toName,
  label,
  maxVal,
  symbol,
  selectProps
}: NumberRangeInputProps) => {
  const { values, setValues } = useFormikContext<ProductParams>();
  const marks: SliderMarks = {
    [get(values, fromName) ?? 0]: `${(symbol === '$') ? symbol : ''}${(get(values, fromName) ?? 0)?.toString()}`,
    [get(values, toName) ?? maxVal]: `${(symbol === '$') ? symbol : ''}${(get(values, toName) ?? maxVal)?.toString()}`
  };
  const handleFilterParamUpdate = (val: [number, number]) => {
    const updatedFromValues = set(values, fromName, val[0]);
    const updatedToValues = set(updatedFromValues, toName, val[1]);
    setValues({ ...updatedToValues });
  };
  return (
    <>
      <div className={`display-flex space-between ${symbol === '$' ? 'mg-b-2' : ''}`}>
        <Label label={label} />
        {(symbol !== '$' && selectProps) && <div>
          <Dropdown.Formik {...selectProps} popupMatchSelectWidth={false} />
        </div>}
      </div>
      <Slider range min={0} max={maxVal} marks={marks} onChange={(val) => { handleFilterParamUpdate(val); }} defaultValue={[get(values, fromName) ?? 0, get(values, toName) ?? maxVal]} tooltip={{ open: false }} />
    </>
  );
};

export default NumberRangeInput;
