import React, { useEffect } from "react";
import PlanService from "services/PlanService/plan.service";
import { useParams } from "react-router-dom";
import { PlanParams } from "shared/types/routeParams";
import { Skeleton } from "antd";
import Breadcrumb from "shared/components/Breadcrumb";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import PlanDetails from "./PlanDetails";

import "./planView.scss";

const PlanView = () => {
  const { prototypeId, planId } = useParams<PlanParams>();

  const { showPlan, plan, loading } = PlanService();

  useEffect(() => {
    if (!prototypeId || !planId) return;

    showPlan(prototypeId, planId);
  }, [prototypeId, planId]);

  return (
    <div className="plan-view">
      <div className="plan-view__container">
        <div className="plan-view__container__left">
          <Skeleton loading={loading} active paragraph>
            <Breadcrumb
              size="sm"
              items={[
                {
                  title: plan?.prototype?.title || "Prototype",
                  href: NavigationRoutes.PROTOTYPE,
                },
                {
                  title: plan?.title,
                },
              ]}
            />
            <PlanDetails plan={plan} />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default PlanView;
