import {
  serializable,
  object,
  getDefaultModelSchema,
  list,
  alias,
  primitive,
} from "serializr";
import { Plan } from "models/Plans/plans.model";
import { Coordinate } from "../Coordinate/coordinate.model";
import { Attachment } from "models/Attachment/attachment.model";
import { Space } from "models/Space/space.model";

export class Zone {
  constructor() {
    getDefaultModelSchema<Zone>(Zone)!.props.plan = object(Zone);
  }

  @serializable
  id?: string;

  @serializable
  title?: string;

  @serializable
  description?: string;

  @serializable(list(object(Attachment)))
  gallery?: Attachment[];

  @serializable(object(Coordinate))
  coordinate?: Coordinate;

  @serializable(alias("is_pinned", primitive()))
  isPinned?: boolean;

  @serializable(list(object(Space)))
  spaces?: Space[] = [];

  // @serializable(list(object(Plan)))
  plan?: Plan;

  @serializable(object(Attachment))
  diagram?: Attachment;

  @serializable(object(Attachment))
  image?: Attachment;
}
