import React from "react";
import Tabs from "shared/components/Tabs";
import tabItems from "./tabItems";

import "./configurations.scss";
import { Method } from "enums/method.enum";
import { userHaseAccess } from "utils";

const Configurations = () => {
 
  return (
    <div className="configurations">
      <Tabs items={tabItems?.filter(tabItem => userHaseAccess(tabItem.key, Method.VIEW))} tabPosition="top" />
    </div>
  );
};

export default Configurations;
