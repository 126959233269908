import { Formik, Form } from "formik";
import { User } from "models/user.model";
import React, { useEffect } from "react";
import FormHeader from "shared/components/FormHeader";
import "./userForm.scss";
import Input from "shared/components/Input";
import Dropdown from "shared/components/DropdownField";
import { UserRole } from "enums/userRole";
import Switch from "shared/components/Switch";
import { ProductStatus } from "enums/productStatus.enum";
import { useParams } from "react-router-dom";
import UserService from "services/UserService/user.service";
import { Spin, Tooltip } from "antd";
import { validationSchema } from "./UserValidationSchema";
import useRedirect from "shared/hooks/useRedirect";
import { Info } from "shared/icons";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

const UserForm = () => {
  const { redirectToUsers } = useRedirect();
  const handleSubmit = (values: User) => {
    values?.id ? editUser(values, values?.id) : createUser(values);
  };
  const { userId } = useParams();
  const { user, getUser, loading, deleteUser, createUser, editUser } =
    UserService();
  const initialValues: User = user || new User();
  useEffect(() => {
    userId && getUser(userId);
  }, []);

  const handleDeleteUser = async () => {
    userId && (await deleteUser(userId));
    redirectToUsers();
  };
  return (
    <div className="user-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(userId)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setValues }) => (
          <>
            <Form>
              {loading ? (
                <div className="display-flex flex-justify-center flex-align-center">
                  <Spin />
                </div>
              ) : (
                <>
                  
                    <FormHeader
                      redirectToOnDiscard={redirectToUsers}
                      handleDeleteItem={handleDeleteUser}
                      isSubmitting={loading}
                      isValid={true}
                      module={Module.USER_MANAGEMENT}
                      values={values}
                      deleteBtnLabel="Delete User"
                    />
                  
                  <div className="user-form__grid">
                    <Input.Formik
                      label="Username"
                      name="name"
                      placeholder="Enter"
                    />
                    <div>
                      <Dropdown.Formik
                        label="User Role"
                        name="role"
                        onChange={(val: UserRole) => {
                          setValues({ ...values, role: val });
                        }}
                        options={[
                          { label: "Admin", value: UserRole.ADMIN },
                          { label: "Editor", value: UserRole.EDITOR },
                          { label: "Vendor", value: UserRole.VENDOR },
                          { label: "Viewer", value: UserRole.VIEWER },
                        ]}
                        placeholder="Search & Select"
                      />
                    </div>
                    <Input.Formik
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter"
                    />
                    <Input.Formik
                      label="Phone"
                      name="phoneNumber"
                      placeholder="Enter"
                    />
                    <div>
                      <div className="password-label-container">
                        <p className="text-medium label password-label">
                          {values?.id ? "Update Password" : "Password"}
                        </p>
                        <Tooltip title="Password must contain at least 8 characters including 1 uppercase, 1 lowercase, 1 numeric and 1 special character">
                          <Info className="icon--info" />
                        </Tooltip>
                      </div>
                      <Input.Formik
                        name="password"
                        type="password"
                        placeholder="Enter"
                      />
                    </div>
                    <br />
                    {values?.id && (
                      <div className="status-switch">
                        <Switch.Formik
                          id="switch-status"
                          name="status"
                          label="Status"
                          unCheckedLabel="Inactive"
                          checkedLabel="Active"
                          checked={values?.status === ProductStatus.ACTIVE}
                          onChange={(checked) =>
                            setValues({
                              ...values,
                              status:
                                ProductStatus[checked ? "ACTIVE" : "IN_ACTIVE"],
                            })
                          }
                        />
                      </div>
                    )}
                    {values?.id && values?.role === UserRole.VENDOR && (
                      <Switch.Formik
                        id="switch__product-cost-edit"
                        name="productCostEdit"
                        label="Product Cost Edit"
                        unCheckedLabel="Disabled"
                        checkedLabel="Enabled"
                        checked={values?.productCostEdit}
                        onChange={(checked) =>
                          setValues({ ...values, productCostEdit: checked })
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
