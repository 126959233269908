import React, { FC } from "react";
import { Sustainable } from "shared/icons";
import SustainableProductBg from "assets/icons/multicolored/sustainable_product_bg.svg";

import "./sustainableCard.scss";

interface SustainableCardProps {
  description?: string;
}

const SustainableCard: FC<SustainableCardProps> = ({ description = "" }) => {
  return (
    <div className="sustainable-card">
      <div className="sustainable-card__title">
        <Sustainable className="icon--sustainable" />
        <span>Sustainable Product</span>
      </div>
      <div className="sustainable-card__description">{description}</div>
      <img className="sustainable-card__image" src={SustainableProductBg} />
    </div>
  );
};

export default SustainableCard;
