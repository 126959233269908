import React, { useEffect, useState } from "react";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import "./picklist.scss";
import PicklistTable from "./PicklistTable";
import { Back } from "shared/icons";
import PicklistService from "services/PicklistService";
import {
  AddToPicklistParams,
  Picklist as PicklistModel,
} from "models/Picklist";
import picklistColumns from "./PicklistTable/columns";
import PicklistTabButtons from "./PicklistTabButtons";

const PAGINATION_LIMIT = 20;

const Picklist = () => {
  const [selectedPicklist, setSelectedPicklist] = useState<PicklistModel>();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    picklists,
    fetchPicklists,
    createPicklist,
    picklistedVariants,
    fetchPicklistedVariants,
    updatePicklist,
    initialpicklist,
    setPicklists,
    clearPicklist,
    currentId,
    updateLoading,
    loading,
    removeProductVariantFromPicklist,
    addProductVariantToPicklists,
    exportPicklist,
    deletePicklist,
  } = PicklistService();
  useEffect(() => {
    fetchPicklists();
  }, []);
  useEffect(() => {
    setSelectedPicklist(selectedPicklist || picklists?.at(0));
  }, [picklists]);
  useEffect(() => {
    if (selectedPicklist) {
      fetchPicklistedVariants(selectedPicklist?.id ?? "");
    }
  }, [selectedPicklist]);

  const onGetPicklistsWithSearch = (search: string) => {
    if (search === "") {
      setPicklists(initialpicklist);
      return;
    }
    fetchPicklists(search);
  };

  const changeProductCount = async (variantId: string, count: number) => {
    const payload: AddToPicklistParams = {
      ids: [selectedPicklist?.id ?? ""],
      products: [
        {
          variantId: { id: variantId },
          quantity: count,
        },
      ],
    };
    addProductVariantToPicklists(payload, false);
  };

  const removeProduct = (id: string) => {
    removeProductVariantFromPicklist(selectedPicklist?.id ?? "", id).then(
      () => {
        fetchPicklistedVariants(selectedPicklist?.id ?? "");
      }
    );
  };

  const handlePagination = async (page: number) => {
    if (
      page > 0 &&
      picklistedVariants?.meta?.totalPages &&
      page <= picklistedVariants?.meta?.totalPages
    ) {
      await fetchPicklistedVariants(selectedPicklist?.id ?? "", {
        page,
        limit: PAGINATION_LIMIT,
      });
      setCurrentPage(page);
    }
  };

  return (
    <div className="picklist__container">
      <div className="picklist__header">
        <PicklistTabButtons
          picklists={picklists}
          selectedPicklist={selectedPicklist}
          setSelectedPicklist={setSelectedPicklist}
          createPicklist={createPicklist}
          updatePicklist={updatePicklist}
          onSearchPicklists={onGetPicklistsWithSearch}
          clearPicklist={clearPicklist}
          serviceLoading={loading}
          exportPicklist={exportPicklist}
          deletePicklist={deletePicklist}
          fetchPicklists={fetchPicklists}
        />
      </div>

      <div className={`picklist-main picklist-${selectedPicklist?.status}`}>
        <PicklistTable
          pagination={false}
          columns={picklistColumns({
            onRemove: removeProduct,
            onCountChange: changeProductCount,
            currentId: currentId,
            updateLoading: updateLoading,
          })}
          loading={loading}
          dataSource={picklistedVariants?.picklist?.products}
        />
      </div>

      {picklistedVariants ? (
        <div className="picklist-footer">
          <div className="doc-counter type-text">
            Showing {picklistedVariants?.meta?.totalDocs? currentPage * PAGINATION_LIMIT - PAGINATION_LIMIT + 1: 0}-
            {currentPage * PAGINATION_LIMIT <=
            (picklistedVariants?.meta?.totalDocs || 1)
              ? currentPage * PAGINATION_LIMIT
              : picklistedVariants?.meta?.totalDocs}{" "}
            of {picklistedVariants?.meta?.totalDocs}
          </div>
          <div className="page-action type-button">
            <Button
              className="back-button"
              type={ButtonType.DEFAULT}
              disabled={picklistedVariants.meta?.prevPage === null}
              onClick={() => {
                handlePagination(picklistedVariants.meta?.prevPage ?? 1);
              }}
            >
              <Back />
            </Button>
            <Button
              className="next-button"
              type={ButtonType.PRIMARY}
              disabled={picklistedVariants.meta?.nextPage === null}
              onClick={() => {
                handlePagination(picklistedVariants.meta?.nextPage ?? 1);
              }}
            >
              Next
              <Back className="invert--hr" />
            </Button>
          </div>
          <div className="page-counter type-text">
            Page {picklistedVariants.meta?.totalPages? currentPage:0} of {picklistedVariants.meta?.totalPages}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Picklist;
