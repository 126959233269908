import React, { useEffect } from 'react'
import './userRoleManagement.scss'
import Accordion from 'shared/components/Accordion';
import { useUserRoles } from 'context/UserRolesContext';
import { Skeleton, Table } from 'antd';

const UserRoleManagement = () => {
  const { userRoles, getUserRoles, loading } = useUserRoles();
  useEffect(()=>{
    getUserRoles?.();
},[]);
  return (
  <Skeleton loading={loading&&userRoles?.length===0}>
    <div className='user-role-container'>
      <Accordion items = {userRoles}>
      </Accordion >
    </div>
    </Skeleton>
  )
}

export default UserRoleManagement
