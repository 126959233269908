import React from "react";
import { Space } from "models/Space/space.model";
import FolderHeader from "shared/components/FolderHeader";
import AddItemIcon from "shared/components/AddItemIcon";
import FolderView from "shared/components/FolderView";

import "./spaceList.scss";
import useRedirect from "shared/hooks/useRedirect";
import { useParams } from "react-router-dom";
import { ZoneParams } from "shared/types/routeParams";

interface SpaceListProps {
  activeSpace?: Space;
  onSelect: (space: Space) => void;
  spaces: Space[];
}

const SpaceList = ({ spaces, onSelect, activeSpace }: SpaceListProps) => {
  const { redirectToAddSpaceForm } = useRedirect();

  const { prototypeId, planId, zoneId } = useParams<ZoneParams>();

  const handleAddSpace = () => {
    if (!prototypeId || !planId || !zoneId) return;

    redirectToAddSpaceForm(prototypeId, planId, zoneId);
  };

  return (
    <div className="space-list">
      <FolderHeader
        title="SPACE"
        extraContent={
          <AddItemIcon
            onAdd={handleAddSpace}
            tooltipOptions={{ title: "Add Space" }}
          />
        }
      />
      <div className="space-list__main">
        <FolderView
          items={spaces?.map((space) => ({
            label: space?.title,
            value: space,
          }))}
          onClick={onSelect}
          activeItem={activeSpace}
          hideNext
        />
      </div>
    </div>
  );
};

export default SpaceList;
