import React, { FC } from "react";
import { Form, Formik, FormikConfig, useField, useFormikContext } from "formik";
import { Variant } from "models/Variant/variant.model";
import AddVariant from "../../../../AddProduct/StepFour/AddVariant";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Product } from "models/Product/product.model";
import Button from "shared/components/Button";
import useToggle from "shared/hooks/useToggle";
import { ButtonType } from "enums/buttonType.enum";
import { Popover } from "antd";
import { v4 as uuidv4 } from "uuid";
import { Edit } from "shared/icons";

import "./addVariantPopover.scss";
import { useVariantMeta } from "context/MetaContext";

interface AddVariantPopoverProps {
  subCategories: SubCategory[];
  variant?: Variant;
}

const AddVariantPopover: FC<AddVariantPopoverProps> = ({
  subCategories,
  variant = Object.assign(new Variant(), { id: undefined }),
}) => {
  const { values: product } = useFormikContext<Product>();

  const { colors, materials, finishes } = useVariantMeta();

  const [{ value: variants }, , { setValue }] = useField<Variant[]>("variants");

  const { isToggled, toggleOff, setToggle } = useToggle();

  const color = colors?.find(({ id }) => variant?.color?.id === id)?.name;

  const material = materials?.find(
    ({ id }) => variant?.material?.id === id
  )?.name;

  const finish = finishes?.find(({ id }) => variant?.finish?.id === id)?.name;

  const caster = variant?.isCaster
    ? variant?.isCaster === "no"
      ? "Without Caster"
      : "With Caster"
    : "";

  const title = [
    color,
    material,
    finish,
    caster,
    variant?.colorTemperature,
    variant?.lightType,
  ]?.filter(Boolean);

  const isUpdate = !!variant?.id;

  const handleAddVariant: FormikConfig<{ variant: Variant }>["onSubmit"] = ({
    variant,
  }) => {
    if (isUpdate)
      setValue(
        variants?.map((existingVariant) =>
          existingVariant?.id === variant?.id ? variant : existingVariant
        )
      );
    else
      setValue([
        ...variants,
        Object.assign(variant, {
          id: uuidv4(),
        }),
      ]);
    toggleOff();
  };

  return (
    <Popover
      arrow={false}
      content={
        <Formik initialValues={{ variant }} onSubmit={handleAddVariant}>
          {({ values }) => (
            <Form>
              <div className="add-variant-popover">
                <div className="add-variant-popover__header">
                  <span className="text-capitalize">variant details</span>
                  <div className="add-variant-popover__header__end">
                    <Button onClick={toggleOff} type={ButtonType.TEXT}>
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !values?.variant?.color || !values?.variant?.material
                      }
                      htmlType="submit"
                      type={ButtonType.LINK}
                    >
                      {isUpdate ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
                <hr />
                <AddVariant
                  name="variant"
                  product={product}
                  subCategories={subCategories}
                />
              </div>
            </Form>
          )}
        </Formik>
      }
      destroyTooltipOnHide
      onOpenChange={setToggle}
      open={isToggled}
      overlayClassName="add-variant-overlay"
      placement="bottomRight"
      trigger="click"
    >
      {isUpdate ? (
        <div className="update-variant-title">
          {<>
            {(title?.length === 0)?<Button type={ButtonType.LINK}>+ Add Variant</Button>:<>
            {title?.map((name) => (
              <span className="update-variant-title__item" key={name}>
                {name}
              </span>
            ))}
            <Edit className="icon icon--edit tw-w-8 tw-h-8" />
            </>}
          </>
          } 
        </div>
      ) : (
        <Button type={ButtonType.LINK}>+ Add Variant</Button>
      )}
    </Popover>
  );
};

export default AddVariantPopover;