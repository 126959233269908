import { Spin } from "antd";
import React from "react";
import './spinner.scss';

interface Props {
    loading: boolean;
}

const Spinner: React.FC<Props> = ({ loading, children }) => {
    return <>
        {
            loading ?
                <div className='loader-spinner'><Spin /></div> :
                <>{children}</>
        }
    </>;
};

export default Spinner;