import * as Yup from  "yup";

export const validationSchema = (userId ?: string) => Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid")
    .required("E-mail is required"),
  password: userId
    ? Yup.string().min(8, "Password has to be longer than 8 characters").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Enter a strong password')
    : Yup.string().min(8, "Password has to be longer than 8 characters").required("Password is required").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,'Enter a strong password'),
  name: Yup.string().required('Name is required'),
  role: Yup.string().required('Role is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
});
