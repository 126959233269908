import React from "react";
import { useField } from "formik";
import { Product } from "models/Product/product.model";
import { Attachment as AttachmentModel } from "models/Attachment/attachment.model";
import UploadInput from "shared/components/UploadInput";
import AttachmentPreview from "shared/components/AttachmentPreview";

import "./productAttachments.scss";

const ProductAttachments = () => {
  const [{ value: attachments }, , { setValue: setAttachments }] =
    useField<Product["attachments"]>("attachments");

  const [
    { value: threeDimensionModel },
    ,
    { setValue: setThreeDimensionModel },
  ] = useField<Product["threeDimensionModel"]>("threeDimensionModel");

  const supported3DModels = [
    ".stl",
    ".obj",
    ".fbx",
    ".rfa",
    ".dwg",
    ".zip",
    ".skp",
  ];

  const handlePdfDelete = (attachment: AttachmentModel) =>
    setAttachments(attachments?.filter(({ id }) => id !== attachment?.id));

  return (
    <div className="product-attachments">
      {threeDimensionModel?.id ? (
        <AttachmentPreview
          type="delete"
          attachment={threeDimensionModel}
          onClick={() => setThreeDimensionModel(undefined)}
        />
      ) : (
        <UploadInput.Formik
          name="threeDimensionModel"
          title="Upload 3D model"
          note="Supported formats: STL, OBJ, FBX, RFA, SKP, DWG, ZIP and file size < 50MB"
          hideLoaderOnComplete
          primaryTheme
          options={{
            restrictions: {
              allowedFileTypes: supported3DModels,
              maxFileSize: 50 * 1024 * 1024, // 50 MB File Size
            },
          }}
        />
      )}
      {attachments?.map((attachment) => (
        <AttachmentPreview
          key={attachment?.id}
          type="delete"
          attachment={attachment}
          onClick={handlePdfDelete}
        />
      ))}
      {attachments?.length < 10 && (
        <UploadInput.Formik
          name="attachments"
          title="Upload PDF files"
          note="File size < 10MB"
          hideLoaderOnComplete
          primaryTheme
          options={{
            restrictions: {
              maxNumberOfFiles: 10,
              allowedFileTypes: [".pdf"],
              maxFileSize: 10 * 1024 * 1024, // 10MB File Size
            },
          }}
        />
      )}
    </div>
  );
};

export default ProductAttachments;