import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { NavigationRoutes } from "../../../routes/routeConstants/appRoutes";
import RestrictAccess from "../RestrictedAccess";
import { Role } from "../../../enums/role.enum";

interface AuthenticationProps {
  allowedRoles?: Role[];
  user?: { role: Role };
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const Authentication = (props: AuthenticationProps) => {
    const { allowedRoles } = props;
    const { authenticated } = AuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      if (!authenticated && location.pathname !== NavigationRoutes.LOGIN) {
        return navigate(NavigationRoutes.LOGIN);
      }
    }, [props]);

    if (allowedRoles?.length && props?.user?.role) {
      const { user } = props;
      return allowedRoles.includes(user.role) ? children : <RestrictAccess />;
    }
    return children;
  };

  return <Authentication />;
};

export const isAuthenticated = (component: JSX.Element) => {
  return RequireAuth({ children: component });
};

export default isAuthenticated;
