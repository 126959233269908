import { getMetricValidationSchema } from "models/Metric/metric.validation";
import * as Yup from "yup";

const distanceMetricValidation = getMetricValidationSchema("distance");

const massMetricValidation = getMetricValidationSchema("mass");

export const measurementValidationSchema = Yup.object().shape({
  height: distanceMetricValidation,
  length: distanceMetricValidation,
  weight: massMetricValidation,
  width: distanceMetricValidation,
});
