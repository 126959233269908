import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { ReactComponent as Filter } from "assets/icons/solid/filter.svg";
import ProductsFilter from "../ProductsFilter";
import { ProductParams } from "models/Product/product.model";
import variables from "styles/variables";
import FilterDrawer from "shared/components/FilterDrawer";
import { Form, Formik, FormikConfig } from "formik";
import { ProductFiltersContext } from "context/ProductFilterContext";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { Color } from "models/Color/color.model";
import { Material } from "models/Material/material.model";
import { Country } from "models/Country/country.model";
import { useMeta } from "services/useMetaService/useMeta.service";
import { VariantMetaContext } from "context/MetaContext";
import { LocationContext } from "context/LocationContext";
import "./productsHeaderExtra.scss";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import { useFilterToggle } from "context/FilterToggleContext";
import useRedirect from "shared/hooks/useRedirect";

interface ProductsHeaderExtraProps {
  filterMeta?: {categories:Category[], subCategories:SubCategory[], types:Type[] }
  filters?: ProductParams;
  onFilterUpdate?: Dispatch<SetStateAction<ProductParams>>;
  redirectOnCLose: boolean
}

const { GREY_2 } = variables;

const ProductsHeaderExtra: FC<ProductsHeaderExtraProps> = ({
  filters,
  onFilterUpdate,
  filterMeta,
  redirectOnCLose,
}) => {
  
  const {redirectToDashBoardListing} = useRedirect();
  const {categories,subCategories,types} = (filterMeta)? filterMeta: {categories: undefined,subCategories: undefined,types: undefined};
  const {toggleFilterOff, handleFilterOn, filterIsToggled} = useFilterToggle();
  const { fetchMeta: fetchColors, meta: colors } = useMeta({
    key: "colors",
    url: ApiRoutes.COLORS,
    serializingClass: Color,
  });

  const { fetchMeta: fetchMaterials, meta: materials } = useMeta({
    key: "materials",
    url: ApiRoutes.MATERIALS,
    serializingClass: Material,
  });

  const { fetchMeta: fetchCountries, meta: countries } = useMeta({
    key: "countries",
    url: ApiRoutes.COUNTRIES,
    serializingClass: Country,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchColors();
    fetchMaterials();
    fetchCountries();
  }, []);

  const handleSubmit: FormikConfig<ProductParams>["onSubmit"] = (params) => {
    const {sustainable, ...newParams} = params;
    navigate({
                                        pathname: location.pathname,
                                        hash: location.hash,
                                        search: QueryString.stringify(sustainable? params : newParams, {
                                          skipNulls: true,
                                        }),}),
    onFilterUpdate?.({ ...params, page: 1 });
    toggleFilterOff?.();
  };

  const handleClear = () => {
    onFilterUpdate?.(new ProductParams());
    toggleFilterOff?.();
    redirectOnCLose && redirectToDashBoardListing();
  }; 

  return (
    <>
      <div className="product-header-extra">
        <div className="filter-container" onClick={()=>{handleFilterOn?.('categories')}}>
          <Filter
            className="cursor-pointer icon"
            width={14}
            height={14}
            fill={GREY_2}
          />
          <div className="filter-container__text">Filters</div>
        </div>
      </div>
      {(categories && subCategories && types )
        ?
        
        <VariantMetaContext.Provider
          value={{
            colors,
            materials,
          }}
        >
          <LocationContext.Provider
            value={{
              countries,
            }}
          >
            <Formik
              enableReinitialize
              initialValues={filters??new ProductParams()}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <FilterDrawer
                    open={filterIsToggled}
                    onClose={()=>{toggleFilterOff?.()}}
                    onClear={handleClear}
                    onFilter={handleSubmit}
                  >
                    <ProductsFilter />
                  </FilterDrawer>
                </Form>
              )}
            </Formik>
          </LocationContext.Provider>
        </VariantMetaContext.Provider>
      :<></>
      }
    </>
  );
};

export default ProductsHeaderExtra;
