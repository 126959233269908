import React from "react";
import { Variant } from "models/Variant/variant.model";
import Table from "shared/components/Table";
import getVariantColumns from "./columns";
import { EditableColumnType } from "shared/types/editableColumn.type";
import { useField } from "formik";
import { SubCategory } from "models/SubCategory/subCategory.model";
import AddVariantPopover from "./AddVariantPopover";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";

import "./multipleVariant.scss";

interface MultipleVariantProps {
  subCategories: SubCategory[];
}

const MultipleVariant = ({ subCategories }: MultipleVariantProps) => {
  const [{ value: variants }, , { setValue }] = useField<Variant[]>("variants");

  const columns = getVariantColumns({ subCategories });
  const handleSave: EditableColumnType<Variant>["handleSave"] = (
    variant,
    dataIndex
  ) => {
    const isCountryChange = Array.isArray(dataIndex)
      ? dataIndex?.join(".") === "location.country.id"
      : false;

    const provinceResettedVariant = isCountryChange
      ? set(cloneDeep(variant), "location.province.id", undefined)
      : variant;

    setValue(
      variants?.map((existingVariant) =>
        existingVariant?.id === variant?.id
          ? provinceResettedVariant
          : existingVariant
      )
    );
  };

  const editableColumns = columns?.map<EditableColumnType<Variant>>(
    (column) => {
      if (!column.editable) return column;

      return {
        ...column,
        onCell: (variant) => ({
          ...(column?.onCell?.(variant) || {}),
          dataIndex: column.dataIndex,
          editable: column.editable,
          handleSave,
          inputType: column?.inputType,
          options: column.options,
          record: variant,
          title: "",
        }),
      };
    }
  );

  return (
    <div className="multiple-variant">
      <Table
        bordered
        columns={editableColumns}
        dataSource={variants}
        pagination={false}
      />
      {(variants?.length === 0 || (variants?.length === 1 && !(variants[0].material)))
        ? <></>
        : <div className="multiple-variant__footer">
          <AddVariantPopover subCategories={subCategories} />
        </div>
      }
    </div>
  );
};

export default MultipleVariant;
