import React, { useRef } from "react";
import './groupedPin.scss'
import {
  ReactZoomPanPinchContext,
  useControls,
  useTransformEffect,
} from "react-zoom-pan-pinch";


interface GroupedPinProps {
  dataset: number[][];
  clusterIndex: number;
  clusterLength: number;
}

const GroupedPin = ({
  clusterLength,
}: GroupedPinProps) => {
  const ref = useRef<HTMLElement>(null);

  const { zoomToElement } = useControls();

  const [instance, setInstance] = React.useState<ReactZoomPanPinchContext>();

  useTransformEffect((ref) => {
    setInstance(ref.instance);
  });

  return (
    <span
      ref={ref}
      className="grouped-pin"
      onClick={() => {
        if (!ref.current) return;

        zoomToElement(ref.current, (instance?.transformState?.scale || 1) + 1);
      }}
    >
      {clusterLength}
    </span>
  );
};

export default GroupedPin;
