import { serializable } from "serializr";
import { CurrencyUnit } from "enums/currencyUnit.enum";

class CostUnit {
  constructor(unit?: CurrencyUnit) {
    this.unit = unit;
  }

  @serializable
  unit?: CurrencyUnit;
}
export class CostPerItem extends CostUnit {
  constructor(unit?: CurrencyUnit) {
    super(unit);
  }

  @serializable
  id?: string;

  @serializable
  value?: number;

  @serializable
  type?: string;
}

export class CurrencyQueryParams {
  @serializable
  unit = CurrencyUnit.CAD;

  @serializable
  from = 0;

  @serializable
  to?: number;
}
