import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { PrototypeWithPlans } from "../../models/PrototypeWithPlans/prototypeWithPlans";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { PrototypeActivity } from "models/PrototypeActivity/prototypeActivity.model";
import { PaginationParams } from "models/PaginationParams";
import { PaginationMeta } from "models/PaginationMeta";

const PrototypeService = () => {
  const [loading, setLoading] = useState(false);

  const [prototypeLoading, setPrototypeLoading] = useState(false);

  const [prototypes, setPrototypes] = useState<PrototypeWithPlans[]>([]);

  const [prototype, setPrototype] = useState(new PrototypeWithPlans());

  const [activityLogs, setActivityLogs] = useState<PrototypeActivity[]>([]);
  const [activityLogsMeta, setActivityLogsMeta] = useState<PaginationMeta>();
  const [activityLogsLoading, setActivityLogsLoading] = useState(false);

  const fetchPrototypes = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.PROTOTYPES);
      const prototypes = deserialize(
        PrototypeWithPlans,
        response.data["prototypes"] as unknown[]
      );

      setPrototypes(prototypes);
      return prototypes;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to get Prototype List",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const createPrototype = async (prototype: PrototypeWithPlans) => {
    try {
      setPrototypeLoading(true);

      const { data } = await axiosInstance.post(ApiRoutes.PROTOTYPES, {
        prototype: serialize(PrototypeWithPlans, prototype),
      });

      const newPrototype = deserialize(PrototypeWithPlans, data["prototype"]);

      setPrototype(newPrototype);
      setPrototypes((prototypes) => [...prototypes, newPrototype]);

      Notification({
        message: `Created prototype ${newPrototype?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return newPrototype;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to create Prototype",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPrototypeLoading(false);
    }
  };

  const updatePrototype = async (prototype: PrototypeWithPlans) => {
    try {
      setPrototypeLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.PROTOTYPE, {
        id: String(prototype.id),
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        prototype: serialize(PrototypeWithPlans, prototype),
      });

      const updatedPrototype = deserialize(
        PrototypeWithPlans,
        data["prototype"]
      );

      setPrototype(updatedPrototype);
      setPrototypes((prototypes) =>
        prototypes?.map((prototype) =>
          prototype?.id === updatedPrototype?.id ? updatedPrototype : prototype
        )
      );

      Notification({
        message: `Updated prototype ${updatedPrototype?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return updatedPrototype;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Prototype",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPrototypeLoading(false);
    }
  };

  const deletePrototype = async (id: string) => {
    try {
      setPrototypeLoading(true);
      const API_URL = generatePath(ApiRoutes.PROTOTYPE, {
        id,
      });
      await axiosInstance.delete(API_URL);
      setPrototypes((prototypes) =>
        prototypes?.filter((prototype) => prototype?.id !== id)
      );
      Notification({
        message: `Prototype deleted`,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to delete Prototype",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPrototypeLoading(false);
    }
  };

  const showPrototype = async (id: string) => {
    try {
      setLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.PROTOTYPE, {
        id,
      });
      const { data } = await axiosInstance.get(ENDPOINT);

      const prototype = deserialize(PrototypeWithPlans, data["prototype"]);

      setPrototype(prototype);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to get Prototype",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const getActivityLogs = async (
    prototypeId: string,
    params?: PaginationParams
  ) => {
    try {
      setActivityLogsLoading(true);
      const ENDPOINT = generatePath(ApiRoutes.PROTOTYPE_ACTIVITY, {
        prototypeId,
      });
      const { data } = await axiosInstance.get(ENDPOINT, {
        params,
      });
      setActivityLogs(
        activityLogs?.concat(
          deserialize(PrototypeActivity, data["activity_log"] as unknown[])
        )
      );
      setActivityLogsMeta(deserialize(PaginationMeta, data["meta"]));
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to get Prototype",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setActivityLogsLoading(false);
    }
  };

  return {
    createPrototype,
    deletePrototype,
    fetchPrototypes,
    loading,
    prototype,
    prototypeLoading,
    prototypes,
    showPrototype,
    updatePrototype,
    activityLogs,
    getActivityLogs,
    activityLogsMeta,
    activityLogsLoading,
  };
};

export default PrototypeService;
