import React from "react";
import { Sustainable } from "shared/icons";
import "./sustainabilityFilter.scss";
import Switch from "shared/components/Switch";
import SustainabilityFilterBG from "assets/icons/multicolored/sustainable_product_filter_bg.svg";
import { useField } from "formik";

const SustainabilityFilter = () => {
    const [{ value: sustainable }] = useField<boolean>("sustainable");

    return (
        <div className={`sustainability-filter${sustainable ? "" : " unsustainable"}`}>
            <div className="product-filter-title sustainability-filter__title">
                Sustainability
            </div>
            <div className="text-capitalize sustainability-filter__content">
                <div className="sustainability-filter__content__icon"><Sustainable className="icon--sustainable" /></div>
                <div className="sustainability-filter__content__form">
                    <div className="sustainability-filter__content__form-label">Sustainable Product</div>
                    <Switch.Formik name="sustainable" />
                    <div className="sustainability-filter__content__form-desc">Toggle the button to show sustainabile products</div>
                </div>
            </div>
            <div className="sustainability-filter__illustration">
                <img src={SustainabilityFilterBG} alt="sustainability filter illustration" />
            </div>
        </div>
    );
};

export default SustainabilityFilter;