import React, { useState } from "react";
import { Product } from "models/Product/product.model";
import VariantDetails from "./VariantDetails";
import ListVariant from "./ListVariant";
import { AddToPicklist } from "shared/icons";
import "./viewProductContent.scss";
import { usePicklistToggle } from "context/PicklistToggleContext";
import { Variant } from "models/Variant/variant.model";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

interface ViewProductContentProps {
  product: Product;
}

const ViewProductContent = ({ product }: ViewProductContentProps) => {
  const { variants, name } = product;
  const {
    togglePicklistOn,
    setPicklistSelectedProduct,
    setPicklistSelectedvariant,
  } = usePicklistToggle();
  const [chosenVariant, setChosenVariant] = useState(variants?.[0]);

  const picklistDrawerOpen = (
    e: React.BaseSyntheticEvent,
    product: Product,
    variant: Variant
  ) => {
    e.stopPropagation();
    e.preventDefault();
    togglePicklistOn?.();
    setPicklistSelectedProduct?.(product);
    setPicklistSelectedvariant?.(variant);
  };

  const imageUrl = chosenVariant?.image?.url;

  return (
    <div className="view-product-content">
      <div className="view-product-content__start">
        <LazyLoadedImage
          src={imageUrl ?? ""}
          alt="Product Image"
          imageClassName="view-product-content__image"
        />
        {userHaseAccess(Module.PICKLIST, Method.EDIT) && (
          <div
            className="view-product-content__image-overlay"
            onClick={(e) => {
              picklistDrawerOpen(e, product, chosenVariant);
            }}
          >
            <AddToPicklist className="view-product-content__image-overlay_icon" />
            <div className="view-product-content__image-overlay_text">
              Add to Picklist
            </div>
          </div>
        )}
      </div>
      <div className="view-product-content__end">
        <div className="text-heavy view-product-content__end__title">
          {name}
        </div>
        <ListVariant
          activeVariant={chosenVariant}
          onClick={setChosenVariant}
          variants={product?.variants}
        />
        <VariantDetails variant={chosenVariant} product={product} />
      </div>
    </div>
  );
};

export default ViewProductContent;
