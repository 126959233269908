import { Form, Input } from "antd";
import React, { useContext, useMemo, useState } from "react";
import EditableTableContext from "context/EditableTableContext";
import { EditableColumnInputType } from "shared/types/editableColumn.type";
import { DefaultOptionType } from "antd/lib/select";
import get from "lodash.get";
import set from "lodash.set";
import { NamePath } from "antd/es/form/interface";
import cloneDeep from "lodash.clonedeep";
import Dropdown from "shared/components/DropdownField";
import InputNumber from "shared/components/InputNumber";

interface EditableCellProps<T> {
  children: React.ReactNode;
  dataIndex: keyof T & string;
  editable: boolean;
  handleClick?: (record: T) => void;
  handleSave: (record: T, dataIndex?: NamePath) => void;
  inputType: EditableColumnInputType;
  options: (record: T) => DefaultOptionType[];
  record: T;
  className?: string;
}

const EditableCell = <T extends object>({
  children,
  dataIndex,
  editable,
  handleClick,
  handleSave,
  inputType,
  options,
  record,
  className = "",
  ...props
}: EditableCellProps<T>) => {
  const isToggleInput = useMemo(() => inputType === "toggle", [inputType]);

  const [editing, setEditing] = useState(isToggleInput);

  const form = useContext(EditableTableContext);

  const toggleEdit = () => {
    if (isToggleInput) return;

    setEditing((editing) => !editing);
    form?.setFieldsValue(set({}, dataIndex, get(record, dataIndex)));
  };

  const save = () =>
    form?.validateFields().then((values) => {
      toggleEdit();
      const updatedValue = set(
        cloneDeep(record),
        dataIndex,
        get(values, dataIndex)
      );
      handleSave(updatedValue, dataIndex);
    });

  let childNode = children;

  if (editable) {
    let inputNode = null;

    switch (inputType) {
      case "wholeNumber":
      case "decimalNumber":
        inputNode = (
          <InputNumber
            autoFocus
            onBlur={save}
            onPressEnter={save}
            bordered={false}
            placeholder="Enter"
            type={inputType === "wholeNumber" ? "whole" : "decimal"}
          />
        );
        break;

      case "dropdown":
        inputNode = (
          <Dropdown
            autoFocus
            showAction={["focus"]}
            allowClear={false}
            bordered={false}
            onChange={save}
            options={options?.(record) || []}
          />
        );
        break;

      default:
        inputNode = (
          <Input
            autoFocus
            onPressEnter={save}
            onBlur={save}
            bordered={false}
            placeholder="Enter"
            autoComplete="input"
          />
        );
    }

    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ minHeight: 25 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  } else if (handleClick) {
    childNode = <div onClick={() => handleClick(record)}>{children}</div>;
  }

  return (
    <td
      className={className?.concat(editing ? " editable-cell" : "")}
      {...props}
    >
      {childNode}
    </td>
  );
};

export default EditableCell;
