import React from "react";
import {
  Radio as AntRadio,
  RadioGroupProps as AntRadioGroupProps,
  Space,
} from "antd";
import Label from "../Label";
import { ErrorMessage, Field, useField } from "formik";
import Error from "../Error";

import "./radioGroup.scss";

interface RadioGroupProps extends AntRadioGroupProps {
  label?: string;
}

interface RadioGroupWitFormikProps extends RadioGroupProps {
  name: string;
}

const RadioGroup = ({ label, ...props }: RadioGroupProps) => {
  return (
    <div className="radio-group">
      <Label label={label} />
      <AntRadio.Group {...props} />
    </div>
  );
};

const RadioGroupWithFormik = ({ name, ...props }: RadioGroupWitFormikProps) => {
  const [{ value }, { error }] = useField(name);

  return (
    <Space direction="vertical">
      <Field name={name} as={RadioGroup} value={value} {...props} />
      <ErrorMessage name={name}>
        {(msg) => <Error message={msg} />}
      </ErrorMessage>
    </Space>
  );
};

RadioGroup.Formik = RadioGroupWithFormik;

export default RadioGroup;
