import React, { FC } from "react";
import { Back, Delete } from "shared/icons";

import "./imagePreview.scss";

interface ImagePreviewProps {
  visible: boolean;
  url?: string;
  onDelete?: () => void;
  onClose?: () => void;
}

const ImagePreview: FC<ImagePreviewProps> = ({
  onClose,
  onDelete,
  url,
  visible,
}) => {
  return (
    <div className={"image-preview".concat(!visible ? " d-none" : "")}>
      <div className="image-preview__header">
        <div
          className="image-preview__header__back icon-container"
          onClick={onClose}
        >
          <Back className="icon--back" />
          <span>Back</span>
        </div>
        <div className="image-preview__header__delete" onClick={onDelete}>
          <Delete className="icon--delete" />
        </div>
      </div>
      <div className="image-preview__content">
        <img width={622} height={622} src={url} />
      </div>
    </div>
  );
};

export default ImagePreview;
