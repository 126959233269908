import React, { PropsWithChildren } from "react";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";
import { DrawerProps as AntDrawerProps } from "antd";
import Drawer from "../Drawer";
import { Delete } from "shared/icons";

import "./filterDrawer.scss";

interface FilterDrawerProps extends AntDrawerProps {
  onClear?: () => void;
  onClose?: () => void;
  onFilter?: () => void;
}

const FilterDrawer = ({
  onClear,
  onClose,
  onFilter,
  children,
  ...props
}: PropsWithChildren<FilterDrawerProps>) => {
  return (
    <Drawer
      rootClassName="filter-drawer-root"
      className="filter-drawer"
      {...props}
    >
      <header className="text-heavy filter-drawer__header">
        <span className="filter-drawer__header__title">Filter</span>
        <Button
          type={ButtonType.TEXT}
          icon={<Delete className="icon icon--delete" />}
          onClick={onClear}
        >
          clear filter
        </Button>
        <Button type={ButtonType.DEFAULT} onClick={onClose}>
          close
        </Button>
        <Button htmlType="submit" type={ButtonType.PRIMARY} onClick={onFilter}>
          apply
        </Button>
      </header>
      <main className="filter-drawer__main">{children}</main>
    </Drawer>
  );
};

export default FilterDrawer;
