import { serializable } from "serializr";

export class PaginationParams {
  @serializable
  page = 1;

  @serializable
  search? = "";

  @serializable
  limit = 20;
}
