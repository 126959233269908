import { alias, primitive, serializable } from "serializr";
import { TokenType } from "enums/tokenType";

export class Token {
  constructor(accessToken?: string, refreshToken?: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  @serializable
  type?: TokenType;

  @serializable(alias("access_token", primitive()))
  accessToken?: string;

  @serializable(alias("refresh_token", primitive()))
  refreshToken?: string;
}
