import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { useMeta } from "services/useMetaService/useMeta.service";

interface useConfigInputProps {
  onCategoryUpdate: (categoryId: string) => void;
  onSubCategoryUpdate: (subCategoryId: string) => void;
  onSubCategoriesUpdate?: (subCategories: SubCategory[]) => void;
  categoryId?: string;
  subCategoryId?: string;
}

export const useConfigInput = ({
  onCategoryUpdate,
  onSubCategoryUpdate,
  onSubCategoriesUpdate,
  categoryId,
  subCategoryId,
}: useConfigInputProps) => {
  const {
    fetchMeta: fetchCategories,
    meta: categories,
    loading: categoriesLoading,
  } = useMeta({
    key: "categories",
    url: ApiRoutes.CATEGORIES,
    serializingClass: Category,
  });

  const {
    fetchMeta: fetchSubCategories,
    meta: subCategories,
    loading: subCategoriesLoading,
  } = useMeta({
    key: "sub_categories",
    url: ApiRoutes.SUB_CATEGORIES,
    serializingClass: SubCategory,
  });

  const {
    fetchMeta: fetchTypes,
    meta: types,
    loading: typesLoading,
  } = useMeta({
    key: "types",
    url: ApiRoutes.TYPES,
    serializingClass: Type,
  });

  const populateSubCategories = async ({ id: categoryId } = new Category()) => {

    if (!categoryId) return;

    const subCategoryEndpoint = generatePath(ApiRoutes.SUB_CATEGORIES, {
      categoryId,
    });
    const subCategories = await fetchSubCategories({}, subCategoryEndpoint);
    onSubCategoriesUpdate?.(subCategories);
  };

  const populateTypes = async (
    { id: subCategoryId } = new SubCategory(),
    { id: categoryId } = new Category()
  ) => {
    if (!categoryId || !subCategoryId) return;

    const subCategoryEndpoint = generatePath(ApiRoutes.TYPES, {
      categoryId,
      subCategoryId,
    });
    await fetchTypes({}, subCategoryEndpoint);
  };

  const handleCategoryChange = async (categoryId: string) => {
    onCategoryUpdate(categoryId);
    await populateSubCategories({ id: categoryId });
  };

  const handleSubCategoryChange = async (
    subCategoryId: string,
    categoryId?: string
  ) => {
    onSubCategoryUpdate(subCategoryId);
    await populateTypes({ id: subCategoryId }, { id: categoryId });
  };

  useEffect(() => {
    fetchCategories();
    populateSubCategories({ id: categoryId });
    populateTypes({ id: subCategoryId }, { id: categoryId });
  }, []);

  return {
    categories,
    categoriesLoading,
    subCategories,
    subCategoriesLoading,
    types,
    typesLoading,
    handleCategoryChange,
    handleSubCategoryChange,
  };
};
