import { useCanAddPin } from 'context/CanAddPinContext';
import { useOpenedLayerVariant } from 'context/OpenedLayerVariantContext';
import { useSelectedLayerId } from 'context/SelectedLayerContext';
import { useSelectedPlan } from 'context/SelectedPlanContext';
import { DiagramView } from 'enums/diagramView';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import PrototypeService from 'services/PrototypeService/prototype.service';
import DualViewContainer from 'shared/components/PrototypeCombined'
import { addEventListenerEscCancelPinning, removeEventListenerEscCancelPinning } from 'utils';

const PrototypeViewCombined = () => {
  const params = useParams();
  const { loading, prototype, showPrototype} = PrototypeService(); 
  const { handleSetSelectedPlanId } = useSelectedPlan();
  
  const { handleSetAddPin } = useCanAddPin();
  const { updateSelectedLayerId } = useSelectedLayerId();
  const { setOpenedLayerVariantId } = useOpenedLayerVariant();
  useEffect(()=>{
    addEventListenerEscCancelPinning(handleSetAddPin)
    return () => {
      removeEventListenerEscCancelPinning(handleSetAddPin)
    }
  },[])

  useEffect(()=>{
    updateSelectedLayerId?.(undefined);
    setOpenedLayerVariantId?.(undefined)
  },[])

  const firstPlanId = prototype?.plans[0]?.id ?? null;

  useEffect(() => {
    if (!loading && firstPlanId) {
      handleSetSelectedPlanId?.(firstPlanId)
    }
  }, [loading, firstPlanId])

  return (
    <DualViewContainer viewType = {DiagramView.PROTOTYPE}  
                       loading={loading} 
                       subViewListingService={()=>{showPrototype(params?.prototypeId ?? '')}}
                       viewDetails={prototype}
                       subViewList={prototype?.plans}/>
  )
}

export default PrototypeViewCombined
