import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMeta } from "services/useMetaService/useMeta.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Category } from "models/Category/category.model";
import { Type } from "models/Type/type.model";
import FolderView from "shared/components/FolderView";
import FolderHeader from "shared/components/FolderHeader";
import EmptyPlaceholderSelect from "shared/components/EmptyPlaceholderSelect";
import { generatePath } from "react-router-dom";
import ConfigurationCategoryService from "services/ConfigurationCategoryService/configurationCategory.service";
import ConfigItemForm from "./ConfigItemForm";
import FolderItemExtra from "./FolderItemExtra";
import DeleteConfig from "./DeleteConfig";
import { CategoryConfiguration as CategoryConfigurationModel } from "models/CategoryConfiguration/categoryConfiguration.model";

import "./categoryConfiguration.scss";

const CategoryConfiguration = () => {
  const {
    addCategory,
    addSubCategory,
    addType,
    updateCategory,
    updateSubCategory,
    updateType,
  } = ConfigurationCategoryService();

  const {
    fetchMeta: fetchCategories,
    meta: categories,
    loading: categoriesLoading,
    setMeta: setCategories,
  } = useMeta({
    key: "categories",
    url: ApiRoutes.CATEGORIES,
    serializingClass: Category,
  });

  const {
    fetchMeta: fetchSubCategories,
    meta: subCategories,
    loading: subCategoriesLoading,
    setMeta: setSubCategories,
  } = useMeta({
    key: "sub_categories",
    url: ApiRoutes.SUB_CATEGORIES,
    serializingClass: SubCategory,
  });

  const {
    fetchMeta: fetchTypes,
    meta: types,
    loading: typesLoading,
    setMeta: setTypes,
  } = useMeta({
    key: "types",
    url: ApiRoutes.TYPES,
    serializingClass: Type,
  });

  const [chosenCategory, setChosenCategory] = useState<Category>();

  const [chosenSubCategory, setChosenSubCategory] = useState<SubCategory>();

  const [chosenType, setChosenType] = useState<Type>();

  const [config, setConfig] = useState<CategoryConfigurationModel>();

  const populateSubCategories = (category: Category) => {
    setChosenSubCategory(undefined);
    setChosenType(undefined);

    const subCategoryEndpoint = generatePath(ApiRoutes.SUB_CATEGORIES, {
      categoryId: category?.id,
    });
    setTypes([]);
    fetchSubCategories({}, subCategoryEndpoint);
  };

  const populateTypes = (
    subCategory: SubCategory,
    category = chosenCategory
  ) => {
    setChosenType(undefined);

    const typeEndpoint = generatePath(ApiRoutes.TYPES, {
      categoryId: category?.id,
      subCategoryId: subCategory?.id,
    });
    fetchTypes({}, typeEndpoint);
  };

  const handleCategoryClick = (category: Category) => {
    if (chosenCategory?.id === category?.id) return;

    setChosenCategory(category);
    populateSubCategories(category);
  };
  const handleSubCategoryClick = (subCategory: SubCategory) => {
    if (chosenSubCategory?.id === subCategory?.id) return;

    setChosenSubCategory(subCategory);
    populateTypes(subCategory);
  };

  const handleSubmittedValue = <T extends Category | SubCategory | Type>(
    setState: Dispatch<SetStateAction<T[]>>,
    isUpdate: boolean,
    value?: T
  ) => {
    if (!value) return;

    setState((values) =>
      isUpdate
        ? values?.map((existingValue) =>
            existingValue?.id === value?.id ? value : existingValue
          )
        : [...values, value]
    );
  };

  const handleDeletedValue = <T extends Category | SubCategory | Type>(
    setState: Dispatch<SetStateAction<T[]>>,
    setChosenState: Dispatch<SetStateAction<T | undefined>>,
    chosenValue: T
  ) => {
    setState((values) => values?.filter(({ id }) => id !== chosenValue?.id));
    setChosenState(undefined);
  };

  const handleDeleteClick = () => {
    setConfig({
      category: { id: chosenCategory?.id },
      subCategory: { id: chosenSubCategory?.id },
      type: { id: chosenType?.id },
    });
  };

  const handleDeleteSubmit = () => {
    if (chosenType?.id) handleDeletedValue(setTypes, setChosenType, chosenType);
    else if (chosenSubCategory?.id)
      handleDeletedValue(
        setSubCategories,
        setChosenSubCategory,
        chosenSubCategory
      );
    else if (chosenCategory?.id)
      handleDeletedValue(setCategories, setChosenCategory, chosenCategory);

    setConfig(undefined);
  };

  const handleCategorySubmit = async (category: Category) => {
    const isUpdate = !!category?.id;

    const updatedCategory = isUpdate ? await updateCategory(category) : await addCategory(category);
    handleSubmittedValue(setCategories, isUpdate, updatedCategory);
  };
  
  const handleSubCategorySubmit = async (subCategory: SubCategory, ) => {
    const isUpdate = !!subCategory?.id;

    if (!chosenCategory?.id) return;
    const updatedSubCategory = isUpdate ? await updateSubCategory(subCategory, chosenCategory.id) : await addSubCategory(subCategory, chosenCategory.id);
    handleSubmittedValue(setSubCategories, isUpdate, updatedSubCategory);
  };
  
  const handleTypeSubmit = async (type: Type,) => {
    const isUpdate = !!type?.id;

    if (!chosenSubCategory?.id || !chosenCategory?.id) return;
    const updatedType = isUpdate ? await updateType(type, chosenSubCategory.id, chosenCategory.id) : await addType(type, chosenSubCategory.id, chosenCategory.id);
    handleSubmittedValue(setTypes, isUpdate, updatedType);
  };
  

  const closeDeleteModal = () => setConfig(undefined);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <div className="category-configuration">
        <div className="category-configuration__item">
          <FolderHeader
            title="Category"
            extraContent={
              <ConfigItemForm
                item={new Category()}
                onSubmit={handleCategorySubmit}
                title="Category"
              />
            }
          />
          <FolderView
            loading={categoriesLoading}
            items={categories?.map((value) => ({
              label:
                !chosenSubCategory && chosenCategory?.id === value?.id ? (
                  <FolderItemExtra
                    item={value}
                    onDelete={handleDeleteClick}
                    onUpdate={handleCategorySubmit}
                    title={value?.name}
                  />
                ) : (
                  value?.name
                ),
              value,
            }))}
            onClick={handleCategoryClick}
            activeItem={chosenCategory}
            isActiveSecondary={!!chosenSubCategory?.id}
          />
        </div>
        <div className="category-configuration__item">
          <FolderHeader
            title="Sub Category"
            extraContent={
              chosenCategory?.id && (
                <ConfigItemForm
                  item={new SubCategory()}
                  onSubmit={handleSubCategorySubmit}
                  title="Sub Category"
                />
              )
            }
          />
          <FolderView
            loading={subCategoriesLoading}
            items={subCategories?.map((value) => ({
              label:
                !chosenType && chosenSubCategory?.id === value?.id ? (
                  <FolderItemExtra
                    item={value}
                    onDelete={handleDeleteClick}
                    onUpdate={handleSubCategorySubmit}
                    title={value?.name}
                  />
                ) : (
                  value?.name
                ),
              value,
            }))}
            onClick={handleSubCategoryClick}
            activeItem={chosenSubCategory}
            isActiveSecondary={!!chosenType?.id}
            emptyPlaceHolder={
              <EmptyPlaceholderSelect title="Select a category to view" />
            }
          />
        </div>
        <div className="category-configuration__item">
          <FolderHeader
            title="Type"
            extraContent={
              chosenSubCategory?.id && (
                <ConfigItemForm
                  item={new Type()}
                  onSubmit={handleTypeSubmit}
                  title="Type"
                />
              )
            }
          />
          <FolderView
            loading={typesLoading}
            items={types?.map((value) => ({
              label:
                chosenType?.id === value?.id ? (
                  <FolderItemExtra
                    item={value}
                    onDelete={handleDeleteClick}
                    onUpdate={handleTypeSubmit}
                    title={value?.name}
                  />
                ) : (
                  value?.name
                ),
              value,
            }))}
            onClick={setChosenType}
            activeItem={chosenType}
            hideNext
            emptyPlaceHolder={
              <EmptyPlaceholderSelect title="Select a sub category to view" />
            }
          />
        </div>
      </div>
      <DeleteConfig
        onDelete={handleDeleteSubmit}
        onCancel={closeDeleteModal}
        config={config}
      />
    </>
  );
};

export default CategoryConfiguration;
