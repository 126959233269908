import { Attachment } from "models/Attachment/attachment.model";
import { Prototype } from "models/Prototype/prototype.model";
import { Space } from "models/Space/space.model";
import { Zone } from "models/Zone/zone.model";
import {
  serializable,
  object,
  list,
  getDefaultModelSchema,
  alias,
} from "serializr";

export class Plan {
  constructor() {
    getDefaultModelSchema<Plan>(Plan)!.props.prototype = alias(
      "prototype",
      object(Prototype)
    );
  }
  @serializable
  id?: string;

  @serializable
  title?: string;

  @serializable
  description?: string;

  @serializable(object(Attachment))
  diagram?: Attachment;

  @serializable(object(Attachment))
  image?: Attachment;

  @serializable(list(object(Space)))
  spaces: Space[] = [];

  @serializable(list(object(Zone)))
  zones: Zone[] = [];

  prototype?: Prototype;
}
