import { Attachment } from "models/Attachment/attachment.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { alias, object, primitive, serializable } from "serializr";


export class Type {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(object(Attachment))
  image?: Attachment;

  @serializable(alias("sub_category", object(SubCategory)))
  subCategory?: SubCategory;

  static findById(types: Type[], id: string) {
    const type = types.find((type) => type.id === id);

    return type;
  }

  static groupBySubCategory(types: Type[] = []): Map<string, Type[]> {
    const groupedTypes = new Map<string, Type[]>();

    types.forEach((type) => {
      const subCategoryId = type?.subCategory?.id;

      if (!subCategoryId) return;

      groupedTypes.set(subCategoryId, [
        ...(groupedTypes.get(subCategoryId) || []),
        type,
      ]);
    });

    return groupedTypes;
  }
}
