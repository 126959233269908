import React, { PropsWithChildren } from "react";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";
import { DrawerProps as AntDrawerProps } from "antd";

import "./activityNotifications.scss";
import Drawer from "../Drawer";

interface ActivityNotificationProps extends AntDrawerProps {
  onClose?: () => void;
  onUpdate?: () => void;
}

const ActivityNotifications = ({
  onClose,
  onUpdate,
  children,
  ...props
}: PropsWithChildren<ActivityNotificationProps>) => {
  return (
    <Drawer
      rootClassName="activity-notifications-drawer-root"
      className="activity-notifications-drawer"
      {...props}
    >
      <header className="text-heavy activity-notifications-drawer__header">
      <span className="picklist-drawer__header__title">Activity Notifications</span>

        <Button type={ButtonType.DEFAULT} onClick={onClose}>
          close
        </Button>
        <Button htmlType="submit" type={ButtonType.PRIMARY} onClick={onUpdate}>
          update
        </Button>
      </header>
      <main className="activity-notifications-drawer__main">{children}</main>
    </Drawer>
  );
};

export default ActivityNotifications;
