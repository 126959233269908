import React, { useEffect, useState } from 'react';
import Table from 'shared/components/Table';
import GetColumns from './columns';
import ProductService from 'services/ProductService/product.service';
import './productsList.scss';
import SearchAndFilter from 'views/Products/SearchAndFilter';
import useRedirect from 'shared/hooks/useRedirect';
import { Back, DownArrow, RightArrow } from 'shared/icons';
import ExpandedRow from './ExpandedRow';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import LayerService from 'services/LayerService/layer.service';
import { useParams } from 'react-router-dom';
import { ButtonType } from "enums/buttonType.enum";
import Button from "shared/components/Button";
import { Form, Formik } from "formik";
import InputNumber from "shared/components/InputNumber";
import { ProductFiltersContext } from "context/ProductFilterContext";
import FilterToggleState from "context/FilterToggleContext";
import { useSelectedLayerId } from 'context/SelectedLayerContext';

const PAGINATION_LIMIT = 20;
interface ProductsListParams {
  replace?: boolean;
}

const ProductsList = ({ replace }: ProductsListParams) => {
  const {
    products,
    fetchProducts,
    loading,
    meta,
    filters,
    fetchFilters,
    params,
    setParams } = ProductService();
  const { loading: LayerLoading, addProductToLayer, replaceProductFromLayer } = LayerService();

  const handleSearch = (search = "", searchCategory: string) => {
    setParams({
      ...params,
      search,
      searchCategory
    });
  };

  useEffect(() => {
    fetchProducts(params);
  }, [params]);
  const { categories, subCategories, types } = filters;
  useEffect(() => {
    fetchFilters();
  }, []);

  // By default on expanding, multiple rows can remain expaneded
  // Use the below code to make it work like accordian, one one row expanded at a time
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { prototypeId, planId, spaceId, layerId, layerVariantId } = useParams();
  const { redirectToSpaceView } = useRedirect();

  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => { setSelectedVariant(layerVariantId); }, [layerVariantId]);

  const [selectedVariant, setSelectedVariant] = useState<string>();
  const { goBack } = useRedirect();
  const { layer, updateLayerDetails } = useSelectedLayerId();
  const handleMapProductToLayer = async () => {
    if (!selectedVariant) {
      Notification({
        message: "No Variant Selected",
        description: "Please select a variant for mapping",
        type: NotificationTypes.ERROR
      });
      return;
    }
    if (prototypeId && planId && spaceId && layerId) {
        if( layerVariantId ){
          const replacedProductLayer = await replaceProductFromLayer(prototypeId, planId, spaceId, layerId, layerVariantId, selectedVariant);
          if(replacedProductLayer && layer){
            const { products, ...layerWithoutProducts } = layer;
            const productsListAfterReplace = products ? [...products] : [];
            const replacedProductIndex = productsListAfterReplace?.findIndex((product)=> product?.id === replacedProductLayer?.id) ;
            productsListAfterReplace.splice(replacedProductIndex, 1, replacedProductLayer);
            updateLayerDetails?.({...layerWithoutProducts, products: productsListAfterReplace});
            goBack();
          }
        }
        else{
          const addedProductLayer =  await addProductToLayer(prototypeId, planId, spaceId, layerId, selectedVariant);
          if(addedProductLayer && layer){
            const { products, ...layerWithoutProducts } = layer;
            const updatedProductsList = products ? [...products] : [];
            updatedProductsList.push(addedProductLayer);
            updateLayerDetails?.({...layerWithoutProducts, products:updatedProductsList});
          }
      }
    }
  };

  const handlePagination = (page: number) => {
    fetchProducts({ ...params, page });
  };

  return <>
    <ProductFiltersContext.Provider
      value={{
        categories,
        subCategories,
        types,
      }}>
      <FilterToggleState>
        <div className='list-container'>
          <div
            className="icon-container list-container__back-icon"
            onClick={goBack}
          >
            <Back className="icon--back" />
            <span className="text-capitalize">back</span>
          </div>
          <div className='list-container__table'>
            {/* add 'key' field in data source so that on exapanding one row, all the rows do not expand */}
            <Table
              pagination={false}
              columns={GetColumns}
              dataSource={products?.map((product) => ({ ...product, key: product?.id }))}
              isMapProduct
              loading={loading}

              expandable={{
                expandedRowKeys: expandedRowKeys,
                expandRowByClick: true,
                expandedRowRender: (record) => <p style={{ margin: 0 }}><ExpandedRow variants={record?.variants} setSelectedVariant={setSelectedVariant} selectedVariant={selectedVariant} /></p>,
                onExpand: (expanded, { id }) => { id && setExpandedRowKeys(expanded ? [id] : []); },
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded
                    ? <span className='expand-row' onClick={e => onExpand(record, e)}><DownArrow className="icon icon--down-arrow" /></span>
                    : <span onClick={e => onExpand(record, e)}><RightArrow className="icon icon--right-arrow" /></span>
                  }}
              headerExtra={
                <SearchAndFilter filters={filters} params={params} setParams={setParams} onSearch={handleSearch} mapProductsToLayer={true} handleMapProductToLayer={handleMapProductToLayer} mappingLoading={LayerLoading} redirectOnCLose={false} />
              }
            />

          </div>

          {/* Product Pagination */}
          <div className="products-pagination">
            <div className="doc-counter type-text">
              {
                products.length !== 0 ?
                  <>
                    Showing {
                      (((meta?.page || 1) * PAGINATION_LIMIT) - PAGINATION_LIMIT + 1)
                    }-
                    {
                      (meta?.page || 1) * PAGINATION_LIMIT <= (meta?.totalDocs || 1) ? (meta?.page || 1) * PAGINATION_LIMIT : meta?.totalDocs
                    } of {meta?.totalDocs}
                  </> : <>Showing 0 of 0</>
              }
            </div>
            <div className="page-action type-button">
              <Button
                className="back-button"
                type={ButtonType.DEFAULT}
                disabled={meta?.prevPage === null}
                onClick={() => { handlePagination(meta?.prevPage ?? 1); }}
              ><Back /></Button>
              <Button
                className="next-button"
                type={ButtonType.PRIMARY}
                disabled={meta?.nextPage === null}
                onClick={() => { handlePagination(meta?.nextPage ?? 1); }}
              >Next<Back className="invert--hr" /></Button>
            </div>
            <div className="page-counter type-text">
              <Formik
                enableReinitialize
                initialValues={{ page: { value: meta?.page ?? 0 } }}
                onSubmit={({ page }) => { handlePagination(page.value); }}>
                {
                  ({ submitForm }) => (
                    <Form>
                      <InputNumber.Formik
                        name="page"
                        onPressEnter={(e) => { submitForm; }}
                        addonAfter={<>of {meta?.totalPages}</>}
                        addonBefore={<>Page</>}
                        keyboard
                        max={meta?.totalPages}
                        min={1}
                      ></InputNumber.Formik>
                    </Form>
                  )
                }
              </Formik>
            </div>
          </div>
        </div>
      </FilterToggleState>
    </ProductFiltersContext.Provider>
  </>;
};

export default ProductsList;
