import React, { FC } from "react";
import { RightArrow, LeftArrow } from "shared/icons";

import "./roundedArrow.scss";

interface RoundedArrowProps {
  isLeft?: boolean;
}

const RoundedArrow: FC<RoundedArrowProps> = ({ isLeft }) => {
  const ArrowComponent = isLeft ? LeftArrow : RightArrow;

  return (
    <div className="rounded-arrow">
      <ArrowComponent className="icon icon--right-arrow" />
    </div>
  );
};

export default RoundedArrow;
