import * as Yup from "yup";

export const positiveIntegerValidationSchema = Yup.object().shape({
    value: Yup.number().typeError('Must be a valid number').required('Value is required').integer('Must be an integer').min(1, 'Must be atleast 1')
});

export const addProductValidationSchema = Yup.object().shape({
    quantity: positiveIntegerValidationSchema,
    picklists: Yup.array().of(Yup.string()).required().min(1)
});

export const createPicklistValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').trim().strict(true).max(50, 'Name length must be less than or equal to 50 characters')
});