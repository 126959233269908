import React from "react";
import CheckboxGroup, {
  Checkbox,
  CheckboxGroupWithFormikProps,
  CheckboxWithFormikProps,
} from "shared/components/Checkbox";

interface CheckboxFiltersProps {
  title: string;
  isGroup?: boolean;
  checkboxProps: CheckboxGroupWithFormikProps | CheckboxWithFormikProps;
  groupByParent?: string;
  filterClassName?: string
}

const CheckboxFilters = ({
  title,
  checkboxProps,
  isGroup = true,
  filterClassName,
}: CheckboxFiltersProps) => {
  return (
    <div className={`checkbox-filters ${filterClassName}`}>
      <div className="product-filter-title checkbox-filters__title">
        {title}
      </div>
      <div className="text-capitalize checkbox-filters__content">
        {isGroup ? (
          <CheckboxGroup.Formik
            {...(checkboxProps as CheckboxGroupWithFormikProps)}
          />
        ) : (
          <Checkbox.Formik {...(checkboxProps as CheckboxWithFormikProps)} />
        )}
      </div>
    </div>
  );
};

export default CheckboxFilters;
