import React from "react";
import {
  Pagination as AntPagination,
  PaginationProps as AntPaginationProps,
} from "antd";
import { ReactComponent as RightArrow } from "assets/icons/solid/right-arrow.svg";
import { ReactComponent as LeftArrow } from "assets/icons/solid/left-arrow.svg";
import summarizePagination from "./renderPaginationTotal";

import "./pagination.scss";

const Pagination = ({ ...props }: AntPaginationProps) => (
  <div className="pagination">
    <AntPagination
      nextIcon={<RightArrow className="icon" />}
      showTotal={summarizePagination}
      prevIcon={<LeftArrow className="icon" />}
      showSizeChanger={false}
      {...props}
    />
  </div>
);

export default Pagination;
