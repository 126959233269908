import React from "react";
import { VariantField as VariantFieldModel } from "models/VariantField/variantField.model";
import { VariantFieldType } from "enums/variantFieldType.enum";
import Switch from "../Switch";
import Dropdown from "../DropdownField";
import RadioGroup from "../RadioGroup";

import "./variantField.scss";

interface VariantFieldProps {
  prefixName?: string;
  variant: VariantFieldModel;
}

const VariantField = ({
  prefixName,
  variant: { label, name, options = [], type },
}: VariantFieldProps) => {
  const getInputField = () => {
    const prefixedName = prefixName ? prefixName?.concat(`.${name}`) : name;

    const optionalLabel = label?.concat(" (", "Optional", ")");

    switch (type) {
      case VariantFieldType.SWITCH:
        return <Switch.Formik label={optionalLabel} name={prefixedName} />;
      case VariantFieldType.DROPDOWN:
        return (
          <Dropdown.Formik
            label={optionalLabel}
            placeholder={`Search & Select ${label}`}
            name={prefixedName}
            options={options}
          />
        );
      case VariantFieldType.RADIO:
        return (
          <RadioGroup.Formik
            label={optionalLabel}
            name={prefixedName}
            options={options}
          />
        );
    }
  };
  return <div className="variant-field">{getInputField()}</div>;
};

export default VariantField;
