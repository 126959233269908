import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy, { UppyOptions } from "@uppy/core";
import { getToken } from "interceptor/axiosInstance";
import { COMPANION_URL } from "routes/routeConstants/apiRoutes";
import { v4 as uuidv4 } from "uuid";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { AttachmentFormat } from "enums/attachmentFormat";

export default (options: UppyOptions = {}) =>
  new Uppy({
    id: uuidv4(),
    autoProceed: true,
    ...options,
  })
    .use(AwsS3Multipart, {
      companionUrl: COMPANION_URL,
      companionHeaders: {
        "uppy-auth-token": getToken(),
      },
    })
    .use(ThumbnailGenerator, {
      waitForThumbnailsBeforeUpload: true,
      thumbnailType: AttachmentFormat.JPEG,
      thumbnailHeight: 100,
      thumbnailWidth: 100,
      locale: {
        strings: {
          generatingThumbnails: "",
        },
      },
    });
