import { Skeleton, Spin, TableColumnType } from "antd";
import { Product } from "models/Picklist";
import React from "react";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import { Remove } from "shared/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { priceUnitTypesMap } from "shared/constants/products";

export interface PicklistVariantQuantityProps {
  id: string;
  quantity: number;
  updateLoading: boolean;
  currentId: string;
  onChange: (variantId: string, count: number) => Promise<void>;
}

interface PicklistVariantRemoteProps {
  variantId: string;
  onRemove: (productId: string) => void;
}
const antIcon = <LoadingOutlined spin />;

const ProductQuantityInput = ({
  id,
  quantity,
  onChange,
  currentId,
  updateLoading,
  ...props
}: PicklistVariantQuantityProps) => {
  const changeCount = (increase = true) => {
    increase ? onChange(id, 1) : onChange(id, -1);
  };
  return (
    <div>
      {updateLoading && currentId === id ? (
        <div className="product__quantity">
          {" "}
          <div className="value">{quantity}</div>
          <Spin indicator={antIcon} className="action"></Spin>{" "}
        </div>
      ) : (
        <div className="product__quantity">
          {" "}
          {userHaseAccess(Module.PICKLIST, Method.EDIT) && (
            <div
              className="action"
              onClick={() => {
                changeCount(false);
              }}
            >
              &#8722;
            </div>
          )}
          <div className="value">{quantity}</div>
          {userHaseAccess(Module.PICKLIST, Method.EDIT) && (
            <div
              className="action"
              onClick={() => {
                changeCount(true);
              }}
            >
              &#43;
            </div>
          )}{" "}
        </div>
      )}
    </div>
  );
};

const ProductRemoveButton = ({
  variantId,
  onRemove,
  ...props
}: PicklistVariantRemoteProps) => {
  const onProductRemove = (id: string) => {
    onRemove(id);
  };

  return userHaseAccess(Module.PICKLIST, Method.EDIT) ? (
    <div className="product-remove-button">
      <Remove
        className="action"
        onClick={() => {
          onProductRemove(variantId);
        }}
      />
    </div>
  ) : (
    <div></div>
  );
};

interface PicklistColumnsProps {
  onRemove: (productId: string) => void;
  updateLoading: boolean;
  currentId: string;
  onCountChange: (variantId: string, count: number) => Promise<void>;
}

const picklistColumns = ({
  onRemove,
  onCountChange,
  updateLoading,
  currentId,
}: PicklistColumnsProps) => {
  const columns: TableColumnType<Product>[] = [
    {
      title: "SKU",
      dataIndex: ["variantId", "product", "code"],
    },
    {
      title: "product name",
      dataIndex: ["variantId", "product", "name"],
      sorter: (a: Product, b: Product) => {
        const v1 = a.variantId?.product?.name || "";
        const v2 = b.variantId?.product?.name || "";
        return v1.localeCompare(v2);
      },
      render: (title: string, product: any) => {
        return (
          <div className="product__name" key={product.id}>
            <LazyLoadedImage
              src={product?.variantId?.image?.url}
              imageClassName="product__name-image"
              loaderClassName="product__name-image"
            />
            <span>{title}</span>
          </div>
        );
      },
    },
    {
      title: "variant name",
      dataIndex: ["variantId", "name"],
      sorter: (a: Product, b: Product) => {
        const v1 = a.variantId?.name || "";
        const v2 = b.variantId?.name || "";
        return v1.localeCompare(v2);
      },
    },
    {
      title: "Vendor",
      dataIndex: ["variantId", "product", "vendor", "name"],
      sorter: (a: Product, b: Product) => {
        const v1 = a.variantId?.product?.vendor?.name || "";
        const v2 = b.variantId?.product?.vendor?.name || "";
        return v1.localeCompare(v2);
      },
    },
    {
      title: "available count",
      dataIndex: ["variantId", "count"],
      sorter: (a: Product, b: Product) => {
        const v1 = a.variantId?.count || 0;
        const v2 = b.variantId?.count || 0;
        return v1 - v2;
      },
      render: (value: number) => <div className="text--center">{value}</div>,
    },
    {
      title: "lead time",
      dataIndex: ["variantId", "leadTime"],
      sorter: (a: Product, b: Product) => {
        const v1 = a.variantId?.leadTime || 0;
        const v2 = b.variantId?.leadTime || 0;
        return v1 - v2;
      },
      render: (value: number) => {
        return <span>{value} weeks</span>;
      },
    },
    {
      title: "contract sell price",
      dataIndex: ["variantId", "cost", "value"],
      render: (value: number) => {
        return <div className="text--right">${value.toFixed(2)}</div>;
      },
    },
    {
      title: "price type",
      dataIndex: ["variantId", "cost", "type"],
      render: (type?: string | null) => {
        return type && <div className="text--right">{priceUnitTypesMap[type]}</div>
      },
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      render: (value: number, product: any) => {
        return (
          <ProductQuantityInput
            id={product.variantId?.id}
            quantity={value}
            updateLoading={updateLoading}
            currentId={currentId}
            onChange={onCountChange}
          />
        );
      },
    },
    {
      title: "extended price",
      dataIndex: ["quantity"],
      render: (value: number, product: any) => {
        return (
          <div className="text--right">
            ${(value * product?.variantId?.cost?.value).toFixed(2)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: ["id"],
      render: (value: string) => {
        return <ProductRemoveButton variantId={value} onRemove={onRemove} />;
      },
    },
  ];

  return columns;
};

export default picklistColumns;
