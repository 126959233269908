import React from "react";
import { Variant } from "models/Variant/variant.model";
import { DefaultOptionType } from "antd/es/select";
import Label from "shared/components/Label";
import { Space } from "antd";
import { Product } from "models/Product/product.model";
import Sustainability from "../Sustainability";
import Attachments from "shared/components/Attachments";
import TruncateContent from "shared/components/TruncateContent";
import InventoryDetails from "./InventoryDetails";

import "./variantDetails.scss";
import DimensionAndCost from "./DimensionAndCost";
import Divider from "shared/components/Divider";
import ProductDescription from "../ProductDescription";

interface VariantDetailsProps {
  product: Product;
  variant: Variant;
}

const VariantDetails = ({ product, variant }: VariantDetailsProps) => {
  const cardDetails: DefaultOptionType[] = [
    {
      label: "Variant",
      value: variant?.name,
      id:1,
    },
    {
      label: "Color",
      value: variant?.color?.name,
      id:2,
    },
    {
      label: "Material",
      value: variant?.material?.name,
      id:3,
    },
    {
      label: "Finish",
      value: variant?.finish?.name,
      id:4,
    },
    {
      label: "Caster",
      value: variant?.isCaster ? "Yes" : "No",
      id:5,
    },
    {
      label: "Color Temperature",
      value: variant?.colorTemperature,
      id:6,
    },
    {
      label: "Light Type",
      value: variant?.lightType,
      id:7,
    },
  ]?.filter(({ value }) => Boolean(value));
  return (
    <div className="variant-details">
      <div className="variant-details__card">
        {cardDetails?.map(({ label, value, id }) => (
          <Space key={id} direction="vertical">
            <Label className="text-sm text-primary" label={label} />
            <span>{value}</span>
          </Space>
        ))}
      </div>
      <ProductDescription product={product} />
      <Divider />
      <DimensionAndCost product={product} variant={variant} />
      <Divider />
      <InventoryDetails product={product} variant={variant} />
      {product?.sustainability?.isSustainable && (
        <>
          <Divider />
          <Sustainability sustainability={product?.sustainability} />
        </>
      )}
      {product?.attachments?.length || product?.threeDimensionModel ? (
        <>
          <Divider />
          <Attachments
            attachments={[
              ...(product?.threeDimensionModel
                ? [product?.threeDimensionModel]
                : []),
              ...(product?.attachments || []),
            ]}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VariantDetails;
