import React from "react";
import {
  InputNumber as AntInputNumber,
  InputNumberProps as AntInputNumberProps,
  Space,
} from "antd";
import Label from "../Label";
import { ErrorMessage, Field, useField } from "formik";
import Dropdown, { DropdownProps } from "../DropdownField";
import { DefaultOptionType } from "antd/es/select";
import { Metric } from "models/Metric/metric.model";
import Error from "../Error";

import "./inputNumber.scss";

interface InputNumberProps extends AntInputNumberProps {
  label?: string;
  unitOptions?: DefaultOptionType[];
  selectAfterProps?: DropdownProps<string>;
  error?: string;
  type?: "whole" | "decimal";
}

interface InputNumberWitFormikProps extends InputNumberProps {
  name: string;
  hideError?: boolean
}

const InputNumber = ({
  label,
  unitOptions = [],
  selectAfterProps,
  error,
  type,
  ...props
}: InputNumberProps) => {
  return (
    <div className="input-number">
      <Label label={label} hasError={!!error} />
      <AntInputNumber
        controls={false}
        placeholder="0"
        type="number"
        onKeyDown={(e) => {
          if (e.key.match(/[0-9]/)) {
            const [, decimal] = (props.value?.toString() || "").split(".");
            if (decimal.length >= 2) e.preventDefault();
            // Since Number input accept e (i.e., Exponent), prevent that as well
          } else if (e.key.match(/^e$/i)) {
            e.preventDefault();
          } else if (e.key === "." && type === "whole") {
            e.preventDefault();
          }
        }}
        addonAfter={
          unitOptions?.length && (
            <Dropdown
              options={unitOptions}
              bordered={false}
              showSearch={false}
              popupMatchSelectWidth
              disabled={props.disabled}
              {...selectAfterProps}
            />
          )
        }
        {...props}
      />
    </div>
  );
};

const InputNumberWitFormik = <T extends "distance" | "mass">({
  name,
  hideError,
  selectAfterProps = {},
  ...props
}: InputNumberWitFormikProps) => {
  const [{ value = {} }, { touched, error }, { setValue }] =
    useField<Metric<T>>(name);

  const handleNumberChange = (number: number) =>
    setValue({ ...value, value: number });

  const handleUnitSelect = (unit: Metric<T>["unit"]) =>
    setValue({ ...value, unit });

  return (
    <Space className="w-100" direction="vertical">
      <Field
        name={name.concat(".value")}
        error={touched && error}
        status={touched && error ? "error" : undefined}
        as={InputNumber}
        value={value?.value}
        onChange={handleNumberChange}
        selectAfterProps={{
          value: value?.unit,
          onSelect: handleUnitSelect,
          allowClear: false,
          ...selectAfterProps,
        }}
        {...props}
      />
      {hideError
        ?<></>
        :<ErrorMessage name={name?.concat(".value")}>
        {(message) => <Error message={message} />}
      </ErrorMessage>}
    </Space>
  );
};

InputNumber.Formik = InputNumberWitFormik;

export default InputNumber;
