const META = "/meta";

export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  ATTACHMENTS: "/attachments",
  ATTACHMENTS_SPECIFIC: "/attachments/:id",
  ATTACHMENT_PRESIGNED_URL: "/attachments/get-presigned-url",
  USER_LOGIN: "/auth/login",
  PRODUCTS: "/products",
  PRODUCTS_CODE: "/products/code",
  PRODUCT: "/products/:id",
  PRODUCT_MAX_VALUES: "/products/max",

  // META
  CATEGORIES: META + "/categories",
  SUB_CATEGORIES: META + "/categories/:categoryId/sub_categories",
  TYPES: META + "/categories/:categoryId/sub_categories/:subCategoryId/types",
  CATEGORY: META + "/categories/:categoryId",
  SUB_CATEGORY: META + "/categories/:categoryId/sub_categories/:subCategoryId",
  TYPE:
    META +
    "/categories/:categoryId/sub_categories/:subCategoryId/types/:typeId",
  PRODUCT_META: META + "/products",

  PRODUCT_FILTERS: META + "/product_filters",
  VENDORS: META + "/vendors",
  COUNTRIES: META + "/countries",
  COLORS: META + "/colors",
  MATERIALS: META + "/materials",
  FINISHES: META + "/finishes",

  PROTOTYPES: "/prototypes",
  PROTOTYPE: "/prototypes/:id",
  PROTOTYPE_ACTIVITY: "/prototypes/:prototypeId/activity",

  PLANS: "/prototypes/:id/plans",
  PLAN: "/prototypes/:id/plans/:planId",

  ZONES: "/prototypes/:id/plans/:planId/zones",
  ZONE: "/prototypes/:id/plans/:planId/zones/:zoneId",
  ZONE_PIN: "/prototypes/:id/plans/:planId/zones/:zoneId/pin",

  SPACES: "/prototypes/:id/plans/:planId/zones/:zoneId/spaces",
  SPACE: "/prototypes/:id/plans/:planId/zones/:zoneId/spaces/:spaceId",
  SPACE_PIN: "/prototypes/:id/plans/:planId/zones/:zoneId/spaces/:spaceId/pin",

  ADD_LAYER: "/prototypes/:prototypeId/plans/:planId/spaces/:spaceId/layers",
  LAYER:
    "/prototypes/:prototypeId/plans/:planId/spaces/:spaceId/layers/:layerId",
  ADD_PRODUCT_TO_LAYER:
    "/prototypes/:prototypeId/plans/:planId/spaces/:spaceId/layers/:layerId/products",
  LAYER_PRODUCTS:
    "/prototypes/:prototypeId/plans/:planId/spaces/:spaceId/layers/:layerId/products/:layerVariantId",
  PIN_PRODUCT_TO_LAYER:
    "/prototypes/:prototypeId/plans/:planId/spaces/:spaceId/layers/:layerId/products/:layerVariantId/pin",

  // Picklist
  PICKLISTS: "/picklist",
  PICKLIST: "/picklist/:id",
  PICKLIST_CLEAR: "/picklist/:id/clear",
  PICKLIST_PRODUCT: "/picklist/product",
  PICKLIST_REMOVE_PRODUCT: "/picklist/:picklistId/remove/:productId",
  PICKLIST_EXPORT: "/picklist/:picklistId/export",

  USERS: "/users",
  USER: "/users/:userId",

  USER_ROLES: "/userroles",
  EDIT_USER_ROLE: "/userroles/:userRoleId",
  // ClientSettings
  CLIENTSETTINGS: "/client",

  CLIENTSETTINGS_UPDATE: "/client/:clientId",

  // ActivityNotifications
  PROTOTYPE_ACTIVITY_NOTIFICATIONS: "/prototypes/:protoTypeId/notifications",
  // Notifications
  NOTIFICATIONS: "/notifications",
  CLEAR_NOTIFICATIONS: "/notifications/clearall",
};

export const COMPANION_URL = ApiRoutes.BASE_URL?.concat(ApiRoutes.ATTACHMENTS);
