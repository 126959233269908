import React from "react";
import AuthRouter from "../authRouters";


const AuthWrapper = () => {
  return (
    <AuthRouter />
  );
};

export default AuthWrapper;
