import { Col, Empty, Row } from "antd";
import React, { useEffect } from "react";
import "./prototypeGrid.scss";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import PrototypeService from "services/PrototypeService/prototype.service";
import useRedirect from "shared/hooks/useRedirect";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import Spinner from "shared/components/Spinner";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedSpace } from "context/SelectedSpaceContext";
import { useCanAddPin } from "context/CanAddPinContext";
import { useSelectedLayerId } from "context/SelectedLayerContext";
import { useSelectedVariantId } from "context/SelectedVariantContext";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

const PrototypeGrid = () => {
  const { fetchPrototypes, loading, prototypes } = PrototypeService();
  const { redirectToAddPrototype, redirectToViewPrototype } = useRedirect();

  const { handleSetSelectedPlanId } = useSelectedPlan();
  const { handleSetSelectedSpace } = useSelectedSpace();
  const { handleSetAddPin } = useCanAddPin();
  const { updateSelectedLayerId } = useSelectedLayerId();
  const { updateSelectedVariantId } = useSelectedVariantId();

  useEffect(() => {
    fetchPrototypes();
    handleSetSelectedPlanId?.(undefined);
    handleSetSelectedSpace?.(undefined);
    handleSetAddPin?.(false);
    updateSelectedLayerId?.(undefined);
    updateSelectedVariantId?.(undefined);
  }, []);

  return (
    <div className="prototype-container">
      <div className="prototype-container__header">
        <h1 className="heading">Prototype</h1>
        {userHaseAccess(Module.PROTOTYPE, Method.CREATE) && (
          <Button className="prototype-container__add-btn" type={ButtonType.PRIMARY} onClick={redirectToAddPrototype}>
            Add Prototype
          </Button>
        )}{" "}
      </div>
      <div className="prototype-container__content">
        <Spinner loading={loading}>
          {prototypes?.length === 0 ? (
            <div className="empty-container">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
              <>
                {prototypes?.map((prototype) => (
                  <Col
                    key={prototype?.id}
                    span={6}
                    className="hover-animation prototype-container__content__card"
                    onClick={() => redirectToViewPrototype(prototype?.id ?? "")}
                  >
                    <div className="img-container">
                      <LazyLoadedImage
                        src={
                          prototype?.image?.url ||
                          prototype?.image?.thumbnailUrl ||
                          ""
                        }
                        imageClassName="prototype-container__content__img"
                      />
                    </div>
                    <div className="wrapper-center">
                      <div className="prototype-container__content__title">
                        {prototype?.title}
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            </Row>
          )}{" "}
        </Spinner>
      </div>
    </div>
  );
};

export default PrototypeGrid;
