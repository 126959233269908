import React from "react";
import { Tabs as AntTabs, TabsProps as AntTabsProps } from "antd";

import "./tabs.scss";

const Tab = ({ ...props }: AntTabsProps) => {
  return (
    <div className="tabs">
      <AntTabs tabPosition="left" {...props} />
    </div>
  );
};

export default Tab;
