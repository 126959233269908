import { VariantFieldType } from "enums/variantFieldType.enum";
import { alias, list, object, serializable } from "serializr";

export class VariantFieldOption {
  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }

  @serializable
  id?: string;

  @serializable
  label: string;

  @serializable
  value: string;
}

export class VariantField {
  constructor(name: string) {
    this.name = name;
  }
  @serializable
  id?: string;

  @serializable
  name: string;

  @serializable
  label?: string;

  @serializable
  type?: VariantFieldType;

  @serializable(alias("options", list(object(VariantFieldOption))))
  options?: VariantFieldOption[];
}
