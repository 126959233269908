import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import { CheckboxGroupProps } from "antd/es/checkbox";
import { Field, useField } from "formik";
import { CheckboxProps } from "antd/lib/checkbox";

import "./checkbox.scss";

export interface CheckboxWithFormikProps extends CheckboxProps {
  name: string;
}

export interface CheckboxGroupWithFormikProps extends CheckboxGroupProps {
  name: string;
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div className="checkbox">
      <AntCheckbox {...props} />
    </div>
  );
};

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  return (
    <div className="checkbox-group">
      <AntCheckbox.Group {...props} />
    </div>
  );
};

const CheckboxWithFormik = ({ name, ...props }: CheckboxWithFormikProps) => {
  const [{ value }, , { setValue }] = useField(name);

  const handleChange: CheckboxProps["onChange"] = ({ target: { checked } }) =>
    setValue(checked);

  return (
    <Field
      name={name}
      as={Checkbox}
      checked={value}
      onChange={handleChange}
      {...props}
    />
  );
};

const CheckboxGroupWithFormik = ({
  name,
  ...props
}: CheckboxGroupWithFormikProps) => {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <Field
      name={name}
      as={CheckboxGroup}
      value={value}
      onChange={setValue}
      {...props}
    />
  );
};

Checkbox.Formik = CheckboxWithFormik;

CheckboxGroup.Formik = CheckboxGroupWithFormik;

export default CheckboxGroup;
