import { Method } from "enums/method.enum";
import { Module } from "enums/module.enum";
import { User } from "models/user.model";
import React from "react";
import { Edit } from "shared/icons";
import { userHaseAccess } from "utils";

const GetColumns = (handleOpenEditForm: (userId: string) => void) => [
  {
    title: "USERNAME",
    dataIndex: "name",
    key: "name",
    colSpan: 1,
  },
  {
    title: "ROLE",
    key: "role",
    dataIndex: "role",
    colSpan: 1,
  },
  {
    title: "EMAIL",
    key: "email",
    dataIndex: "email",
    colSpan: 1,
  },
  {
    title: "PHONE NUMBER",
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    colSpan: 1,
    width: 140,
  },
 
  {
      title: "",
      key: "editIcon",
      width: 45,
      render: (_: string, record: User) => (
        <Edit
          className="icon icon-edit tw-w-8 tw-h-8"
          onClick={() => {
            record?.id && handleOpenEditForm(record?.id);
          }}
        />
      ),
    },

];

export default GetColumns;
