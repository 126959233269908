import React, { useEffect } from "react";
import ProductService from "services/ProductService/product.service";
import { useParams } from "react-router-dom";
import { EditProductParams } from "shared/types/routeParams";
import ViewProductHeader from "./ViewProductHeader";
import ViewProductContent from "./ViewProductContent";

import "./viewProduct.scss";
import ProductSkeletonLoader from "shared/components/ProductSkeletonLoader";

const ViewProduct = () => {
  const { productId } = useParams<EditProductParams>();

  const { product, loading, fetchProduct } = ProductService();

  useEffect(() => {
    if (!productId) return;

    fetchProduct(productId);
  }, []);

  return (
        <div className="view-product">
          <ViewProductHeader product={product} loading={loading}/>
          {<ProductSkeletonLoader loading={loading}><ViewProductContent product={product}/></ProductSkeletonLoader>}
        </div>
  );
};

export default ViewProduct;
