import { User, UserList, UserParams } from "models/user.model";
import { useState } from "react";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import useRedirect from "shared/hooks/useRedirect";
import { PaginationParams } from "models/PaginationParams";

const UserService = () => {
  const [users, setUsers] = useState<UserList>();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);

  const { redirectToUsers } = useRedirect();

  const getUsers = async (    params?: UserParams
    ) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.USERS, {
        params,
      });
      setUsers(deserialize(UserList, data));
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to get Users",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };
  const getUser = async (userId?: string) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        generatePath(ApiRoutes.USER, { userId })
      );
      setUser(deserialize(User, data["user"]));
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to get user",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };
  const createUser = async (data: User) => {
    try {
      const payload = {
        user: serialize(User, data),
      };
      setLoading(true);
      await axiosInstance.post(ApiRoutes.USERS, payload);
      Notification({
        message: `New user ${data?.name} created`,
        type: NotificationTypes.SUCCESS,
      });
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to create a user",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
      redirectToUsers();
    }
  };
  const editUser = async (data: User, userId: string) => {
    try {
      const payload = {
        user: serialize(User, data),
      };
      setLoading(true);
      await axiosInstance.put(
        generatePath(ApiRoutes.USER, { userId }),
        payload
      );
      Notification({
        message: `User ${data?.name} edited successfully`,
        type: NotificationTypes.SUCCESS,
      });
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to edit the user",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
      redirectToUsers();
    }
  };
  const deleteUser = async (userId: string) => {
    try {
      setLoading(true);
      await axiosInstance.delete(generatePath(ApiRoutes.USER, { userId }));
      Notification({
        message: `User deleted successfully`,
        type: NotificationTypes.SUCCESS,
      });
    } catch (err) {
      Notification({
        message: (err as Error)?.message || "Unable to delete the user",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
      redirectToUsers();
    }
  };
  return {
    users,
    user,
    loading,
    getUsers,
    getUser,
    createUser,
    editUser,
    deleteUser,
  };
};

export default UserService;
