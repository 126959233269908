import React from "react";
import TextArea, { TextAreaProps } from "../TextArea";
import { ViewDescription } from "shared/icons";
import { ErrorMessage, Field, useField } from "formik";
import { Space } from "antd";
import Error from "../Error";

import "./containedTextArea.scss";

interface ContainedTextAreaProps extends TextAreaProps {
  label: string;
}

interface ContainedTextAreaWithFormikProps extends ContainedTextAreaProps {
  name: string;
}

const ContainedTextArea = ({ label, ...props }: ContainedTextAreaProps) => {
  return (
    <div className="contained-text-area">
      <div className="contained-text-area__header">
        <ViewDescription className="icon icon--view-description" />
        <span className="contained-text-area__header__label">{label}</span>
      </div>
      <TextArea bordered={false} rows={9} {...props} />
    </div>
  );
};

const ContainedTextAreaWithFormik = ({
  name,
  ...props
}: ContainedTextAreaWithFormikProps) => {
  const [{ value }] = useField(name);

  return (
    <Space direction="vertical">
      <Field name={name} as={ContainedTextArea} value={value} {...props} />
      <ErrorMessage name={name}>
        {(msg) => <Error message={msg} />}
      </ErrorMessage>
    </Space>
  );
};

ContainedTextArea.Formik = ContainedTextAreaWithFormik;

export default ContainedTextArea;
