import * as Yup from "yup";

export const sustainabilityValidationSchema = Yup.object().shape({
  description: Yup.string().when("isSustainable", {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  isSustainable: Yup.boolean().required(),
});
