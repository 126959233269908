import { Category } from "models/Category/category.model";
import { VariantField } from "models/VariantField/variantField.model";
import { alias, list, object, primitive, serializable } from "serializr";
import { Attachment } from "models/Attachment/attachment.model";

export class SubCategory {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(alias("variant_fields", list(object(VariantField))))
  variantFields?: VariantField[];

  @serializable(object(Category))
  category?: Category;

  @serializable(object(Attachment))
  image?: Attachment;


  static findById(subCategories: SubCategory[] = [], id: string) {
    return subCategories.find((subCategory) => subCategory?.id === id);
  }

  static groupByCategory(
    subCategories: SubCategory[] = []
  ): Map<string, SubCategory[]> {
    const groupedSubCategories = new Map<string, SubCategory[]>();

    subCategories.forEach((subCategory) => {
      const categoryId = subCategory?.category?.id;

      if (!categoryId) return;

      groupedSubCategories.set(categoryId, [
        ...(groupedSubCategories.get(categoryId) || []),
        subCategory,
      ]);
    });

    return groupedSubCategories;
  }
}
