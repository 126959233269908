import React, { useEffect, useState } from "react";
import "./clientSettings.scss";
import ClientSettingsEdit from "./clientSettingsEdit";
import CardWidget from "./cardWidget";
import { Skeleton } from "antd";
import ClientService from "services/ClientSettings/clientSettings.service";
import { Edit, Mail, Phone } from "shared/icons";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

const ClientSettings: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const { client, fetchClients, loading } = ClientService();

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const handleBackClick = () => {
    setIsEditing(false);
    fetchClients();
  };
  useEffect(() => {
    fetchClients();
  }, []);
  return (
    <Skeleton loading={loading}>
      {isEditing ? (
        <ClientSettingsEdit
          client={client!}
          handleBackClick={handleBackClick}
        />
      ) : (
        <div>
          <div className="client-card" key={client?.id}>
            <CardWidget
              name={client?.clientName ?? "--"}
              imageUrl={client?.image?.url}
            />
            <div className="client-info">
              <div className="title">{client?.clientName}</div>
              <div className="row">
                <RowWithIcon
                  icon={<Mail className="row_icon-mail" />}
                  text={client?.email ?? ""}
                />
                <RowWithIcon
                  icon={<Phone className="row_icon-phone" />}
                  text={client?.phoneNumber ?? ""}
                />
              </div>
            </div>
            {userHaseAccess(Module.CLIENT_SETTINGS, Method.EDIT)?  (
              <Edit className="edit-icon tw-w-8 tw-h-8" onClick={() => handleEditClick()} />
            ):<div className="edit-icon"/>}{" "}
          </div>
        </div>
      )}
    </Skeleton>
  );
};

interface RowWithIconProps {
  icon: React.ReactNode;
  text: string;
}

const RowWithIcon: React.FC<RowWithIconProps> = ({ icon, text }) => (
  <div className="row">
    {icon}
    <div className="subtitle">{text}</div>
  </div>
);

export default ClientSettings;
