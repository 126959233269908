import { serializable, object, getDefaultModelSchema, list, alias, primitive } from "serializr";
import { Plan } from "models/Plans/plans.model";
import { Coordinate } from "../Coordinate/coordinate.model";
import { Attachment } from "models/Attachment/attachment.model";
import { Layer } from "models/Layer/layer.model";


export class Space {
  constructor() {
    getDefaultModelSchema<Space>(Space)!.props.plan = object(Plan);
  }

  @serializable
  id?: string;

  @serializable
  title?: string;

  @serializable
  description?: string;

  @serializable(list(object(Attachment)))
  gallery?: Attachment[];

  @serializable(object(Coordinate))
  coordinate?: Coordinate;

  @serializable(alias('is_pinned',primitive()))
  isPinned?:boolean

  @serializable(list(object(Layer)))
  layers?: Layer[] = [];

  plan?: Plan;
}
