import * as Yup from "yup";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { MassUnit } from "enums/massUnit.enum";

export const getMetricValidationSchema = (type: "distance" | "mass") =>
  Yup.object().shape({
    value: Yup.number().notRequired().typeError("Value must be a number"),
    unit: Yup.string()
      .notRequired()
      .oneOf(Object.values(type === "distance" ? DistanceUnit : MassUnit)),
  });
