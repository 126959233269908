import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { alias, list, object, serializable } from "serializr";

export class ProductFilters {
  @serializable(list(object(Category)))
  categories: Category[] = [];

  @serializable(alias("sub_categories", list(object(SubCategory))))
  subCategories: SubCategory[] = [];

  @serializable(list(object(Type)))
  types: Type[] = [];
}
