import React, { useContext, FC, createContext, useState } from "react";
import useToggle from "shared/hooks/useToggle";

interface FilterToggleContextType {
  filterIsToggled: boolean;
  toggleFilterOff?: () => void;
  toggleFilterOn?: () => void;
  defaultKey: string;
  handleFilterOn?: (val: undefined | "categories" | "subCategories") => void;
  setDefaultKey?: React.Dispatch<React.SetStateAction<string>>;
}
const initialValues: FilterToggleContextType = {
  filterIsToggled: false,
  defaultKey: "categories",
};
export const FilterToggleContext =
  createContext<FilterToggleContextType>(initialValues);

//context provider
const FilterToggleState: FC = ({ children }) => {
  const [defaultKey, setDefaultKey] = useState<string>("categories");
  const {
    isToggled: filterIsToggled,
    toggleOff: toggleFilterOff,
    toggleOn: toggleFilterOn,
  } = useToggle(false);
  const handleFilterOn = (val?: "categories" | "subCategories"|undefined) => {
    if (val) {
      setDefaultKey(val);
    }  


    toggleFilterOn();
  };
  return (
    <FilterToggleContext.Provider
      value={{
        filterIsToggled,
        toggleFilterOff,
        toggleFilterOn,
        defaultKey,
        handleFilterOn,
        setDefaultKey,
      }}
    >
      {children}
    </FilterToggleContext.Provider>
  );
};

//using context
export const useFilterToggle = () => {
  const context = useContext<FilterToggleContextType>(FilterToggleContext);
  if (!context)
    throw new Error(
      "FilterContext must be used within a FilterContextProvider"
    );

  return {
    ...context,
  };
};

export default FilterToggleState;
