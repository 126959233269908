import React, { useState } from "react";
import { Plan } from "models/Plans/plans.model";
import ToggleDescriptionIcon from "shared/components/ToggleDescriptionIcon";
import useToggle from "shared/hooks/useToggle";
import SpaceList from "./SpaceList";
import { Space } from "models/Space/space.model";

import "./planDetails.scss";

interface PlanDetailsProps {
  plan: Plan;
}

const PlanDetails = ({ plan }: PlanDetailsProps) => {
  const {
    toggle: toggleDescriptionVisibility,
    isToggled: isDescriptionVisible,
  } = useToggle();

  const [selectedSpace, setSelectedSpace] = useState<Space>();

  return (
    <div className="plan-details">
      <div className="plan-details__header">
        <div className="plan-details__header__title">{plan?.title}</div>
        <div className="plan-details__header__options">
          <ToggleDescriptionIcon
            active={isDescriptionVisible}
            onActiveUpdate={toggleDescriptionVisibility}
          />
        </div>
      </div>
      <div className="plan-details__content">
        {isDescriptionVisible && (
          <div className="plan-details__content__description">
            {plan?.description}
          </div>
        )}
        <hr />
        <SpaceList
          spaces={plan?.spaces}
          onSelect={setSelectedSpace}
          activeSpace={selectedSpace}
        />
      </div>
    </div>
  );
};

export default PlanDetails;
