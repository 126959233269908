import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { RouterProps } from "../shared/types/route.type";
import routes from "./routes";

const AppRouter = () => {
  const generateRoute = (routes?: RouterProps[]) => {
    if (!routes?.length) return <></>;

    return routes.map(({ component, path, children }) => (
      <Route key={path} path={path} element={component}>
        {generateRoute(children)}
      </Route>
    ));
  };

  return (
    <BrowserRouter>
      <Routes>{generateRoute(routes)}</Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
