import React, { PropsWithChildren } from "react";
import {
  Collapse as AntCollapse,
  CollapseProps as AntCollapseProps,
} from "antd";
import { DownArrow } from "shared/icons";

import "./collapse.scss";

export interface CollapseProps<T> extends AntCollapseProps {
  children: (item: T) => React.ReactElement;
  draggable?: boolean;
  list: T[];
  getPanelExtra?: (item: T) => React.ReactElement;
}

const { Panel } = AntCollapse;

interface ICollapse {
  id?: number;
  getHeader?: () => string | React.ReactElement;
}

const Collapse = <T extends ICollapse>({
  accordion,
  className = "",
  children,
  draggable,
  list,
  getPanelExtra,
  ...props
}: PropsWithChildren<CollapseProps<T>>) => {
  return (
    <AntCollapse
      accordion={accordion}
      className={`collapse ${
        accordion ? "collapse--accordion " : "collapse--collapsible"
      } ${className}`.trimEnd()}
      destroyInactivePanel
      expandIcon={({ isActive }) => (
        <DownArrow
          className={`icon icon--down-arrow ${
            isActive ? "rotate-180" : ""
          }`.trimEnd()}
        />
      )}
      ghost
      {...props}
    >
      {list?.map((item, index) => (
        <Panel
          extra={
            draggable ? <i className="icon-reorder" /> : getPanelExtra?.(item)
          }
          header={
            <div className="collapse__header-title">{item?.getHeader?.()}</div>
          }
          key={item?.id || index}
        >
          {children(item)}
        </Panel>
      ))}
      {children}
    </AntCollapse>
  );
};

export default Collapse;
