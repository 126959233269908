import React from "react";
import CanAddPinState from "context/CanAddPinContext";
import DualViewSelectedPlanState from "context/SelectedPlanContext";
import DualViewSelectedSpaceState from "context/SelectedSpaceContext";
import DualViewSelectedZoneState from "context/SelectedZoneContext";
import PinMarkersState from "context/PinMarkersContext";
import SelectedLayerState from "context/SelectedLayerContext";
import SelectedVariantState from "context/SelectedVariantContext";
import OpenedLayerVariantState from "context/OpenedLayerVariantContext";
import "./prototypes.scss";
import { Outlet, useParams } from "react-router-dom";

const Prototypes = () => {
  const { prototypeId, spaceId } = useParams();
  const viewId = spaceId ?? prototypeId;

  return (
    <DualViewSelectedPlanState viewId={viewId}>
      <DualViewSelectedZoneState viewId={viewId}>
        <DualViewSelectedSpaceState viewId={viewId}>
          <CanAddPinState>
            <SelectedLayerState>
              <SelectedVariantState viewId={viewId}>
                <PinMarkersState>
                  <OpenedLayerVariantState>
                    <Outlet />
                  </OpenedLayerVariantState>
                </PinMarkersState>
              </SelectedVariantState>
            </SelectedLayerState>
          </CanAddPinState>
        </DualViewSelectedSpaceState>
      </DualViewSelectedZoneState>
    </DualViewSelectedPlanState>
  );
};

export default Prototypes;
