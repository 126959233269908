import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from  "yup";

export const validationSchema = (userId ?: string) => Yup.object().shape({
  clientName: Yup.string().required('Client name is required'),
  email: Yup.string()
    .email("E-mail is not valid")
    .required("E-mail is required"),
  phoneNumber: Yup.string().required('Phone number is required'),
  image: getObjectIdSchema().required("Image is required")
});
