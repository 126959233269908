import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/Input";
import { validationSchema } from "./LoginValidation";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "models/user.model";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import Logo from "assets/images/logo.svg";

import "./login.scss";

const LoginForm = () => {
  const { loginUser } = AuthService();

  return (
    <div className="login">
      <div className="login__header">
        <div className="login__header__content">
          <div className="login__header__content__logo">
            <img src={Logo} width={150} height={150} />
          </div>
          <h1 className="login__header__content__title tw-tracking-wider tw-mb-0">
            Digital Standards
          </h1>
          <span className="login__header__content__description tw-font-light">
            Please enter your details to sign in
          </span>
        </div>
      </div>
      <div className="login__form tw-mt-12">
        <Formik
          initialValues={new User()}
          onSubmit={loginUser}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField.Formik
                label="email"
                type="email"
                name="email"
                className="login__form__input login__form__email-input"
                placeholder="Enter"
              />
              <InputField.Formik
                label="password"
                type="password"
                name="password"
                className="login__form__input login__form__password-input"
                placeholder="Enter"
              />
              <Button
                loading={isSubmitting}
                type={ButtonType.PRIMARY}
                htmlType="submit"
                className="tw-border tw-border-white tw-font-semibold"
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
