import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Table,
} from "antd";
import { UserAccess } from "models/UserRole/userRole.model";
import { Check, No } from "shared/icons";
import { FieldType } from "enums/fieldType";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: FieldType.Check_Box;
  record: UserAccess;
  index: number;
  children: React.ReactNode;
  onSave: (updatedData: UserAccess) => void; // Add setData prop
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onSave, // Receive setData as a prop

  ...restProps
}) => {
  const renderCheckbox = (checked: boolean | undefined) => {
    if (checked) {
      return <Check className="iconSvg" />;
    } else {
      return <No className="iconSvgC" />;
    }
  };

  const inputNode =
    inputType === FieldType.Check_Box ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputType === FieldType.Check_Box ? (
            <Checkbox
              style={{ padding: 0, margin: 0 }}
              checked={getViewAccess(dataIndex)}
              disabled={getViewAccess(dataIndex) === undefined}
              onChange={(check) => {
                for (const item of record.roleAccess ?? []) {
                  if (item.method === dataIndex) {
                    item.access = !item.access;
                  }
                }
                onSave(record);
              }}
            />
          ) : (
            inputNode
          )}
        </Form.Item>
      ) : inputType === FieldType.Check_Box ? (
        renderCheckbox(getViewAccess(dataIndex))
      ) : (
        children
      )}
    </td>
  );
  function getViewAccess(data: string) {
    for (const item of record.roleAccess ?? []) {
      if (item.method === data) {
        return item.access;
      }
    }
    return undefined;
  }
};

interface UserTableProps {
  items: UserAccess[];
  isEdit: boolean;
  onEdit: (updatedData: UserAccess) => void;
}
const UserTable: React.FC<UserTableProps> = ({ items, isEdit, onEdit }) => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Modules",
      dataIndex: "module",
      width: "40%",
      editable: false,
    },
    {
      title: "View",
      dataIndex: "View",
      width: "15%",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Create",
      dataIndex: "Create",
      width: "15%",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Update",
      dataIndex: "Update",
      width: "15%",
      editable: true,
      align: "center" as const,
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      width: "15%",
      editable: true,
      align: "center" as const,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: UserAccess) => ({
        record,
        inputType:
          col.dataIndex === "View" ||
          col.dataIndex === "Update" ||
          col.dataIndex === "Create" ||
          col.dataIndex === "Delete"
            ? FieldType.Check_Box
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEdit,
        onSave: (updatedRecord: UserAccess) => {
          onEdit(updatedRecord);
        },
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        style={{ borderRadius: "20px" }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered={false}
        dataSource={items}
        columns={mergedColumns}
        pagination={false}
      />
    </Form>
  );
};

export default UserTable;
