import React, { Children } from 'react'
import './productSkeletonLoader.scss';
import SkeletonImage from 'antd/es/skeleton/Image';
import SkeletonInput from 'antd/es/skeleton/Input';
import '../../../views/ViewProduct/ViewProductContent/viewProductContent.scss'

interface ProductSkeletonLoaderType{
  loading?:boolean
} 
const ProductSkeletonLoader: React.FC<ProductSkeletonLoaderType> = ({loading, children}) => (
  loading
    ?<div className="view-product-content">
      <div className="view-product-content__start">
        <SkeletonImage active className="product-img-skeleton"/>
      </div>
      <div className="view-product-content__end">
        <div className="text-heavy view-product-content__end__title">
          <SkeletonInput active block/>
        </div>
        <div className='skeleton-img-gp'>
            {(Array(5).fill(0)?.map((ele,idx)=><span key={idx} className='skeleton-img'><SkeletonImage active/></span>))}
        </div>
        {(Array(4).fill(0)?.map((ele,idx)=><div key={idx} className='skeleton-line'><SkeletonInput active block size="large"/></div>))}
      </div>
    </div>
    :<>{children}</>
  )
  
export default ProductSkeletonLoader;
