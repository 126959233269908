import React, { HTMLAttributes } from "react";
import "./label.scss";

interface LabelProps extends HTMLAttributes<HTMLSpanElement> {
  label?: React.ReactNode;
  hasError?: boolean;
}

const Label = ({ label, hasError, className = "", ...props }: LabelProps) => {
  if (!label) return <></>;

  return (
    <span
      className={`text-medium label ${hasError ? "text-danger" : ""}`
        .trimEnd()
        .concat(" ", className)
        .trimEnd()}
      {...props}
    >
      {label}
    </span>
  );
};

export default Label;
