import React from 'react';
import { Skeleton } from "antd";
import ProgressiveImage from "react-progressive-graceful-image";
import './lazyLoadedImage.scss';

interface LazyLoadedImageProps {
    src: string,
    alt?: string,
    imageClassName?: string,
    loaderClassName?: string,
    aspectRatio?: 'square' | 'auto' | number;
    onLoad?: () => void;
    handleImageClick?: (e: React.MouseEvent) => void;
    imgRef?: React.RefObject<HTMLImageElement>;
}

const LazyLoadedImage = ({ src, alt, imageClassName, loaderClassName, aspectRatio = 'square', onLoad, handleImageClick, imgRef }: LazyLoadedImageProps) => {
    return <ProgressiveImage
        src={src}
        placeholder=""
    >
        {(src, loading) => {
            return loading ? <div
                className={`lazy-loader__container__loader__container aspect-${aspectRatio} ${loaderClassName}`}
            >
                <Skeleton.Image active className="lazy-loader__container__loader" />
            </div> : <img
                onLoad={onLoad}
                onClick={handleImageClick}
                src={src}
                ref={imgRef}
                alt={alt}
                className={`lazy-loader__container__image ${imageClassName} aspect-${aspectRatio}`} />;
        }
        }
    </ProgressiveImage>;
};

export default LazyLoadedImage;