import { Color } from "models/Color/color.model";
import { Finish } from "models/Finish/finish.model";
import { Material } from "models/Material/material.model";
import { useContext, createContext } from "react";

export interface VariantMetaState {
  colors: Color[];
  materials: Material[];
  finishes?: Finish[];
}

const initialValues: VariantMetaState = {
  colors: [],
  finishes: [],
  materials: [],
};

export const VariantMetaContext =
  createContext<VariantMetaState>(initialValues);

export const useVariantMeta = () => {
  const context = useContext<VariantMetaState>(VariantMetaContext);

  if (!context)
    throw new Error("MetaContext must be used within a MetaContextProvider");

  return {
    ...context,
  };
};
