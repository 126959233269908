import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { Category } from "models/Category/category.model";
import { CategoryConfiguration } from "models/CategoryConfiguration/categoryConfiguration.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "shared/components/Notification";

const ConfigurationCategoryService = () => {
  const [category, setCategory] = useState<Category>();

  const [subCategory, setSubCategory] = useState<SubCategory>();

  const [type, setType] = useState<Type>();

  const addCategory = async (category: Category) => {
    try {
      const { data } = await axiosInstance.post(ApiRoutes.CATEGORIES, {
        category: serialize(Category, category),
      });
      const newCategory = deserialize(Category, data["category"]);
      setCategory(newCategory);

      return newCategory;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to create Category",
      });
    }
  };

  const addSubCategory = async (
    subCategory: SubCategory,
    categoryId: string
  ) => {
    try {
      const ENDPOINT = generatePath(ApiRoutes.SUB_CATEGORIES, {
        categoryId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        sub_category: serialize(SubCategory, subCategory),
      });
      const newSubCategory = deserialize(SubCategory, data["sub_category"]);
      setSubCategory(newSubCategory);

      return newSubCategory;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to create Sub Category",
      });
    }
  };

  const addType = async (
    type: Type,
    subCategoryId: string,
    categoryId: string
  ) => {
    try {
      const ENDPOINT = generatePath(ApiRoutes.TYPES, {
        categoryId,
        subCategoryId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        type: serialize(Type, type),
      });

      const newType = deserialize(Type, data["type"]);
      setType(newType);

      return newType;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to create Type",
      });
    }
  };

  const updateCategory = async (category: Category) => {
    try {
      if (!category?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.CATEGORY, {
        categoryId: category?.id,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        category: serialize(Category, category),
      });
      const updatedCategory = deserialize(Category, data["category"]);
      setCategory(updatedCategory);

      return updatedCategory;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to update Category",
      });
    }
  };

  const updateSubCategory = async (
    subCategory: SubCategory,
    categoryId: string
  ) => {
    try {
      if (!subCategory?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.SUB_CATEGORY, {
        categoryId,
        subCategoryId: subCategory?.id,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        sub_category: serialize(SubCategory, subCategory),
      });
      const updatedSubCategory = deserialize(SubCategory, data["sub_category"]);
      setSubCategory(updatedSubCategory);

      return updatedSubCategory;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to update Sub Category",
      });
    }
  };

  const updateType = async (
    type: Type,
    subCategoryId: string,
    categoryId: string
  ) => {
    try {
      if (!type?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.TYPE, {
        categoryId,
        subCategoryId,
        typeId: type?.id,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        type: serialize(Type, type),
      });

      const updatedType = deserialize(Type, data["type"]);
      setType(updatedType);

      return updatedType;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to update Type",
      });
    }
  };

  const deleteCategory = async (
    config: CategoryConfiguration,
    newConfig?: CategoryConfiguration
  ) => {
    try {
      if (!config?.category?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.CATEGORY, {
        categoryId: config.category.id,
      });

      const { data } = await axiosInstance.delete(ENDPOINT, {
        data: newConfig
          ? {
              configuration: serialize(CategoryConfiguration, newConfig),
            }
          : {},
      });

      return true;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to delete Category",
      });
      return false;
    }
  };

  const deleteSubCategory = async (
    config: CategoryConfiguration,
    newConfig?: CategoryConfiguration
  ) => {
    try {
      if (!config?.category?.id || !config?.subCategory?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.SUB_CATEGORY, {
        categoryId: config.category.id,
        subCategoryId: config.subCategory.id,
      });

      await axiosInstance.delete(ENDPOINT, {
        data: newConfig
          ? {
              configuration: serialize(CategoryConfiguration, newConfig),
            }
          : {},
      });

      return true;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to delete Sub Category",
      });
      return false;
    }
  };

  const deleteType = async (
    config: CategoryConfiguration,
    newConfig?: CategoryConfiguration
  ) => {
    try {
      if (
        !config?.category?.id ||
        !config?.subCategory?.id ||
        !config?.type?.id
      )
        return;

      const ENDPOINT = generatePath(ApiRoutes.TYPE, {
        categoryId: config.category.id,
        subCategoryId: config.subCategory.id,
        typeId: config.type.id,
      });

      await axiosInstance.delete(ENDPOINT, {
        data: newConfig
          ? {
              configuration: serialize(CategoryConfiguration, newConfig),
            }
          : {},
      });

      return true;
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to delete Type",
      });
      return false;
    }
  };

  return {
    addCategory,
    addSubCategory,
    addType,
    category,
    subCategory,
    type,
    updateCategory,
    updateSubCategory,
    updateType,
    deleteCategory,
    deleteSubCategory,
    deleteType,
  };
};

export default ConfigurationCategoryService;
