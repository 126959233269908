import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { User } from "../../models/user.model";
import UserRole from "models/UserRole/userRole.model";

export interface AuthState {
  authenticated?: boolean;
  user?: User;
  userRole?:UserRole
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState] | [];

const existingUser = localStorage.getItem("user");

// Define the default context state
const initialValues: AuthState = {
  authenticated: !!existingUser,
  user: existingUser ? JSON.parse(existingUser) : new User(),

};

// Create the context
const AuthContent = createContext<AuthContentProps>([]);

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps>(AuthContent);
  if (!context) {
    throw new Error("useMeContext must be used within a MeContextProvider");
  }
  const [auth, setAuth] = context;
  const [userRole, setUserRole] = useState(initialValues.userRole);

  const setAuthenticated = (user?: User,userRole?:UserRole) => {
    setUserRole(userRole);
    setAuth?.((auth) => ({
      ...auth,
      authenticated: true,
      user,

    }));
  };

  return {
    ...auth,
    setAuthenticated,
    existingUser,
    userRole
    
  };
};

// Create the context provider
const AuthProvider = (ownProps: object) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  const value: AuthContentProps = useMemo(() => [auth, setAuth], [auth]);
  return <AuthContent.Provider value={value} {...ownProps} />;
};

export { AuthProvider, AuthContext };
