import React from "react";
import { Skeleton } from "antd";
import RoundedArrow from "../RoundedArrow";

import "./folderView.scss";
import Spinner from "../Spinner";

interface FolderItems<T> {
  label: React.ReactNode;
  value: T;
}

interface FolderViewProps<T> {
  emptyPlaceHolder?: React.ReactNode;
  hideNext?: boolean;
  isActiveSecondary?: boolean;
  items: FolderItems<T>[];
  onClick: (value: T) => void;
  activeItem?: T;
  loading?: boolean;
  showNext?: boolean;
}

const FolderView = <T extends { id?: string }>({
  emptyPlaceHolder,
  items,
  onClick,
  isActiveSecondary,
  hideNext,
  activeItem,
  loading,
  showNext,
}: FolderViewProps<T>): React.ReactElement => {
  const folderItemClassName = isActiveSecondary
    ? "folder-view__item--active-secondary"
    : "folder-view__item--active";

  const handleClick = (item: T) => {
    onClick(item);
  };

  return (
    <div className="folder-view">
      <Spinner loading={loading??true}>
        {items?.length
          ? items?.map(({ label, value }) => (
              <div
                onClick={() => handleClick(value)}
                key={value?.id}
                className={`folder-view__item ${
                  value?.id === activeItem?.id ? folderItemClassName : ""
                }`.trimEnd()}
              >
                <div className="folder-view__item__left">{label}</div>
                {(showNext || (!hideNext && value?.id === activeItem?.id)) && (
                  <div className="folder-view__item__right">
                    <RoundedArrow />
                  </div>
                )}
              </div>
            ))
          : emptyPlaceHolder}
      </Spinner>
    </div>
  );
};

export default FolderView;
