import React, { FC } from "react";
import { Sustainability as SustainabilityModel } from "models/Sustainability/sustainability.model";
import Collapse from "shared/components/Collapse";
import SustainableCard from "shared/components/SustainableCard";
import { Sustainable } from "shared/icons";
import Status from "shared/components/Status";

import "./sustainability.scss";

interface SustainabilityProps {
  sustainability: SustainabilityModel;
}

const Sustainability: FC<SustainabilityProps> = ({ sustainability }) => {
  const items = [
    {
      id: 1,
      sustainability,
      getHeader: () => (
        <div className="collapse-icon-header">
          <Sustainable className="icon icon--sustainable" />
          <span>Sustainability</span>
          <Status status="sustainable" />
        </div>
      ),
    },
  ];

  return (
    <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
      {({ sustainability: { description } }) => (
        <SustainableCard description={description} />
      )}
    </Collapse>
  );
};

export default Sustainability;
