import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { SubCategory } from "models/SubCategory/subCategory.model";
import Dropdown from "shared/components/DropdownField";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import { CategoryConfiguration } from "models/CategoryConfiguration/categoryConfiguration.model";
import { useConfigInput } from "shared/hooks/useConfigInput";

import "./categoryConfigInput.scss";

interface CategoryConfigInputProps {
  onSubCategoriesUpdate?: (subCategories: SubCategory[]) => void;
}

const CategoryConfigInput = <T extends CategoryConfiguration>({
  onSubCategoriesUpdate,
}: CategoryConfigInputProps) => {
  const { values, setValues } = useFormikContext<T>();

  const { category, subCategory } = values;

  useEffect(() => {
    handleCategoryChange(category?.id ?? '');
  }, []);

  const handleCategoryUpdate = async (id: string) => {
    setValues(
      Object.assign(values, {
        category: {
          id,
        },
        subCategory: {
          id: undefined,
        },
        type: {
          id: undefined,
        },
      })
    );
  };

  const handleSubCategoryUpdate = async (id: string) => {
    const updatedValues = Object.assign(values, {
      subCategory: {
        id,
      },
      type: {
        id: undefined,
      },
    });
    setValues(updatedValues);
  };

  const {
    categoriesLoading,
    categories,
    subCategories,
    subCategoriesLoading,
    types,
    typesLoading,
    handleCategoryChange,
    handleSubCategoryChange,
  } = useConfigInput({
    onCategoryUpdate: handleCategoryUpdate,
    onSubCategoryUpdate: handleSubCategoryUpdate,
    onSubCategoriesUpdate,
  });

  return (
    <div className="category-config-input">
      <Dropdown.Formik
        label="Category"
        loading={categoriesLoading}
        name="category.id"
        onChange={handleCategoryChange}
        options={generateDropdownOptions("name", "id", categories)}
        placeholder="Search & Select Category"
      />
      <Dropdown.Formik
        disabled={!category?.id}
        label="Sub Category"
        loading={subCategoriesLoading}
        name="subCategory.id"
        onChange={(id: string) =>
          handleSubCategoryChange(id, values?.category?.id)
        }
        options={generateDropdownOptions("name", "id", subCategories)}
        placeholder="Search & Select Sub Category"
      />
      <Dropdown.Formik
        disabled={!subCategory?.id}
        label="Type"
        loading={typesLoading}
        name="type.id"
        options={generateDropdownOptions("name", "id", types)}
        placeholder="Search & Select Type"
      />
    </div>
  );
};

export default CategoryConfigInput;
