import React from "react";
import Dropdown from "../DropdownField";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import { useField } from "formik";
import { Location } from "models/Location/location.model";
import { SelectProps } from "antd";
import { useLocation } from "context/LocationContext";

import "./locationInput.scss";

interface LocationInputProps {
  name: string;
  countryLabel?: string;
  provinceLabel?: string;
}
const LocationInput = ({
  name,
  countryLabel = "Country",
  provinceLabel = "Province",
}: LocationInputProps) => {
  const [{ value: location = {} }, , { setValue }] = useField<Location>(name);

  const { countries, loading } = useLocation();

  const handleCountryChange: SelectProps<string>["onChange"] = (countryId) =>
    setValue({ ...location, country: { id: countryId }, province: undefined });

  const provinces =
    countries?.find((country) => country?.id === location?.country?.id)
      ?.provinces || [];

  return (
    <div className="location-input">
      <Dropdown.Formik
        label={countryLabel}
        loading={loading}
        name={name?.concat(".country.id")}
        options={generateDropdownOptions("name", "id", countries)}
        value={location?.country?.id}
        showSearch
        allowClear
        onChange={handleCountryChange}
      />
      <Dropdown.Formik
        label={provinceLabel}
        loading={loading}
        name={name?.concat(".province.id")}
        options={generateDropdownOptions("name", "id", provinces)}
        value={location?.province?.id}
        showSearch
        disabled={!location?.country?.id}
        allowClear
      />
    </div>
  );
};

export default LocationInput;
