import * as Yup from "yup";
import { getLocationValidation } from "models/Location/location.validation";
import { currencyValidationSchema } from "models/CostPerItem/currency.validation";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";

export const variantValidation = Yup.object().shape({
  color: getObjectIdSchema().required(),
  cost: currencyValidationSchema,
  count: Yup.number()
    .typeError("Count must be a number")
    .required("Count is required"),
  image: getObjectIdSchema().required(),
  leadTime: Yup.number()
    .typeError("Lead Time must be a number")
    .required("Lead time is required"),
  location: getLocationValidation(false),
  material: getObjectIdSchema().required("Material is required"),
  name: Yup.string().required("Name is required"),
});
