import React, { FC, HTMLAttributes } from "react";

import "./groupedInput.scss";

interface ItemProps {
  label: React.ReactNode;
  labelSmall?: boolean ,
  value: React.ReactNode;
}

interface GroupedInputProps extends HTMLAttributes<HTMLDivElement> {
  items: ItemProps[];
  size?: "sm" | "md";
}

const GroupedInput: FC<GroupedInputProps> = ({
  items,
  size = "md",
  className = "",
}) => (
  <div
    className={`grouped-input grouped-input grouped-input--${size}`
      .concat(" ", className)
      .trimEnd()}
  >
    {items?.map(({ label, value, labelSmall }) => (
      <>
       <div className={`grouped-input__item grouped-input__${!labelSmall? 'label':'labelSmall'}`}>{label}</div>
        <div className="grouped-input__item  grouped-input__value">{value}</div>
      </>
    ))}
  </div>
);

export default GroupedInput;
