import React, { FC, useEffect, useRef, useState } from "react";
import "./accordion.scss";
import { AccordionClose, AccordionOpen } from "shared/icons";
import UserTable from "views/UserRoleManagement/userRoleTable";
import UserRole, { UserAccess } from "models/UserRole/userRole.model";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";
import { useUserRoles } from "context/UserRolesContext";
import GroupedInput from "../GroupedInput";
import Input from "../Input";
import { Formik, FormikProps } from "formik";
import { Spin } from "antd";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

interface AccordionProps {
  items: UserRole[];
}
const Accordion: FC<AccordionProps> = ({ items }) => {
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    setUserRoles(JSON.parse(JSON.stringify(items)));
  }, [items]);
  const onEditHandler = (edit: UserAccess) => {
    setUserRoles((prevUserRoles) =>
      prevUserRoles.map((userRole) => {
        if (userRole.id === edit.id) {
          return edit;
        }

        return userRole;
      })
    );
  };

  const { getUserRoles, putUserRoles, loading } = useUserRoles();
  const handleAccordianToggle = (id?: string) => {
    if (!edit) {
      setSelectedCardId((prevId) => (prevId === id ? undefined : id));
    }
  };
  const formikRef = useRef<FormikProps<UserRole> | null>(null);
  function onChangeLimit(
    e: UserRole
  ): React.ChangeEventHandler<HTMLInputElement> | undefined {
    return (event) => {
      setUserRoles((prevUserRoles) =>
        prevUserRoles.map((userRole) => {
          if (userRole.id == e.id) {
            if (event.target?.value != undefined || event.target?.value != null)
              userRole.limit =
                event.target.value === ""
                  ? undefined
                  : parseInt(event.target.value);
            formikRef.current?.setValues(userRole);
          }
          return userRole;
        })
      );
    };
  }
  return (
    <>
      {userRoles?.map((userRole) => (
        <div className="accordion-card" key={userRole.id}>
          <div
            className={`accordion-card__header ${
              userRole.id === selectedCardId && "border-bottom"
            }`}
            onClick={() => handleAccordianToggle(userRole.id)}
          >
            <div className="accordion-card__header__name">
              {userRole.roleName}
            </div>

            <div className="accordion-card__header__limit">
              {userRole.id === selectedCardId && edit ? (
                <Formik
                  onSubmit={(values) => {
                    setUserRoles((prevUserRoles) =>
                      prevUserRoles.map((userRole) => {
                        if (userRole.id == userRole.id) {
                          userRole.limit = values.limit ?? 0;
                        }
                        return userRole;
                      })
                    );
                  }}
                  innerRef={formikRef}
                  initialValues={userRole}
                >
                  {() => (
                    <div>
                      <GroupedInput
                        key={userRole.id}
                        items={[
                          {
                            labelSmall: true,
                            label: "User Limit",

                            value: (
                              <Input.Formik
                                style={{
                                  width: "4rem",
                                  padding: 0,
                                  margin: 0,
                                  height: "2rem",
                                }}
                                onChange={onChangeLimit(userRole)}
                                key={`input-${userRole.id}`}
                                name="limit"
                                bordered={false}
                                hideError
                              />
                            ),
                          },
                        ]}
                        size="sm"
                      />
                    </div>
                  )}
                </Formik>
              ) : (
                <div style={{ paddingTop: "0.5rem" }}>
                  USER LIMIT:{" "}
                  <span className="accordion-card__header__limit__count">
                    {userRole.limit}
                  </span>
                </div>
              )}

              {userRole.id === selectedCardId &&
                userHaseAccess(Module.USER_ACCESS_AND_LIMIT, Method.EDIT) && (
                  <div>
                    {edit ? (
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={(event) => {
                          setEdit(false);
                          setUserRoles(JSON.parse(JSON.stringify(items)));
                          event.stopPropagation();
                        }}
                        className="small-button"
                      >
                        {"Cancel"}
                      </Button>
                    ) : (
                      <div />
                    )}
                    {loading ? (
                      <div className="spin">
                        <Spin />
                      </div>
                    ) : (
                      <Button
                        htmlType="submit"
                        type={ButtonType.PRIMARY}
                        onClick={async (event) => {
                          setEdit(!edit);
                          if (edit) {
                            await putUserRoles?.(userRole);
                            getUserRoles?.();
                          }
                          event.stopPropagation();
                        }}
                        className="small-button"
                      >
                        {edit ? "Update" : "Edit"}
                      </Button>
                    )}
                  </div>
                )}
              <div>
                {userRole.id === selectedCardId ? (
                  <AccordionOpen className="icon icon-accord" />
                ) : (
                  <AccordionClose className="icon icon-accord" />
                )}
              </div>
            </div>
          </div>
          <div
            className={`${userRole.id !== selectedCardId && "display-none"}`}
          >
            <UserTable
              items={userRole.access ?? []}
              isEdit={edit}
              onEdit={onEditHandler}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default Accordion;
