import React, { FC } from "react";
import { Badge } from "antd";

import "./filterLabel.scss";

interface FilterLabelProps {
  label: string;
  count?: number;
}

const FilterLabel: FC<FilterLabelProps> = ({ label, count = 0 }) => {
  return (
    <div className="filter-label">
      <span>{label}</span>
      <Badge className="filter-label__count" count={count} />
    </div>
  );
};

export default FilterLabel;
