import { Layer } from 'models/Layer/layer.model';
import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayerService from 'services/LayerService/layer.service';

interface SelectedLayerType {
    selectedLayerId?: string;
    updateSelectedLayerId?: (layerId?: string) => void;
    updateSelectedLayer?: (newLayer: Layer) => void;
    layer?: Layer;
    loading?: boolean;
    updateLayerDetails?: (newLayer:Layer) => void
}

//create context
const SelectedLayerContext = createContext<SelectedLayerType>({});

//create context provider component
const SelectedLayerState: FC<SelectedLayerType> = ({ children }) => {
    const [selectedLayerId, setSelectedLayerId] = useState<string>();
    const updateSelectedLayerId = (layerId?: string) => {
        setSelectedLayerId(layerId);
    };
    const { prototypeId, planId, spaceId } = useParams();
    const { layer, loading, getLayer, setLayer, updateLayerDetails } = LayerService();
    const updateSelectedLayer = (newLayer: Layer) => {
        setLayer(newLayer);
    };

    useEffect(() => {
        (prototypeId && planId && spaceId && selectedLayerId) && getLayer(prototypeId, planId, spaceId, selectedLayerId);
    }, [selectedLayerId]);

    return <SelectedLayerContext.Provider value={{ selectedLayerId, updateSelectedLayerId, updateSelectedLayer, layer, loading, updateLayerDetails }}>
        {children}
    </SelectedLayerContext.Provider>;
};

//using the context
export const useSelectedLayerId = () => {
    const context = useContext<SelectedLayerType>(SelectedLayerContext);
    if (!context) {
        throw new Error("selectedLayer context must be used within selectedLayer context provider ");
    }
    return { ...context };
};


export default SelectedLayerState;