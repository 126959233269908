import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { Sustainability } from "../Sustainability/sustainability.model";
import { Measurement } from "../Measurement/measurement.model";
import { Attachment } from "../Attachment/attachment.model";
import { Type } from "../Type/type.model";
import { SubCategory } from "../SubCategory/subCategory.model";
import { Category } from "../Category/category.model";
import { Inventory } from "../Inventory/inventory.model";
import { Variant } from "models/Variant/variant.model";
import { Location, LocationParams } from "models/Location/location.model";
import { User } from "models/user.model";
import { ProductStatus } from "enums/productStatus.enum";
import { v4 as uuidv4 } from "uuid";
import { PaginationParams } from "models/PaginationParams";
import { CurrencyQueryParams } from "models/CostPerItem/costPerItem.model";
import { MetricQueryParams } from "models/Metric/metric.model";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { MassUnit } from "enums/massUnit.enum";
import { ParsedQs } from "qs";
import get from "lodash.get";
import { parseNumber } from "shared/utils/parser";
import { CurrencyUnit } from "enums/currencyUnit.enum";

export const ProductParamKeyType = "";

export class Product {
  @serializable(list(object(Attachment)))
  attachments: Attachment[] = [];

  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable
  code?: string;

  @serializable
  description?: string;

  @serializable(object(Inventory))
  inventory = new Inventory();

  @serializable(object(Category))
  category = new Category();

  @serializable(alias("sub_category", object(SubCategory)))
  subCategory = new SubCategory();

  @serializable(object(Type))
  type = new Type();

  @serializable(object(Attachment))
  image = new Attachment();

  @serializable(object(Measurement))
  measurement? = new Measurement();

  @serializable(object(Sustainability))
  sustainability = new Sustainability();

  @serializable(alias("three_dimension_model", object(Attachment)))
  threeDimensionModel?: Attachment;

  @serializable(alias("production_location", object(Location)))
  productionLocation? = new Location();

  @serializable(object(User))
  vendor = new User();

  @serializable
  status?: ProductStatus;

  @serializable(list(object(Variant)))
  variants: Variant[] = [new Variant(uuidv4())];
}

export class ProductParams extends PaginationParams {
  @serializable
  search = "";

  @serializable(
    custom(
      (val) => val || undefined,
      () => undefined
    )
  )
  sustainable?: boolean;

  @serializable(list(primitive()))
  categories: string[] = [];

  @serializable(alias("sub_categories", list(primitive())))
  subCategories: string[] = [];

  @serializable(alias("search_category", primitive()))
  searchCategory = "all";

  @serializable(list(primitive()))
  types: string[] = [];

  @serializable(object(CurrencyQueryParams))
  cost = new CurrencyQueryParams();

  @serializable(list(primitive()))
  colors: string[] = [];

  @serializable(list(primitive()))
  materials: string[] = [];

  @serializable(alias("production_location", object(LocationParams)))
  productionLocation?: LocationParams;

  @serializable(alias("inventory", object(LocationParams)))
  inventory?: LocationParams;

  @serializable(object(MetricQueryParams))
  length = new MetricQueryParams(DistanceUnit.CM);

  @serializable(object(MetricQueryParams))
  width = new MetricQueryParams(DistanceUnit.CM);

  @serializable(object(MetricQueryParams))
  height = new MetricQueryParams(DistanceUnit.CM);

  @serializable(object(MetricQueryParams))
  weight?: MetricQueryParams<"mass"> = new MetricQueryParams(MassUnit.KG);

  @serializable(list(primitive()))
  statuses: ProductStatus[] = [];

  @serializable(alias("sort_by", primitive()))
  sortBy = "product";

  @serializable(alias("sort_direction", primitive()))
  sortDirection?: "asc" | "desc" = "asc";

  static initializeParams(params: ParsedQs): ProductParams {
    const initializeRangeInput = (key: string, defaultValue: string) => {
      const from = get(params, `${key}.from`);

      const to = get(params, `${key}.to`);

      const unit = get(params, `${key}.unit`);

      return {
        from: parseNumber(from),
        to: to ? parseNumber(to) : undefined,
        unit: unit ? unit : defaultValue,
      };
    };

    return {
      ...new ProductParams(),
      ...params,
      sustainable: params?.sustainable === "true",
      cost: initializeRangeInput(
        "cost",
        CurrencyUnit.CAD
      ) as CurrencyQueryParams,
      height: initializeRangeInput(
        "height",
        DistanceUnit.CM
      ) as MetricQueryParams,
      length: initializeRangeInput(
        "length",
        DistanceUnit.CM
      ) as MetricQueryParams,
      width: initializeRangeInput(
        "width",
        DistanceUnit.CM
      ) as MetricQueryParams,
      weight: initializeRangeInput(
        "weight",
        MassUnit.KG
      ) as MetricQueryParams<"mass">,
    };
  }
}

export class ProductMeta {
  @serializable
  total = 0;
}

export class ProductMetaParams {
  @serializable
  category?: string;

  @serializable(alias("sub_category", primitive()))
  subCategory?: string;

  @serializable
  type?: string;
}

export class MaxValuesMeta {
  @serializable
  length?: number;

  @serializable
  height?: number;

  @serializable
  width?: number;

  @serializable
  weight?: number;

  @serializable
  cost?: number;
}
