import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { EditProductParams } from "shared/types/routeParams";
import ProductService from "services/ProductService/product.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { Color } from "models/Color/color.model";
import { useMeta } from "services/useMetaService/useMeta.service";
import { Material } from "models/Material/material.model";
import { Finish } from "models/Finish/finish.model";
import { Country } from "models/Country/country.model";
import useRedirect from "shared/hooks/useRedirect";
import { Form, Formik, FormikConfig } from "formik";
import { Product } from "models/Product/product.model";
import productFormValidation from "./productFormValidation";
import { VariantMetaContext } from "context/MetaContext";
import { LocationContext } from "context/LocationContext";
import ProductFormHeader from "./ProductFormHeader";
import ProductFormContent from "./ProductFormContent";
import "./productForm.scss";
// import ProductSkeletonLoader from "shared/components/ProductSkeletonLoader";
import Spinner from "shared/components/Spinner";

const ProductForm = () => {
  const { productId } = useParams<EditProductParams>();
  const location = useLocation();
  const searchedList = location.search?.slice(1)?.split("&");
  const getIdFromSearchParam = (searchParam: string) =>
    searchParam?.split("=")?.[1];
  const categoryId = getIdFromSearchParam(searchedList?.[0]);
  const subCategoryId = getIdFromSearchParam(searchedList?.[1]);
  const typeId = getIdFromSearchParam(searchedList?.[2]);

  const prePopulatedInitialValues = {
    ...new Product(),
    category: { id: categoryId },
  };
  const {
    product,
    createProduct,
    updateProduct,
    fetchProduct,
    fetchProductCode,
    loading,
  } = ProductService();

  const { fetchMeta: fetchColors, meta: colors } = useMeta({
    key: "colors",
    url: ApiRoutes.COLORS,
    serializingClass: Color,
  });

  const { fetchMeta: fetchMaterials, meta: materials } = useMeta({
    key: "materials",
    url: ApiRoutes.MATERIALS,
    serializingClass: Material,
  });

  const { fetchMeta: fetchFinishes, meta: finishes } = useMeta({
    key: "finishes",
    url: ApiRoutes.FINISHES,
    serializingClass: Finish,
  });

  const {
    fetchMeta: fetchCountries,
    meta: countries,
    loading: countriesLoading,
  } = useMeta({
    key: "countries",
    url: ApiRoutes.COUNTRIES,
    serializingClass: Country,
  });

  const { redirectToViewProduct } = useRedirect();

  const handleSubmit: FormikConfig<Product>["onSubmit"] = async (product) => {
    const newProduct = await (product?.id ? updateProduct : createProduct)(
      product
    );

    if (!newProduct) return;

    redirectToViewProduct(newProduct?.id);
  };

  useEffect(() => {
    if (productId) fetchProduct(productId);
    else fetchProductCode();
  }, [productId]);

  useEffect(() => {
    fetchColors();
    fetchMaterials();
    fetchFinishes();
    fetchCountries();
  }, []);
  return (
    <div className="product-form">
      <Spinner loading={loading}>
        <Formik
          enableReinitialize
          initialValues={
            product?.id ? product : { ...prePopulatedInitialValues, ...product }
          }
          validationSchema={productFormValidation}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => {
            console.log(errors)
            return (
              <Form>
                <VariantMetaContext.Provider
                  value={{
                    colors,
                    materials,
                    finishes,
                  }}
                >
                  <LocationContext.Provider
                    value={{
                      countries,
                      loading: countriesLoading,
                    }}
                  >
                    <ProductFormHeader />
                    <ProductFormContent
                      selectedCategoryId={product.category.id ?? categoryId}
                      selectedSubCategoryId={
                        product.subCategory.id ?? subCategoryId
                      }
                      selectedTypeId={product.type.id ?? typeId}
                      notEdit={!product?.id}
                    />
                  </LocationContext.Provider>
                </VariantMetaContext.Provider>
              </Form>
          )}}
        </Formik>
      </Spinner>
    </div>
  );
};

export default ProductForm;
