import { Attachment } from "models/Attachment/attachment.model";
import React, { useEffect } from "react";
import UploadInput from "shared/components/UploadInput";
import "./spaceGallery.scss";
import SpaceService from "services/SpaceService/space.service";
import { useParams } from "react-router-dom";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import UploadedImage from "shared/components/UploadedImage";

const SpaceGallery = () => {
  const {
    spaceGalleryImageUpload,
    showSpace,
    space,
    setSpace,
    spaceGalleryImageDelete,
  } = SpaceService();
  const handleImageUpload = (imageId: string) => {
    imageId &&
      prototypeId &&
      planId &&
      spaceId &&
      zoneId &&
      spaceGalleryImageUpload(prototypeId, planId, zoneId, spaceId, imageId);
  };
  const handleImageDelete = (imageId: string) => {
    imageId &&
      prototypeId &&
      planId &&
      spaceId &&
      zoneId &&
      spaceGalleryImageDelete(prototypeId, planId, zoneId, spaceId, imageId);
    setSpace({
      ...space,
      gallery: space?.gallery?.filter((image) => image.id !== imageId),
    });
  };
  const { prototypeId, planId, spaceId, zoneId } = useParams();

  useEffect(() => {
    showSpace(prototypeId ?? "", planId ?? "", zoneId ?? "", spaceId ?? "");
  }, [prototypeId, planId, spaceId, zoneId]);

  return (
    <div className="space-gallery">
      <div className="space-gallery__item">
        <UploadInput
          title="Upload Image"
          note="Supported formats: jpg, png & jpeg and < 10MB"
          hideLoaderOnComplete
          primaryTheme
          hideStatusBar={false}
          options={{
            restrictions: {
              allowedFileTypes: [".jpg", ".jpeg", ".png"],
              maxFileSize: 10 * 1024 * 1024, // 10 MB File Size
            },
          }}
          onAttachmentChange={(attachment: Attachment[]) => {
            if (attachment?.[0]?.id) {
              handleImageUpload(attachment?.[0]?.id);
              const gallery = space?.gallery;
              gallery?.push(attachment?.[0]);
              setSpace({
                ...space,
                gallery: gallery,
              });
            }
          }}
        />
      </div>
      {space &&
        space?.gallery?.map(({ url, id }) => (
          <div className="space-gallery__item" key={id}>
            <UploadedImage
              expandable
              hideRemoveTextOnOverlay
              src={url}
              onDelete={() => {
                handleImageDelete(id ?? "");
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default SpaceGallery;
