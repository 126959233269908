import React from "react";
import { Select } from "shared/icons";

import "./emptyPlaceholderSelect.scss";

interface EmptyPlaceholderSelectProps {
  title: string;
}

const EmptyPlaceholderSelect = ({ title }: EmptyPlaceholderSelectProps) => {
  return (
    <div className="empty-placeholder">
      <div className="empty-placeholder__select">
        <Select className="icon--select" />
      </div>
      <div className="empty-placeholder__title">{title}</div>
    </div>
  );
};

export default EmptyPlaceholderSelect;
