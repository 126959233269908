import React from "react";
import { TabsProps } from "antd";
import CategoryConfiguration from "./CategoryConfiguration";
import UserRoleManagement from "views/UserRoleManagement";
import ClientSettings from "./ClientSettings";
import { Module } from "enums/module.enum";

const tabItems: TabsProps["items"] = [
        {
          key: Module.CATEGORY_CONFIGURATION,
          label: "Category Configuration",
          children: <CategoryConfiguration />,
        },
      {
          key: Module.USER_ACCESS_AND_LIMIT,
          label: "User Limit & Access",
          children: <UserRoleManagement />,
        },
        {
          key: Module.CLIENT_SETTINGS,
          label: "Client Settings",
          children: <ClientSettings />,
        }
];

export default tabItems;
