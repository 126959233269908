import { CheckboxGroup } from "shared/components/Checkbox";
import React from "react";
import { CheckboxGroupProps } from "antd/lib/checkbox";

const PicklistDrawerChoices = ({ options, ...props }: CheckboxGroupProps) => {
    return (
        <CheckboxGroup.Formik name="picklists" options={options} />
    );
};

export default PicklistDrawerChoices;;