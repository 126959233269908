import { alias, primitive, serializable } from "serializr";

export class PaginationMeta {
  @serializable
  page = 1;

  @serializable(alias("total_pages", primitive()))
  totalPages = 1;

  @serializable(alias("total", primitive()))
  totalDocs = 0;

  @serializable(alias("prev_page", primitive()))
  prevPage?: number | null | undefined;

  @serializable(alias("next_page", primitive()))
  nextPage?: number | null | undefined;
}
