import React from "react";
import { Attachment as AttachmentModal } from "models/Attachment/attachment.model";
import AttachmentPreview from "../AttachmentPreview";
import Collapse from "../Collapse";
import { Attachments as AttachmentsIcon } from "shared/icons";

import "./attachments.scss";

interface AttachmentsProps {
  attachments: AttachmentModal[];
}

const Attachments = ({ attachments }: AttachmentsProps) => {
  const items = [
    {
      id: 1,
      attachments,
      getHeader: () => (
        <div className="collapse-icon-header">
          <AttachmentsIcon className="icon-grey-3 icon--attachments" />
          <span>Attachments</span>
          <span className="text-grey-2">({attachments?.length})</span>
        </div>
      ),
    },
  ];

  return attachments?.length ? (
    <div className="attachments">
      <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
        {({ attachments }) => (
          <>
            {attachments?.map((attachment) => (
              <AttachmentPreview
                key={attachment?.id}
                attachment={attachment}
                type="download"
              />
            ))}
          </>
        )}
      </Collapse>
    </div>
  ) : (
    <></>
  );
};

export default Attachments;
