import { ProductStatus } from "enums/productStatus.enum";
import { UserRole } from "enums/userRole";
import { alias, list, object, primitive, serializable } from "serializr";
import { PaginationMeta } from "./PaginationMeta";
import { PaginationParams } from "./PaginationParams";

export class User {
  @serializable
  id?: string;

  @serializable
  email?: string;

  @serializable
  password?: string;

  @serializable
  role?: UserRole;

  @serializable
  name?: string;

  @serializable(alias('phone_number',primitive()))
  phoneNumber?: string;

  @serializable
  status = ProductStatus.ACTIVE;

  @serializable(alias('product_cost_edit',primitive()))
  productCostEdit = false;
}

export class UserList {
  @serializable(list(object(User)))
  users?: User[] = [];
  @serializable(object(PaginationMeta))
  meta?: PaginationMeta;
}
export class UserParams extends PaginationParams {
  @serializable
  search = "";

  @serializable
  roles?:string[]=[];

  @serializable
  statuses?:string[]=[];
}
