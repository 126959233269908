import React, { FC } from "react";

import "./folderHeader.scss";

interface FolderHeaderProps {
  title: string;
  extraContent?: React.ReactNode;
}

const FolderHeader: FC<FolderHeaderProps> = ({ title, extraContent }) => {
  return (
    <div className="folder-header">
      <div className="folder-header__title">{title}</div>
      <div className="folder-header__content">{extraContent}</div>
    </div>
  );
};

export default FolderHeader;
