import React from "react";
import Input from "../../Input";
import { Field, Form, Formik } from "formik";
import { Search } from "shared/icons";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";

interface PicklistDrawerSearchProps {
    onSearch: (search: string) => void;
}

const PicklistDrawerSearch = ({ onSearch }: PicklistDrawerSearchProps) => {
    const handleSubmit = (values: { search: string; }) => {
        onSearch(values.search);
    };

    return <Formik initialValues={{ search: "" }} onSubmit={handleSubmit}>
        <Form className='search__bar'>
            <Field as={Input.Search} name="search" className="search-bar__field" />
            <Button className="search__button" type={ButtonType.DEFAULT} htmlType="submit">
                <Search className="cursor-pointer" />
            </Button>
        </Form>
    </Formik>;
};

export default PicklistDrawerSearch;