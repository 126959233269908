import axiosInstance from "interceptor/axiosInstance";
import UserRole from "models/UserRole/userRole.model";
import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";

const UserRoleService = () => {
  const [loading, setLoading] = useState(false);

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const getUserRoles = async () => {
    setLoading(true);
    const { data } = await axiosInstance.get(ApiRoutes.USER_ROLES);
    const userRoleData = deserialize(UserRole, data["user_roles"] as unknown[]);
    setUserRoles(userRoleData);
    setLoading(false);
  };
  const putUserRoles = async (userRole: UserRole) => {
    setLoading(true);
    const userRoleId = String(userRole.id);
    const ENDPOINT = generatePath(ApiRoutes.EDIT_USER_ROLE, {
      userRoleId,
    });
    const { data } = await axiosInstance.put(ENDPOINT, {
      user_role: serialize(UserRole, userRole),
    });
  
    setLoading(false);
  };
  return {
    userRoles,
    loading,
    getUserRoles,
    putUserRoles,
  };
};

export default UserRoleService;
