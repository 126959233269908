import React, { useEffect } from "react";
import Navbar from "../../shared/components/Navbar";
import { Outlet, useLocation } from "react-router-dom";

import "./home.scss";
import NotificationToggleState from "context/NotificationToggle";
import useRedirect from "shared/hooks/useRedirect";

const Home = () => {
  const { redirectToDashBoardListing } = useRedirect();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname=="/")
    redirectToDashBoardListing();
  }, []);
  return (
    <div className="home">
      <NotificationToggleState>
        <Navbar />
      </NotificationToggleState>
      <div className="home__content">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
