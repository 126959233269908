import React, { useEffect } from "react";
import { TabsProps } from "antd";
import CheckboxFilters from "./CheckboxFilters";
import { useProductFilters } from "context/ProductFilterContext";
import { ProductStatus } from "enums/productStatus.enum";
import { useVariantMeta } from "context/MetaContext";
import LocationInput from "shared/components/LocationInput";
import NumberRangeInput from "views/Products/ProductsFilter/NumberRangeInput";
import DimensionFilters from "./DimensionFilters";
import FilterLabel from "./FilterLabel";
import { useFormikContext } from "formik";
import { ProductParams } from "models/Product/product.model";
import { CheckboxGroupProps } from "antd/es/checkbox";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import GroupedChecklist from "./GroupedChecklist";
import { Category } from "models/Category/category.model";
import ProductService from "services/ProductService/product.service";
import SustainabilityFilter from "./SustainablityFilter";
import { Check } from "shared/icons";

export const getTabItems = (): TabsProps["items"] => {
  const { values, setValues } = useFormikContext<ProductParams>();

  const { categories, subCategories, types } = useProductFilters();
  const { maxValuesMeta, fetchMaxValuesMeta } = ProductService();

  useEffect(() => {
    fetchMaxValuesMeta();
  }, []);
  const { materials, colors } = useVariantMeta();

  const filteredSubCategories = values?.categories?.length
    ? subCategories?.filter(
      ({ category }) =>
        category?.id && values?.categories.includes(category?.id)
    )
    : subCategories;

  const filteredTypes =
    values?.categories?.length || values?.subCategories?.length
      ? types?.filter(({ subCategory }) => {
        if (values?.subCategories?.length) {
          return (
            subCategory?.id && values?.subCategories.includes(subCategory?.id)
          );
        } else if (values?.categories?.length) {
          return (
            subCategory?.category?.id &&
            values?.categories?.includes(subCategory?.category?.id)
          );
        } else return true;
      })
      : types;

  const locationFilterCount =
    Number(
      !!(
        values?.productionLocation?.country ||
        values?.productionLocation?.province
      )
    ) + Number(!!(values?.inventory?.country || values?.inventory?.province));

  const dimensionsFilterCount =
    Number(!!(values?.length?.from || values?.length?.to)) +
    Number(!!(values?.width?.from || values?.width?.to)) +
    Number(!!(values?.height?.from || values?.height?.to)) +
    Number(!!(values?.weight?.from || values?.weight?.to));

  const handleSubCategoryChange: CheckboxGroupProps["onChange"] = (
    subCategoryIds
  ) => {
    const categoryIds: string[] = [];

    subCategoryIds?.forEach((id) => {
      const categoryId = SubCategory.findById(subCategories, id?.toString())
        ?.category?.id;
      if (categoryId) categoryIds.push(categoryId);
    });

    setValues({
      ...values,
      subCategories: subCategoryIds as string[],
      categories: Array.from(new Set([...values.categories, ...categoryIds])),
    });
  };

  const handleTypeChange: CheckboxGroupProps["onChange"] = (typeIds) => {
    const categoryIds: string[] = [];
    const subCategoryIds: string[] = [];

    typeIds?.forEach((id) => {
      const subCategoryId = Type.findById(types, id?.toString())?.subCategory
        ?.id;
      if (subCategoryId) subCategoryIds.push(subCategoryId);
    });

    subCategoryIds?.forEach((id) => {
      const categoryId = SubCategory.findById(subCategories, id?.toString())
        ?.category?.id;
      if (categoryId) categoryIds.push(categoryId);
    });

    setValues({
      ...values,
      types: typeIds as string[],
      subCategories: Array.from(
        new Set([...values.subCategories, ...subCategoryIds])
      ),
      categories: Array.from(new Set([...values.categories, ...categoryIds])),
    });
  };

  return [
    {
      key: "categories",
      label: (
        <FilterLabel label="category" count={values?.categories?.length} />
      ),
      children: (
        <CheckboxFilters
          title="Category"
          checkboxProps={{
            name: "categories",
            options: categories?.map(({ name, id }) => ({
              label: name!,
              value: id!,
            })),
          }}
        />
      ),
    },
    {
      key: "subCategories",
      label: (
        <FilterLabel
          label="sub category"
          count={values?.subCategories?.length}
        />
      ),
      children: (
        <GroupedChecklist
          getGroupTitle={Category.findById}
          groupElements={categories}
          groupItems={SubCategory.groupByCategory}
          items={filteredSubCategories}
          name="subCategories"
          onChange={handleSubCategoryChange}
          title="Sub Category"
        />
      ),
    },
    {
      key: "types",
      label: <FilterLabel label="type" count={values?.types?.length} />,
      children: (
        <GroupedChecklist
          getGroupTitle={SubCategory.findById}
          groupElements={subCategories}
          groupItems={Type.groupBySubCategory}
          items={filteredTypes}
          
          name="types"
          onChange={handleTypeChange}
          title="Type"
        />
      ),
    },
    {
      key: "sustainability",
      label: (
        <FilterLabel
          label="Sustainability"
          count={Number(values?.sustainable || "")}
        />
      ),
      children: (
        <SustainabilityFilter />
      ),
    },
    {
      key: "contract-sell-price",
      label: (
        <FilterLabel
          label="Contract Sell Price"
          count={Number(!!(values?.cost?.from || values?.cost?.to))}
        />
      ),
      children: (
        <>
          <div className="product-filter-title">Contract Sell Price</div>
          <NumberRangeInput
            maxVal={maxValuesMeta?.cost}
            label="Contract Sell Price"
            fromName="cost.from"
            toName="cost.to"
            symbol="$"
          />
        </>
      ),
    },
    {
      key: "materials",
      label: <FilterLabel label="material" count={values?.materials?.length} />,
      children: (
        <CheckboxFilters
          title="Material"
          checkboxProps={{
            name: "materials",
            options: materials?.map(({ name, id }) => ({
              label: name!,
              value: id!,
            })),
          }}
        />
      ),
    },
    {
      key: "colors",
      label: <FilterLabel label="color" count={values?.colors?.length} />,
      children: (
        <CheckboxFilters
          filterClassName="color-filter__options"
          title="color"
          checkboxProps={{
            name: "colors",
            options: colors?.map(({ name, id, hexCode }) => ({
              label: <div className="color-checkbox__label">{name!} <Check className="color-checkbox__tick"/></div>,
              value: id!,
              style: { "--color-checkbox-bg": hexCode! } as React.CSSProperties
            })),
          }}
        />
      ),
    },
    {
      key: "Location",
      label: <FilterLabel label="Location" count={locationFilterCount} />,
      children: (
        <div className="products-filter__location">
          <div className="product-filter-title">Location</div>
          <LocationInput
            countryLabel="Production Country"
            name="productionLocation"
            provinceLabel="Production Province/State"
          />
          <LocationInput
            countryLabel="Stored Country"
            name="inventory"
            provinceLabel="Stored Province/State"
          />
        </div>
      ),
    },
    {
      key: "Dimensions",
      label: <FilterLabel label="Dimensions" count={dimensionsFilterCount} />,
      children: <DimensionFilters maxValuesMeta={maxValuesMeta} />,
    },
    {
      key: "Status",
      label: <FilterLabel label="Status" count={values?.statuses?.length} />,
      children: (
        <CheckboxFilters
          title="status"
          checkboxProps={{
            name: "statuses",
            options: Object.values(ProductStatus)?.map((status) => ({
              label: status.split("_").join("")!,
              value: status!,
            })),
          }}
        />
      ),
    },
  ];
};
