import { Attachment } from "models/Attachment/attachment.model";
import { object, serializable } from "serializr";

export class Category {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(object(Attachment))
  image?: Attachment;
  static findById(categories: Category[] = [], id: string) {
    return categories.find((category) => category?.id === id);
  }
}
