//create a context
import React, { FC, useContext } from "react";
import { createContext } from "react";
import useToggle from "shared/hooks/useToggle";

interface NotificationToggleProps {
    isToggled: boolean;
    toggle?: () => void;
    toggleOff?: () => void
}
const notificationToggleInitVal = {
    isToggled: false
}

const NotificationToggleContext = createContext<NotificationToggleProps>(notificationToggleInitVal);

//create a context provider

const NotificationToggleState:FC = ({children}) =>{
    const {isToggled, toggle, toggleOff } = useToggle();
    return <NotificationToggleContext.Provider value={{isToggled,toggle, toggleOff}}>
            {children}
    </NotificationToggleContext.Provider>
}

//use context
export const useNotificationToggle = () =>{
    const context = useContext<NotificationToggleProps>(NotificationToggleContext);
    if(!context){
         throw new Error(
            "NotificationContext must be used within a NotificationContextProvider");
    }
    return {...context}
}

export default NotificationToggleState;