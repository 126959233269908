import React, { FC, useState } from "react";
import { Product } from "models/Product/product.model";
import { Variant } from "models/Variant/variant.model";
import { Dimensions } from "shared/icons";
import Collapse from "shared/components/Collapse";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { MassUnit } from "enums/massUnit.enum";
import Dropdown from "shared/components/DropdownField";
import { Metric } from "models/Metric/metric.model";
import { parseNumber } from "shared/utils/parser";

import "./dimensionAndCost.scss";
import { priceUnitTypesMap } from "shared/constants/products";

interface DimensionAndCostProps {
  product: Product;
  variant: Variant;
}

const DimensionAndCost: FC<DimensionAndCostProps> = ({
  product: { measurement },
  variant: { cost },
}) => {
  const [chosenDistanceMetric, setChosenDistanceMetric] = useState(
    measurement?.length?.unit || DistanceUnit.CM
  );

  const [chosenMassMetric, setChosenMassMetric] = useState(
    measurement?.weight?.unit || MassUnit.KG
  );

  const convertDistanceValue = ({ value }: Metric) =>
    Math.round(
      parseNumber(value) *
        Metric.getDistanceEquivalent(
          measurement?.length?.unit as DistanceUnit,
          chosenDistanceMetric
        ) *
        100
    ) / 100;

  const convertWeightValue = ({ value }: Metric<"mass">) =>
    Math.round(
      parseNumber(value) *
        Metric.getMassEquivalent(
          measurement?.weight?.unit as MassUnit,
          chosenMassMetric
        ) *
        100
    ) / 100;

  const lwtValues = [];
  // if (
  //   measurement &&
  //   measurement.length &&
  //   measurement.width &&
  //   measurement.height &&
  //   measurement.length.value &&
  //   measurement.width.value &&
  //   measurement.height.value
  // ) {
  //   lwhSpan = `${convertDistanceValue(
  //     measurement?.length
  //   )} * ${convertDistanceValue(measurement?.width)} * ${convertDistanceValue(
  //     measurement?.height
  //   )}`;
  // }

  if (measurement && measurement.length && measurement.length.value) {
    lwtValues.push(convertDistanceValue(measurement?.length));
  } else {
    lwtValues.push("0");
  }

  if (measurement && measurement.width && measurement.width.value) {
    lwtValues.push(convertDistanceValue(measurement?.width));
  } else {
    lwtValues.push("0");
  }

  if (measurement && measurement.height && measurement.height.value) {
    lwtValues.push(convertDistanceValue(measurement?.height));
  } else {
    lwtValues.push("0");
  }

  const priceTypeString = cost?.type ? ` / ${priceUnitTypesMap[cost.type]}` : "";

  const details = [
    {
      label: "Contract Sell Price",
      value: `$${cost?.value?.toFixed(2)}${priceTypeString}`,
    },
    {
      label: "Length * Width * Height",
      value: (
        <div className="dimension-and-cost__value__dropdown">
          <span>{lwtValues.join(" * ")}</span>
          <Dropdown
            allowClear={false}
            bordered={false}
            onChange={setChosenDistanceMetric}
            showSearch={false}
            value={chosenDistanceMetric}
            options={Object.values(DistanceUnit).map((value) => ({
              label: value,
              value,
            }))}
          />
        </div>
      ),
    },
    {
      label: "Weight",
      value: (
        <div className="dimension-and-cost__value__dropdown">
          <span>
            {measurement?.weight &&
              measurement?.weight.value ?
              convertWeightValue(measurement?.weight) : 0}
          </span>
          <Dropdown
            allowClear={false}
            bordered={false}
            onChange={setChosenMassMetric}
            value={chosenMassMetric}
            showSearch={false}
            options={Object.values(MassUnit).map((value) => ({
              label: value,
              value,
            }))}
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      id: 1,
      details,
      getHeader: () => (
        <div className="collapse-icon-header">
          <Dimensions className="icon-grey-3 icon--dimensions" />
          <span>Dimensions &amp; Cost</span>
        </div>
      ),
    },
  ];
  return (
    <div className="dimension-and-cost">
      <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
        {({ details }) => (
          <>
            {details?.map(({ label, value }) => (
              <>
                <span className="text-grey-2">{label}</span>
                <span className="dimension-and-cost__value">{value}</span>
              </>
            ))}
          </>
        )}
      </Collapse>
    </div>
  );
};

export default DimensionAndCost;
