import React, { FC } from "react";
import { Variant } from "models/Variant/variant.model";
import "./listVariant.scss";
import { Tooltip } from "antd";
import LazyLoadedImage from "shared/components/LazyLoadedImage";

interface ListVariantProps {
  activeVariant: Variant;
  onClick: (variant: Variant) => void;
  variants?: Variant[];
}

const ListVariant: FC<ListVariantProps> = ({
  onClick,
  variants = [],
  activeVariant,
}) => {
  return (
    <div className="list-variant">
      {variants?.map((variant) => (
        <div
          className={"list-variant__item".concat(
            variant?.id === activeVariant?.id
              ? " list-variant__item--active"
              : ""
          )}
          key={variant?.id}
          onClick={() => onClick(variant)}
        >
          <Tooltip title={variant?.name}>
            <LazyLoadedImage
              src={variant?.image?.thumbnailUrl || variant?.image?.url || ''}
              alt={variant?.image?.name}
            />
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default ListVariant;
