import React, { FC } from "react";
import { Attachment } from "models/Attachment/attachment.model";
import { filesize } from "filesize";
import { Delete, Download, FileThumbnail, PdfThumbnail } from "shared/icons";
import { downloadAttachment } from "shared/utils/downloadAttachment";

import "./attachmentPreview.scss";
import LazyLoadedImage from "../LazyLoadedImage";

interface AttachmentPreviewProps {
  attachment?: Attachment;
  type: "delete" | "download";
  onClick?: (attachment: Attachment) => void;
}

const AttachmentPreview: FC<AttachmentPreviewProps> = ({
  attachment,
  onClick,
  type,
}) => {
  const handleDownload = (attachment: Attachment) =>
    downloadAttachment(attachment);

  const isPdf = attachment?.format?.includes("pdf");
  const isImage = attachment?.format?.includes("image");

  const isDeletable = type === "delete";

  return attachment?.id ? (
    <div className="attachment-preview">
      <div
        className="attachment-preview__placeholder"
        onClick={() => {
          if (!isDeletable) return handleDownload(attachment);

          onClick?.(attachment);
        }}
      >
        <div className="attachment-preview__placeholder__overlay">
          {isDeletable ? (
            <Delete className="icon--white icon--delete" />
          ) : (
            <Download className="icon--white icon--download" />
          )}
        </div>
        {
          isImage ? 
          <LazyLoadedImage src={attachment?.thumbnailUrl || attachment?.url || ''} aspectRatio={'auto'} /> : 
          isPdf ? (
          <PdfThumbnail className="icon--pdf-thumbnail" />
        ) : (
          <FileThumbnail className="icon--file-thumbnail" />
        )}
      </div>
      <div className="attachment-preview__details">
        <div className="attachment-preview__details__name">
          {attachment?.name}
        </div>
        <div className="attachment-preview__details__size">
          {filesize(attachment?.size)}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AttachmentPreview;
