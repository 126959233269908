import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { User } from "models/user.model";
import useRedirect from "shared/hooks/useRedirect";
import { TokenType } from "enums/tokenType";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { AuthContext } from "context/AuthContext";
import { Token } from "models/Token/token.model";
import UserRole from "models/UserRole/userRole.model";

const AuthService = () => {
  const { redirectToDashBoardListing } = useRedirect();

  const { setAuthenticated } = AuthContext();

  const [user, setUser] = useState<User>();

  const [loading, setLoading] = useState<boolean>(false);

  const loginUser = async (user: User) => {
    try {
      setLoading(true);
      const payload = {
        user: { ...serialize(User, user), token_type: TokenType.ACCESS },
      };
      const res = await axiosInstance.post(ApiRoutes.USER_LOGIN, payload);

      if (res.status !== 200) {
        throw new Error("Login Failed");
      }

      const data = res.data;

      const loggedInUser = deserialize(User, data["user"]);

      const token = deserialize(Token, data["token"]);
      const loggedInUserRoleAccess = deserialize(
        UserRole,
        data["user_role_access"]
      );
      localStorage.setItem(
        "user_role_access",
        JSON.stringify(loggedInUserRoleAccess)
      );

      setAuthenticated(user, loggedInUserRoleAccess);

      setUser(user);

      localStorage.setItem("user", JSON.stringify(loggedInUser));

      localStorage.setItem("token", JSON.stringify(token));

      redirectToDashBoardListing();
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Login Failed",
        description: "",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    user,
    loginUser,
  };
};

export default AuthService;
