import { PaginationMeta } from "models/PaginationMeta";
import { Product as ProductModel } from "models/Product/product.model";
import { Variant as VariantModel } from "models/Variant/variant.model";
import { alias, list, object, primitive, serializable } from "serializr";

export class Picklist {
    @serializable
    name?: string;
    @serializable
    id?: string;
    @serializable
    status?: string;
}

export class PicklistedProduct {
    @serializable(alias('variant_id', object(Picklist)))
    variantId?: Picklist;
    @serializable
    quantity?: number;
}

export class AddToPicklistParams {
    @serializable(list(primitive()))
    ids?: string[] = [];

    @serializable(list(object(PicklistedProduct)))
    products?: PicklistedProduct[] = [];
}

class Variant extends VariantModel {
    @serializable(object(ProductModel))
    product?: ProductModel;
}
export class Product {
    @serializable
    id?: string;
    @serializable(alias('total_cost', primitive()))
    totalCost?: number;
    @serializable
    quantity?: number;
    @serializable(alias("variant_id", object(Variant)))
    variantId?: Variant;
}
export class ProductsInPicklist extends Picklist {
    @serializable(list(object(Product)))
    products?: Product[] = [];
}
export class PicklistedProducts {
    @serializable(object(ProductsInPicklist))
    picklist?: ProductsInPicklist;
    @serializable(object(PaginationMeta))
    meta?: PaginationMeta;
}