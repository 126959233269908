import { Province } from "models/Province/province.model";
import { list, object, serializable } from "serializr";

export class Country {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(list(object(Province)))
  provinces?: Province[];
}
