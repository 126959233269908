import React, { useEffect, useState } from "react";
import "./userManagement.scss";
import Table from "shared/components/Table";
import GetColumns from "./columns";
import SearchAndFilter from "views/Products/SearchAndFilter";
import UserService from "services/UserService/user.service";
import useRedirect from "shared/hooks/useRedirect";
import { Button, Pagination } from "antd";
import { Back } from "shared/icons";
import { ButtonType } from "enums/buttonType.enum";
import { UserParams } from "models/user.model";
import { userHaseAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
const PAGINATION_LIMIT = 20;

const UserManagement = () => {
  //ToDo: get users from api (BE dependency)
  const { users, getUsers, loading } = UserService();
  const [params, setParams] = useState<UserParams>(new UserParams());
  const { redirectToEditUser } = useRedirect();
  const handleRowChange = () => ({});
  const handleOpenEditForm = (userId: string) => {
    redirectToEditUser(userId);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = async (page: number) => {
    if (
      page > 0 &&
      users?.meta?.totalPages &&
      page <= users?.meta?.totalPages
    ) {
      await getUsers({
        page,
        limit: PAGINATION_LIMIT,
        search: "",
      });
      setCurrentPage(page);
    }
    params.page = currentPage;
    setParams(params);
  };
  useEffect(() => {
    params.limit = PAGINATION_LIMIT;
    getUsers(params);
  }, []);

  const handleSearch = (search = "") => {
    params.search = search.toLowerCase();
    setParams(params);
    getUsers(params);
  };
  return (
    <div className="user-mgmt">
      <div className="user-mgmt__heading">Users Management</div>
      <div>
        <Table
          columns={GetColumns(handleOpenEditForm).filter((col) =>
            col.key === "editIcon"
              ? userHaseAccess(Module.USER_MANAGEMENT, Method.EDIT)
              : true
          )}
          dataSource={users?.users}
          loading={loading}
          isMapProduct
          headerExtra={
            <SearchAndFilter
              userParams={params}
              setUserParams={(event) => {
                setParams(event);
                getUsers(event as UserParams);
              }}
              hideDropdown={true}
              searchPlaceholder="User"
              onSearch={handleSearch}
              isUser
            />
          }
          pagination={false}
          onRow={handleRowChange}
        />
        {users?.users ? (
          <div className="picklist-footer">
            <div className="doc-counter type-text">
              Showing {currentPage * PAGINATION_LIMIT - PAGINATION_LIMIT + 1}-
              {currentPage * PAGINATION_LIMIT <= (users?.meta?.totalDocs || 1)
                ? currentPage * PAGINATION_LIMIT
                : users?.meta?.totalDocs}{" "}
              of {users?.meta?.totalDocs}
            </div>
            <div className="page-action type-button">
              <Button
                className="back-button"
                type={ButtonType.DEFAULT}
                disabled={users.meta?.prevPage === null}
                onClick={() => {
                  handlePagination(users.meta?.prevPage ?? 1);
                }}
              >
                <Back />
              </Button>
              <Button
                className="next-button"
                type={ButtonType.PRIMARY}
                disabled={users.meta?.nextPage === null}
                onClick={() => {
                  handlePagination(users.meta?.nextPage ?? 1);
                }}
              >
                Next
                <Back className="invert--hr" />
              </Button>
            </div>
            <div className="page-counter type-text">
              Page {currentPage} of {users.meta?.totalPages}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
