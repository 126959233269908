import * as Yup from "yup";
import { CurrencyUnit } from "enums/currencyUnit.enum";

export const currencyValidationSchema = Yup.object().shape({
  value: Yup.number()
    .typeError("Currency Value must be a number")
    .required("Value is required"),
  unit: Yup.string()
    .oneOf(Object.values(CurrencyUnit))
    .required("Unit is required"),
  type: Yup.string().notRequired().nullable(),
});
