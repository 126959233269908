import { Space } from "antd";
import { Form, Formik, FormikConfig } from "formik";
import React, { FC } from "react";
import InputField from "../../shared/components/Input";
import "./appComponents.scss";
import Dropdown from "../../shared/components/DropdownField";
import Pagination from "../../shared/components/Pagination";
import Button from "shared/components/Button";
import { ReactComponent as Add } from "assets/icons/solid/add.svg";
import { ButtonType } from "enums/buttonType.enum";

const initialValues = {};

const AppComponents: FC = () => {
  const handleSubmit: FormikConfig<typeof initialValues>["onSubmit"] = () => {
    // TODO: Since App Components to show case design system, there is not submit logic
  };

  return (
    <div className="app-components">
      <h1 className="text-center text-decoration">App Components</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <InputField
            label="Title"
            type="text"
            name="input"
            placeholder="Enter some text"
          />
          <Dropdown.Formik
            showSearch
            placeholder="Gender"
            name="gender"
            options={[
              {
                label: "Male",
                value: "male",
              },
            ]}
          />
          <Pagination total={500} pageSize={10} />
          <Space className="mt-5">
            <Button type={ButtonType.PRIMARY}>Primary Button</Button>
            <Button type={ButtonType.SUCCESS}>Affirmative Button</Button>
            <Button type={ButtonType.DANGER}>Negative Button</Button>
            <Button type={ButtonType.DEFAULT}>Default Button</Button>
            <Button type={ButtonType.LINK}>Text Primary Button</Button>
            <Button type={ButtonType.TEXT}>Text Default Button</Button>
          </Space>
        </Form>
      </Formik>
    </div>
  );
};

export default AppComponents;
