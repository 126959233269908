import { serializable, alias, primitive } from "serializr";

export class Sustainability {
  @serializable
  id?: string;

  @serializable(alias("is_sustainable", primitive()))
  isSustainable = false;

  @serializable
  description = "";
}
