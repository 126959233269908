import React from "react";
import { Table as AntTable, TableProps as AntTableProps } from "antd";
import { Product } from "models/Picklist";

const PicklistTable = ({ columns, dataSource, ...props }: AntTableProps<Product>) => {
    return <div className="picklist-table-container">
        <AntTable className="picklist-table" columns={columns} dataSource={dataSource} {...props}/>
    </div>;
};

export default PicklistTable;