import { Attachment } from "models/Attachment/attachment.model";

export const downloadAttachment = (attachment: Attachment) => {
  if (!attachment?.url) return;

  const anchor = document.createElement("a");

  anchor.setAttribute("href", attachment?.url);
  anchor.setAttribute("download", "");
  anchor.click();
  anchor.remove();
};
