import React from "react";
import Switch from "shared/components/Switch";
import { ProductStatus } from "enums/productStatus.enum";
import { useField } from "formik";
import { ConfigProvider } from "antd";
import variables from "styles/variables";
import "./statusUpdate.scss";

const StatusUpdate = () => {
  const [{ value }, , { setValue }] = useField<ProductStatus>("status");

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            colorTextQuaternary: variables.DANGER,
            colorTextTertiary: variables.DANGER,
          },
        },
      }}
    >
      <div className="status-update">
        <Switch.Formik
          checked={value === ProductStatus.ACTIVE}
          onChange={(checked) =>
            setValue(ProductStatus[checked ? "ACTIVE" : "IN_ACTIVE"])
          }
          name="status"
          checkedLabel="Active"
          unCheckedLabel="Inactive"
        />
      </div>
    </ConfigProvider>
  );
};

export default StatusUpdate;
