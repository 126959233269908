import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ReactComponent as Filter } from "assets/icons/solid/filter.svg";
import variables from "styles/variables";
import FilterDrawer from "shared/components/FilterDrawer";
import { Form, Formik, FormikConfig, useFormikContext } from "formik";
import { ProductFiltersContext } from "context/ProductFilterContext";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { Color } from "models/Color/color.model";
import { Material } from "models/Material/material.model";
import { Country } from "models/Country/country.model";
import { useMeta } from "services/useMetaService/useMeta.service";
import { VariantMetaContext } from "context/MetaContext";
import { LocationContext } from "context/LocationContext";
import "./usersHeaderExtra.scss";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import CheckboxFilters from "views/Products/ProductsFilter/CheckboxFilters";
import { UserParams } from "models/user.model";
import { Divider } from "antd";
import { UserRole } from "enums/userRole";

interface UsersHeaderExtraProps {
 
  filters?: UserParams;
  onFilterUpdate?: Dispatch<SetStateAction<UserParams>>;
  redirectOnCLose: boolean;
}
const PAGINATION_LIMIT = 10;

const { GREY_2 } = variables;

const UsersHeaderExtra: FC<UsersHeaderExtraProps> = ({
  filters,
  onFilterUpdate,
  redirectOnCLose,
}) => {
  const [toggleUF, setToggleUF] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleSubmit: FormikConfig<UserParams>["onSubmit"] = (params) => {
    navigate({
      pathname: location.pathname,
      hash: location.hash,
    }),
      onFilterUpdate?.({ ...params, page: 1 });
    setToggleUF(false);
  };
 

  const handleClear = () => {
    const updatefilter = new UserParams();
    updatefilter.limit=filters?.limit??PAGINATION_LIMIT;
    updatefilter.page=filters?.page??1;
    updatefilter.search=filters?.search??"";
    updatefilter.roles=[];
    updatefilter.statuses=[];
    onFilterUpdate?.(updatefilter );
    setToggleUF(false);
  };

  return (
    <>
      <div
        className="product-header-extra"
        onClick={() => {
          setToggleUF(!toggleUF);
        }}
      >
        <div className="filter-container">
          <Filter
            className="cursor-pointer icon"
            width={14}
            height={14}
            fill={GREY_2}
          />
          <div className="filter-container__text">Filters</div>
        </div>
      </div>
      {
        <Formik
          enableReinitialize
          initialValues={filters ?? new UserParams()}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Form>
              <FilterDrawer
                open={toggleUF}
                onClose={() => {
                  setToggleUF(false);
                }}
                onClear={handleClear}
                onFilter={handleSubmit}
              >
                <CheckboxFilters
                  title="User Role"
                  checkboxProps={{
                    name: "roles",
                    options: [
                      { name: UserRole.ADMIN},
                      { name:  UserRole.EDITOR,},
                      { name:  UserRole.VIEWER, },
                      { name:  UserRole.VENDOR, },
                    ]?.map(({ name,  }) => ({
                      label: name!,
                      value: name!,
                    })),
                  }}
                /><Divider/>
                 <CheckboxFilters
                  title="Status"
                  checkboxProps={{
                    name: "statuses",
                    options: [
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "in_active" },
                    
                    ],
                  }}
                />
              </FilterDrawer>
            </Form>
          )}
        </Formik>
      }
    </>
  );
};

export default UsersHeaderExtra;
