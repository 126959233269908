export enum Field
{
    TITLE = "title",
    DESCRIPTION = "description",
    DIAGRAM = "diagram",
    IMAGE = "thumbnail",
    GALLERY = "gallery",
    PIN = "pin",
    MAPPED_VARIANT = "mapped variant"
}
export enum ModuleInPrototype
{
    PLAN = "plan",
    PROTOTYPE = "prototype",
    VARIANT = "variant",
    SPACE = "space",
    LAYER = "layer"
}
export enum Action
{
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    REMOVE = "remove",
    ADD = "add",
    MAP = "map"

}