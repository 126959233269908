import { serializable, alias, primitive } from "serializr";

export class Coordinate {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("x", primitive()))
  x?: number;

  @serializable(alias("y", primitive()))
  y?: number;
}
