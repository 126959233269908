import React from "react";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { Delete } from "shared/icons";
import ConfigItemForm from "../ConfigItemForm";
import { Tooltip } from "antd";

import "./folderItemExtra.scss";

interface FolderItemExtraProps<T> {
  title?: string;
  item: T;
  onUpdate: (item: T) => Promise<void>;
  onDelete: (item: T) => void;
}

const FolderItemExtra = <T extends Category | SubCategory | Type>({
  title,
  item,
  onUpdate,
  onDelete,
}: FolderItemExtraProps<T>) => {
  const isCategory = item instanceof Category;

  const isSubCategory = item instanceof SubCategory;

  const isType = item instanceof Type;

  const formTitle = isCategory
    ? "Category"
    : isSubCategory
    ? "Sub Category"
    : isType
    ? "Type"
    : "";

  const handleDelete = () => onDelete(item);

  return (
    <div className="folder-item-extra">
      <span className="folder-item-extra__title">{title}</span>
      <span className="folder-item-extra__icons">
        <ConfigItemForm item={item} onSubmit={onUpdate} title={formTitle} />
        <Tooltip placement="top" title={`Delete ${formTitle}`}>
          <Delete onClick={handleDelete} className="icon icon--delete" />
        </Tooltip>
      </span>
    </div>
  );
};

export default FolderItemExtra;
