import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { alias, object, serializable } from "serializr";

export class CategoryConfiguration {
  @serializable(object(Category))
  category = new Category();

  @serializable(alias("sub_category", object(SubCategory)))
  subCategory = new SubCategory();

  @serializable(object(Type))
  type = new Type();
}
