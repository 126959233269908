import React, { useEffect } from "react";
import { CategoryConfiguration } from "models/CategoryConfiguration/categoryConfiguration.model";
import DeleteProductMapping from "./DeleteProductMapping";
import ProductService from "services/ProductService/product.service";
import DeleteConfigModal from "./DeleteConfigModal";

import "./deleteConfig.scss";

interface DeleteConfigProps {
  config?: CategoryConfiguration;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteConfig = ({ config, onCancel, onDelete }: DeleteConfigProps) => {
  const { productsMeta, productsMetaLoading, fetchProductsMeta } =
    ProductService();

  useEffect(() => {
    if (!config) return;

    fetchProductsMeta({
      category: config.category?.id,
      subCategory: config.subCategory?.id,
      type: config.type?.id,
    });
  }, [config]);

  return config ? (
    productsMetaLoading || productsMeta?.total ? (
      <div className="delete-config">
        <DeleteProductMapping
          onDelete={onDelete}
          loading={productsMetaLoading}
          meta={productsMeta}
          config={config}
          onCancel={onCancel}
        />
      </div>
    ) : (
      <DeleteConfigModal
        open
        config={config}
        onDelete={onDelete}
        onCancel={onCancel}
      />
    )
  ) : (
    <></>
  );
};

export default DeleteConfig;
