import { Attachment } from "models/Attachment/attachment.model";
import { object, serializable,primitive,alias } from "serializr";

export class Clients {
  @serializable
  id?: string;

  @serializable
  email?: string;

  @serializable(alias("phone_number",primitive()))
  phoneNumber?: string;

  @serializable(alias("client_name", primitive()))
  clientName?: string;

  @serializable(object(Attachment))
  image?: Attachment;
  
}
