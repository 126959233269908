import React from "react";
import {
  Button as AntButton,
  ButtonProps as AntButtonProps,
  ConfigProvider,
} from "antd";
import { ButtonType } from "enums/buttonType.enum";
import { ComponentTokenMap } from "antd/es/theme/interface";
import variables from "styles/variables";

import "./button.scss";

interface ButtonProps extends Omit<AntButtonProps, "type"> {
  type: ButtonType;
}

const {
  NEGATIVE,
  NEGATIVE_2,
  SUCCESS_DARK_10,
  SUCCESS_LIGHT_20,
  SUCCESS_LIGHT_60,
  SUCCESS,
  GREY,
  GREY_4,
} = variables;

const Button = ({ type, className = "", ...props }: ButtonProps) => {
  let themeConfig: ComponentTokenMap["Button"] = {};

  const isSuccess = type === ButtonType.SUCCESS;

  const isDanger = type === ButtonType.DANGER;

  const isDefault = type === ButtonType.DEFAULT;

  if (isSuccess)
    themeConfig = {
      colorBgContainerDisabled: SUCCESS_LIGHT_60,
      colorPrimary: SUCCESS,
      colorPrimaryHover: SUCCESS_DARK_10,
      colorPrimaryActive: SUCCESS_LIGHT_20,
    };
  else if (isDanger)
    themeConfig = {
      colorBgContainerDisabled: GREY_4,
      colorPrimary: NEGATIVE,
      colorPrimaryHover: NEGATIVE,
      colorPrimaryActive: NEGATIVE_2,
    };
  else if (isDefault)
    themeConfig = {
      colorPrimary: GREY,
      colorPrimaryHover: GREY,
      colorPrimaryActive: GREY,
      opacityLoading: 0.4,
      colorBorder: GREY_4,
    };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: { borderRadius: 8, ...themeConfig },
        },
      }}
    >
      <AntButton
        className={`button text-capitalize ${className}`.trimEnd()}
        type={isDanger || isSuccess ? "primary" : type}
        {...props}
      />
    </ConfigProvider>
  );
};

export default Button;
