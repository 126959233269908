import { Plan } from "models/Plans/plans.model";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import PlanService from "services/PlanService/plan.service";

interface SelectedPlanType {
  selectedPlanId?: string;
  plan?: Plan;
  planLoading?: boolean;
  handleSetSelectedPlanId?: (planId?: string) => void;
  updatePlanDetails?: (plan: Plan) => void;
}

// create context
export const SelectedPlanContext = createContext<SelectedPlanType>({});

interface SelectedPlanPropsType {
  viewId?: string;
}

// create context provider
const SelectedPlanState: FC<SelectedPlanPropsType> = ({ viewId, children }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const handleSetSelectedPlanId = (planId?: string) => {
    setSelectedPlanId(planId);
  };
  const {
    plan,
    loading: planLoading,
    showPlan,
    updatePlanDetails,
  } = PlanService();

  useEffect(() => {
    if (viewId && selectedPlanId) showPlan(viewId, selectedPlanId);
  }, [selectedPlanId]);

  return (
    <SelectedPlanContext.Provider
      value={{
        selectedPlanId,
        handleSetSelectedPlanId,
        plan,
        planLoading,
        updatePlanDetails,
      }}
    >
      {children}
    </SelectedPlanContext.Provider>
  );
};

// using the context
export const useSelectedPlan = () => {
  const context = useContext<SelectedPlanType>(SelectedPlanContext);
  if (!context) {
    throw new Error(
      "selectedPlan context must be used within selectedPlan context provider "
    );
  }
  return { ...context };
};

export default SelectedPlanState;
