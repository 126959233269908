import { LocalStorageKey } from "enums/localStorageKey";

export const setItem = (key: LocalStorageKey, item: unknown) => {
  if (typeof item === "object") localStorage.setItem(key, JSON.stringify(item));
  else if (typeof item === "string") localStorage.setItem(key, item);
};

export const getItem = <T = string>(
  key: LocalStorageKey,
  isJson?: boolean
): T => {
  const item = localStorage.getItem(key) || "";

  return isJson ? JSON.parse(item || "{}") : item;
};
