import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Axios, { CancelTokenSource } from "axios";
import { Clients } from "models/ClientSettings/clientSettings.model";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { generatePath } from "react-router-dom";

const ClientService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clientUpdateLoading, setClientUpdateLoading] = useState(false);

  const [client, setclient] = useState<Clients>();
  const [clientsToken, setclientsToken] = useState<CancelTokenSource>();

  const updateClients = async (client: Clients) => {
    try {
      setClientUpdateLoading(true);
      const clientId = client.id;
      const ENDPOINT = generatePath(ApiRoutes.CLIENTSETTINGS_UPDATE, {
        clientId,
      });
      client.image =
        client.image == null || client.image == undefined ? {} : client.image;

      const { data } = await axiosInstance.put(ENDPOINT, {
        client: serialize(Clients, client),
      });
      const clientUpdated = deserialize(Clients, data["client"]);
      setclient(clientUpdated);
      Notification({
        message: "Client Settings Updated Successfully",
        type: NotificationTypes.SUCCESS,
      });
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Client",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setClientUpdateLoading(false);
    }
  };
  const fetchClients = async () => {
    try {
      setLoading(true);

      clientsToken?.cancel("clients Fetch Cancelled");

      const newCancelToken = Axios.CancelToken.source();

      setclientsToken(newCancelToken);

      const { data } = await axiosInstance.get(ApiRoutes.CLIENTSETTINGS, {
        cancelToken: newCancelToken.token,
      });
      const client = deserialize(Clients, data["client"] as Clients);

      setclient(client);
      setclientsToken(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    client,
    clientUpdateLoading,
    fetchClients,
    updateClients,
  };
};

export default ClientService;
