import React, { ComponentProps, FC } from "react";
import { Add } from "shared/icons";
import { Tooltip } from "antd";

import "./addItemIcon.scss";

interface AddItemIconProps {
  onAdd: () => void;
  tooltipOptions: ComponentProps<typeof Tooltip>;
}

const AddItemIcon: FC<AddItemIconProps> = ({ onAdd, tooltipOptions }) => (
  <Tooltip className="add-item-icon" placement="right" {...tooltipOptions}>
    <Add className="icon-primary icon--add" onClick={onAdd} />
  </Tooltip>
);

export default AddItemIcon;
