import { serializable, alias, object, primitive } from "serializr";
import { Location } from "../Location/location.model";
import { CostPerItem } from "../CostPerItem/costPerItem.model";
import { CurrencyUnit } from "enums/currencyUnit.enum";

export class Inventory {
  @serializable
  id?: string;

  @serializable(alias("available_count", primitive()))
  availableCount?: number;

  @serializable(alias("cost_per_item", object(CostPerItem)))
  costPerItem = new CostPerItem(CurrencyUnit.CAD);

  @serializable(alias("lead_time", primitive()))
  leadTime?: number;

  @serializable(object(Location))
  location?: Location;
}
