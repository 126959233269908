import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Uppy, { UppyFile } from "@uppy/core";
import Axios from "axios";
import { ImageFormat } from "enums/imageFormats";
import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance, { getHeaders, getToken } from "interceptor/axiosInstance";
import { Attachment } from "models/Attachment/attachment.model";
import { useCallback, useEffect } from "react";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import AttachmentService from "services/AttachmentService/attachment.service";
import Notification from "shared/components/Notification";
import { generateFileFromURL } from "shared/utils/generateFileFromURL";

export const useUppyMultipartUpload = (
  uppy: Uppy,
  onAttachmentChange?: (attachments: Attachment[]) => void,
  validateAspectRatio?: boolean
) => {
  const {
    attachment,
    setAttachment,
    attachments,
    addAttachment,
    uploadAttachment,
  } = AttachmentService();

  const isValidAccessToken = useCallback(
    async () => {
      // TODO: BE to implement a 'whoami' api
      try {
        await Axios.get(ApiRoutes.BASE_URL + ApiRoutes.COLORS, {
          headers: getHeaders(),
        });
      } catch (err) {
        await axiosInstance.get(ApiRoutes.COLORS);
        uppy.getPlugin<AwsS3Multipart>('AwsS3Multipart')?.setOptions({
          companionHeaders: {
            "uppy-auth-token": getToken(),
          },
        });
        uppy.emit('restriction-failed', [{}, { error: 'TokenValidationFailed' }]);
        Notification({
          message: 'Please try again!',
          type: NotificationTypes.ERROR
        });
      }
    },
    [uppy]);

  const isValidAspectRatio = useCallback(
    ({ data, name, id }: UppyFile) => {
      if (
        !validateAspectRatio ||
        !Object.values(ImageFormat).includes(data?.type as ImageFormat)
      )
        return;

      const fileReader = new FileReader();

      fileReader.readAsDataURL(data);

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          if (img.naturalWidth / img.naturalHeight !== 1) {
            uppy.info(`${name} error`);

            Notification({
              message: "Image aspect ratio must be 1:1",
              type: NotificationTypes.ERROR
            });

            uppy.removeFile(id);
            uppy.emit('restriction-failed', [{ id }, { error: 'AspectRatioRestrictionFailed' }]);
            uppy.cancelAll();
          }
        };

        img.src = (fileReader.result || "")?.toString();
      };
    },
    [validateAspectRatio, uppy]
  );

  const handleAddAttachment = useCallback(async (file) => {
    if (!file) return;

    const { type, name, size, preview, extension } = file;

    const s3Key = file?.s3Multipart?.key;

    let thumbnailS3Key = "";

    if (Object.values(ImageFormat).includes(type)) {
      const thumbnail = await generateFileFromURL(preview, name);

      const thumbnailAttachment = await uploadAttachment(thumbnail);

      thumbnailS3Key = thumbnailAttachment?.s3Key || "";
    }

    if (!s3Key) return;

    const attachment = await addAttachment({
      format: type || extension,
      name,
      size,
      s3Key,
      thumbnailS3Key,
    });

    return attachment;
  }, []);

  const handleAttachments = useCallback(
    async ({ successful }) => {
      try {
        const attachments: Attachment[] = [];

        const attachmentPromises: Promise<Attachment | undefined>[] = [];

        for (const { data, s3Multipart, preview, extension } of successful) {
          attachmentPromises.push(
            handleAddAttachment(
              Object.assign(data, {
                s3Multipart,
                preview,
                extension,
              })
            )
          );
        }

        (await Promise.all(attachmentPromises)).forEach((attachment) => {
          if (attachment) attachments.push(attachment);
        });

        onAttachmentChange?.(attachments);
        uppy.cancelAll();
      } catch (ex) {
        Notification({
          type: NotificationTypes.ERROR,
          message: (ex as Error).message || "Unable to add Attachment",
        });
      }
    },
    [onAttachmentChange, handleAddAttachment]
  );

  useEffect(() => {
    uppy.on("complete", handleAttachments);
    uppy.on("file-added", isValidAccessToken);
    uppy.on("file-added", isValidAspectRatio);

    return () => {
      uppy.off("complete", handleAttachments);
      uppy.off("file-added", isValidAspectRatio);
      uppy.off("file-added", isValidAccessToken);
    };
  }, [handleAttachments, isValidAspectRatio, isValidAccessToken]);

  return {
    attachment,
    attachments,
    handleAddAttachment,
    setAttachment,
    handleAttachments,
  };
};
