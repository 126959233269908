import React, { useEffect } from "react";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";
import Drawer from "../Drawer";
import { usePicklistToggle } from "context/PicklistToggleContext";
import "./picklistDrawer.scss";
import PicklistDrawerSearch from "./PicklistDrawerSearch";
import PicklistDrawerChoices from "./PicklistDrawerChoices";
import AddPicklistPopover from "./AddPicklistPopover";
import InputNumber from "../InputNumber";
import { Form, Formik } from "formik";
import { addProductValidationSchema } from "./picklistDrawer.validation";
import PicklistService from "services/PicklistService";
import { Picklist } from "models/Picklist";
import { priceUnitTypesMap } from "shared/constants/products";

const formInitialValues = {
  quantity: { value: 1 },
  picklists: [],
};

const PicklistDrawer = (props: any) => {
  const {
    picklistIsToggled,
    togglePicklistOff,
    removePicklistSelectedProduct,
    picklistSelectedProduct,
    picklistSelectedvariant,
    removePicklistSelectedvariant,
  } = usePicklistToggle();
  const {
    picklists,
    fetchPicklists,
    createPicklist,
    addProductVariantToPicklists,
  } = PicklistService();

  const picklistDrawerClose = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    togglePicklistOff?.();
    removePicklistSelectedProduct?.();
    removePicklistSelectedvariant?.();
  };

  const createPicklistHandle = (data: Picklist) => {
    createPicklist(data);
  };

  const handleSubmit = (values: typeof formInitialValues) => {
    const payload = {
      products: [
        {
          variantId: { id: picklistSelectedvariant?.id },
          quantity: values.quantity.value,
        },
      ],
      ids: values.picklists,
    };
    addProductVariantToPicklists(payload);
    togglePicklistOff?.();
  };

  const onSearchHandle = (search: string) => {
    fetchPicklists(search);
  };

  useEffect(() => {
    if (picklistIsToggled) {
      fetchPicklists();
    }
  }, [picklistIsToggled]);

  return picklistSelectedProduct && picklistSelectedvariant ? (
    <Drawer
      rootClassName="picklist-drawer-root"
      className="picklist-drawer"
      {...props}
      open={picklistIsToggled}
    >
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={addProductValidationSchema}
      >
        {() => (
          <Form>
            <header className="text-heavy picklist-drawer__header">
              <span className="picklist-drawer__header__title">
                Add to Picklist
              </span>
              <Button
                type={ButtonType.DEFAULT}
                htmlType={"reset"}
                onClick={(e) => {
                  picklistDrawerClose(e);
                }}
              >
                Close
              </Button>
              <Button type={ButtonType.PRIMARY} htmlType="submit">
                Add
              </Button>
            </header>
            <main className="picklist-drawer__main">
              <div className="picklist-drawer__main__product__container">
                <img
                  className="picklist-drawer__main__product__image"
                  src={picklistSelectedvariant?.image?.url}
                ></img>
                <div className="picklist-drawer__main__product__details">
                  <p className="picklist-drawer__main__product__vendor">
                    {picklistSelectedProduct?.vendor?.name}
                  </p>
                  <p className="picklist-drawer__main__product__name">
                    {picklistSelectedProduct?.name}
                  </p>
                  <p className="picklist-drawer__main__product__variant">
                    {picklistSelectedvariant?.name}
                  </p>
                  <div className="picklist-drawer__main__product__qty">
                    <InputNumber.Formik
                      name="quantity"
                      type="whole"
                      label="Qty:"
                    ></InputNumber.Formik>
                  </div>
                  <p className="picklist-drawer__main__product__cost">
                    ${picklistSelectedvariant?.cost?.value}{" "}
                    {picklistSelectedvariant?.cost?.type
                      ? `/ ${priceUnitTypesMap[picklistSelectedvariant.cost.type]}`
                      : ""}
                  </p>
                </div>
              </div>
              <div className="picklist-drawer__main__picklist__container">
                <div className="picklist-drawer__main__picklist__header">
                  <AddPicklistPopover
                    createPicklistHandle={createPicklistHandle}
                  />
                </div>
                <div className="picklist-drawer__main__picklist__search">
                  <PicklistDrawerSearch onSearch={onSearchHandle} />
                </div>
                <div className="picklist-drawer__main-picklist-select scrollbar-visible">
                  {picklists?.length ? (
                    <PicklistDrawerChoices
                      options={picklists?.map(({ id, name, status }) => {
                        if (id && name && status) {
                          return {
                            value: id,
                            label: name,
                            disabled: status === "closed",
                          };
                        }
                        return 0;
                      })}
                    />
                  ) : (
                    <div className="picklist-drawer__no-picklist">
                      {" "}
                      There&#39;s no picklist to show
                    </div>
                  )}
                </div>
              </div>
            </main>
          </Form>
        )}
      </Formik>
    </Drawer>
  ) : (
    <></>
  );
};
export default PicklistDrawer;
