import React, { useEffect } from 'react';
import './layerVariantView.scss';
import { useOpenedLayerVariant } from 'context/OpenedLayerVariantContext';
import LayerService from 'services/LayerService/layer.service';
import { useParams } from 'react-router-dom';
import { useSelectedLayerId } from 'context/SelectedLayerContext';
import { DefaultOptionType } from 'antd/es/select';
import { Space, Spin } from 'antd';
import Label from 'shared/components/Label';
import ProductDescription from 'views/ViewProduct/ViewProductContent/ProductDescription';
import Divider from 'shared/components/Divider';
import DimensionAndCost from 'views/ViewProduct/ViewProductContent/VariantDetails/DimensionAndCost';
import InventoryDetails from 'views/ViewProduct/ViewProductContent/VariantDetails/InventoryDetails';
import Sustainability from 'views/ViewProduct/ViewProductContent/Sustainability';
import Attachments from 'shared/components/Attachments';
import { CloseOutlined } from '@ant-design/icons';
import { Replace, Remove } from "shared/icons";
import { Popover } from "antd";
import { usePinMarkers } from "context/PinMarkersContext";
import useRedirect from "shared/hooks/useRedirect";
import LazyLoadedImage from "shared/components/LazyLoadedImage";

const LayerVariantView = () => {
  const { openedLayerVariantId, setOpenedLayerVariantId } = useOpenedLayerVariant();
  const { selectedLayerId, layer, updateSelectedLayer } = useSelectedLayerId();
  const { layerVariant, getLayerVariant, loading, removeProductFromLayer } = LayerService();
  const { prototypeId, planId, spaceId } = useParams();
  const { removePinMarker } = usePinMarkers();
  const { redirectToProductListsReplace } = useRedirect();

  useEffect(() => {
    prototypeId && planId && spaceId && selectedLayerId && openedLayerVariantId
      && getLayerVariant(prototypeId, planId, spaceId, selectedLayerId, openedLayerVariantId);
  }, [openedLayerVariantId]);


  const cardDetails: DefaultOptionType[] = [
    {
      label: "Variant",
      value: layerVariant?.variant?.name,
      id: 1,
    },
    {
      label: "Color",
      value: layerVariant?.variant?.color?.name,
      id: 2,
    },
    {
      label: "Material",
      value: layerVariant?.variant?.material?.name,
      id: 3,
    },
    {
      label: "Finish",
      value: layerVariant?.variant?.finish?.name,
      id: 4,
    }
  ]?.filter(({ value }) => Boolean(value));

  const handleRemove = async () => {
    if (prototypeId && planId && spaceId && layer?.id && openedLayerVariantId) {
      const deleted = await removeProductFromLayer(prototypeId, planId, spaceId, layer?.id, openedLayerVariantId);
      if (deleted) {
        let idx = -1;
        updateSelectedLayer?.({
          ...layer,
          products: layer?.products?.filter(({ id }) => {
            idx += 1;
            return id !== openedLayerVariantId;
          })
        });
        removePinMarker?.(idx);
        setOpenedLayerVariantId?.(undefined);
      }
    }
  };
  
  return (
    <div className='layer-variant-details'>
      {(loading || !layerVariant)
        ? <div className='display-flex flex-justify-center width-100'><Spin /></div>
        : <>
          <div className='layer-variant-details__actions'>
            <Popover content="Replace mapped product">
              <div className="layer-variant-details__action"
            onClick={() => {
              prototypeId && planId && spaceId && layer?.id && openedLayerVariantId && redirectToProductListsReplace(prototypeId, planId, spaceId, layer?.id, openedLayerVariantId);
            }}>
                <Replace className="icon" />
              </div>
            </Popover>
            <Popover content="Remove mapped product">
              <div className="layer-variant-details__action"
                onClick={handleRemove}>
                <Remove className="icon" />
              </div>
            </Popover>
            <Popover content="Close">
              <div className="layer-variant-details__action"
                onClick={() => setOpenedLayerVariantId?.(undefined)}>
                <CloseOutlined className="icon" />
              </div>
            </Popover>
          </div>
          <div className="view-product-content__start">
            <LazyLoadedImage
              src={layerVariant?.variant?.image?.url ?? ""}
              alt="Product Image"
              imageClassName="view-product-content__image"
              aspectRatio='square' />
          </div>
          <div className='product-name'>{layerVariant?.variant?.product?.name}</div>
          <div className="variant-details">
            <div className="variant-details__card">
              {cardDetails?.map(({ label, value, id }) => (
                <Space key={id} direction="vertical">
                  <Label className="text-sm text-primary" label={label} />
                  <span>{value}</span>
                </Space>
              ))}
            </div>
            {layerVariant?.variant?.product
              &&
              <>
                <ProductDescription product={layerVariant?.variant?.product} />
                <Divider />
                <DimensionAndCost product={layerVariant?.variant?.product} variant={layerVariant?.variant} />
                <Divider />
                <InventoryDetails product={layerVariant?.variant?.product} variant={layerVariant?.variant} />
              </>
            }
            {layerVariant?.variant?.product?.sustainability?.isSustainable && (
              <>
                <Divider />
                <Sustainability sustainability={layerVariant?.variant?.product?.sustainability} />
              </>
            )}
            {(layerVariant?.variant?.product?.attachments?.length || layerVariant?.variant?.product?.threeDimensionModel) && (
              <>
                <Divider />
                <Attachments
                  attachments={[
                    ...(layerVariant?.variant?.product?.threeDimensionModel
                      ? [layerVariant?.variant?.product?.threeDimensionModel]
                      : []),
                    ...(layerVariant?.variant?.product?.attachments || []),
                  ]}
                />
              </>
            )}
          </div></>}

    </div>
  );
};

export default LayerVariantView;
