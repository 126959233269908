import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Space } from "models/Space/space.model";
import React, { FC, useEffect, useState } from "react";
import { Edit } from "shared/icons";
import DiagramView from "../DiagramView";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedLayerId } from "context/SelectedLayerContext";
import { useOpenedLayerVariant } from "context/OpenedLayerVariantContext";
import useRedirect from "shared/hooks/useRedirect";
import { useParams } from "react-router-dom";
import {
  PlanParams,
  PrototypeParams,
  SpaceParams,
} from "shared/types/routeParams";
import { DiagramView as DiagramViewEnum } from "enums/diagramView";
import LayerVariantView from "./LayerVariantView";
import SpaceGallery from "./SpaceGallery";
import { CONSTANTS } from "shared/constants";
import { Popover, Skeleton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Activity from "../Activity";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { userHaseAccess } from "utils";
import { useSelectedZone } from "context/SelectedZoneContext";
import { Plan } from "models/Plans/plans.model";
import { Layer } from "models/Layer/layer.model";
import { getRightViewTitle } from "shared/utils/parser";

interface DualViewRightSideType {
  viewDetails?: PrototypeWithPlans | Space;
  viewType:
    | DiagramViewEnum.PROTOTYPE
    | DiagramViewEnum.SPACE
    | DiagramViewEnum.ZONE;
}

const DualViewRightSide: FC<DualViewRightSideType> = ({
  viewDetails,
  viewType,
}) => {
  const getDiagramUrl = (
    viewType: DiagramViewEnum,
    plan?: Plan,
    layer?: Layer,
    selectedZoneId?: string
  ) => {
    if (selectedZoneId) {
      const selectedZone = plan?.zones?.find((z) => z.id === selectedZoneId);

      return (
        selectedZone?.diagram?.url || selectedZone?.diagram?.thumbnailUrl || ""
      );
    }

    if (viewType === DiagramViewEnum.PROTOTYPE) {
      return plan?.diagram?.url || plan?.diagram?.thumbnailUrl || "";
    }

    return layer?.diagram?.url || layer?.diagram?.thumbnailUrl || "";
  };

  const { selectedPlanId, planLoading, plan, handleSetSelectedPlanId } =
    useSelectedPlan();

  const { selectedZoneId, zone, handleSetSelectedZoneId } = useSelectedZone();

  useEffect(() => {
    if (selectedZoneId && handleSetSelectedZoneId) {
      handleSetSelectedZoneId(undefined);
    }
  }, []);

  const { selectedLayerId, loading, layer, updateSelectedLayerId } =
    useSelectedLayerId();
  const { openedLayerVariantId } = useOpenedLayerVariant();
  const { redirectToUpdatePrototype, redirectToEditSpaceForm } = useRedirect();
  const { prototypeId } = useParams<PrototypeParams>();
  const { planId } = useParams<PlanParams>();
  const { spaceId, zoneId } = useParams<SpaceParams>();
  const [showFullDesc, setShowFullDesc] = useState(false);

  const renderPopoverContent = () => {
    return (
      <Popover content="Close">
        <div
          className="diagram-view__image__action dual-view-icons__icon-gp__icon"
          onClick={() => {
            handleSetSelectedPlanId?.("");
            updateSelectedLayerId?.("");
          }}
        >
          <CloseOutlined className="icon" />
        </div>
      </Popover>
    );
  };
  return (
    <>
      {
        <div
          className={`dual-view__right-container tw-mt-20 tw-pt-20 ${
            !openedLayerVariantId
              ? (
                  viewType === DiagramViewEnum.PROTOTYPE
                    ? selectedPlanId
                    : selectedLayerId
                )
                ? "diagram-view-expand"
                : ""
              : ""
          }`}
        >
          {openedLayerVariantId ? (
            <div className="dual-view__right-container__details">
              <div className="dual-view__right-container__details__header">
                <LayerVariantView />
              </div>
            </div>
          ) : (viewType === DiagramViewEnum.PROTOTYPE && selectedPlanId) ||
            (viewType === DiagramViewEnum.SPACE && selectedLayerId) ? (
            planLoading || loading ? (
              <Skeleton.Image
                className="dual-view__right-container__image-skeleton"
                active
              />
            ) : (
              <>
                {(plan?.title || layer?.title) && (
                  <div className="diagram-view__text-overlay tw-px-4 tw-py-2 tw-text-2xl">
                    {getRightViewTitle(
                      viewType,
                      selectedZoneId,
                      plan,
                      layer
                    )}
                  </div>
                )}
                <DiagramView
                  viewType={viewType}
                  closeIcon={renderPopoverContent()}
                  imgUrl={getDiagramUrl(viewType, plan, layer, selectedZoneId)}
                />
              </>
            )
          ) : viewDetails?.id ? (
            <>
              <div className="dual-view__right-container__details">
                <div className="dual-view__right-container__details__header">
                  <div className="top-level-heading">{viewDetails?.title}</div>
                  {userHaseAccess(Module.PROTOTYPE, Method.EDIT) && (
                    <div className="dual-view-icons__icon-gp">
                      <div
                        onClick={() => {
                          viewType === DiagramViewEnum.PROTOTYPE
                            ? redirectToUpdatePrototype(prototypeId ?? "")
                            : redirectToEditSpaceForm(
                                prototypeId ?? "",
                                planId ?? "",
                                zoneId ?? "",
                                spaceId ?? ""
                              );
                        }}
                      >
                        <Edit className="icon--edit tw-w-12 tw-h-12" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="dual-view__right-container__details__description scrollbar-visible">
                  <div className="description--text multiline">
                    <span>
                      {showFullDesc
                        ? viewDetails?.description
                        : viewDetails?.description?.substring(
                            0,
                            CONSTANTS.MAX_DESC_LENGTH_COLLAPSED
                          )}
                      {(viewDetails?.description &&
                        viewDetails?.description?.length < 140) ||
                      showFullDesc
                        ? ""
                        : "..."}
                    </span>
                    <span
                      onClick={() => setShowFullDesc(!showFullDesc)}
                      className={`dual-view__right-container__details__description--action ${
                        viewDetails?.description &&
                        viewDetails?.description?.length < 140
                          ? "display--none"
                          : ""
                      }`}
                    >
                      {showFullDesc ? "See Less" : "See More"}
                    </span>
                  </div>
                </div>
                <hr />
                {viewType === DiagramViewEnum.PROTOTYPE
                  ? prototypeId && <Activity prototypeId={prototypeId} />
                  : userHaseAccess(Module.PROTOTYPE, Method.EDIT) && (
                      <SpaceGallery />
                    )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      }
    </>
  );
};

export default DualViewRightSide;
