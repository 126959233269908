import React from "react";
import { ConfigProvider, Menu, MenuProps } from "antd";
import navItems, { navItemsRight, UserProfile } from "./navItems";
import { useLocation, useNavigate } from "react-router-dom";
import variables from "styles/variables";
import Branding from "assets/images/parrit-red-logo-v2.svg";

import "./navbar.scss";
import { moduleTitle, userHaseAccess } from "utils";
import { Method } from "enums/method.enum";
import NotificationPopup from "./NotificationPopup";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { Module } from "enums/module.enum";

const { WHITE } = variables;

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleSelect: MenuProps["onClick"] = ({ key }) => navigate(key);

  return (
    <>
      <div className="navbar">
        <div className="navbar__top-bar" />
        <div className="navbar__content">
          <ConfigProvider
            theme={{
              token: { colorPrimary: WHITE },
            }}
          >
            <a href="/" className="tw-bg-none tw-w-fit">
              <img src={Branding} className="navbar__logo" alt="Brand" />
            </a>

            <Menu
              activeKey={pathname}
              onClick={handleSelect}
              className="navbar__menu"
              mode="horizontal"
            >
              {navItems.map(({ url, title }) => {
                return (
                  userHaseAccess(moduleTitle(title), Method.VIEW) && (
                    <Menu.Item key={url}>
                      <span className="navbar__menu__item tw-flex tw-items-center tw-tracking-wide">
                        {title}
                      </span>
                    </Menu.Item>
                  )
                );
              })}
            </Menu>
            <div className="navbar__right">
              <NotificationPopup />
              {navItemsRight.map(
                ({ url, icon, component }) =>
                  (url === NavigationRoutes.SETTINGS
                    ? userHaseAccess(
                        Module.CATEGORY_CONFIGURATION,
                        Method.VIEW
                      ) ||
                      userHaseAccess(Module.CLIENT_SETTINGS, Method.VIEW) ||
                      userHaseAccess(Module.USER_ACCESS_AND_LIMIT, Method.VIEW)
                    : true) && (
                    <span
                      onClick={() => url && navigate(url)}
                      className={`${
                        window.location.pathname === NavigationRoutes.SETTINGS
                          ? "icon--settings-enabled"
                          : ""
                      }`}
                      key={url}
                    >
                      {icon ?? component}
                    </span>
                  )
              )}
              <UserProfile />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </>
  );
};

export default Navbar;
