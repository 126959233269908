export default Object.freeze({
  // COMMON
  BORDER_RADIUS: 4,
  BORDER_COLOR: "#d6dbeb",
  CONTROL_OUTLINE: "none",
  FONT_FAMILY: "AvenirMedium",
  PRIMARY_BLACK: "#273239",
  YELLOW: "#ffb03c",

  // PRIMARY COLORS
  PRIMARY_COLOR: "#FF3C00",

  PRIMARY_DARK_10: "#C93000",
  PRIMARY_DARK_20: "#B22A00",
  PRIMARY_DARK_40: "#862000",
  PRIMARY_DARK_60: "#591500",

  PRIMARY_LIGHT_10: "#E2491A",
  PRIMARY_LIGHT_30: "#E9724D",
  PRIMARY_LIGHT_50: "#EF9A80",
  PRIMARY_LIGHT_60: "#F2AE99",
  PRIMARY_LIGHT_70: "#F5C2B3",

  // GREY
  GREY: "#221E1E",
  GREY_2: "#4F4A4A",
  GREY_3: "#939393",
  GREY_4: "#D1D1D6",
  GREY_5: "#F4F4F4",

  // DANGER
  DANGER_DARK_10: "#e63c42",
  DANGER_LIGHT_20: "#ff696d",
  DANGER_LIGHT_60: "#ffb4b6",
  DANGER: "#ff4349",

  // NEGATIVE
  NEGATIVE: "#221E1E",
  NEGATIVE_2: "#4F4A4A",

  // SUCCESS
  SUCCESS_DARK_10: "#20a37a",
  SUCCESS_LIGHT_20: "#50c49f",
  SUCCESS_LIGHT_60: "#a7e1cf",
  SUCCESS: "#24b587",

  // BLUE
  BLUE: "#3d6fe6",

  BLUE_DARK_20: "#3159b8",
  BLUE_DARK_40: "#25438a",
  BLUE_DARK_60: "#182c5c",

  BLUE_LIGHT_20: "#648ceb",
  BLUE_LIGHT_40: "#8ba9f0",
  BLUE_LIGHT_60: "#b1c5f5",

  // BUTTON
  BUTTON_PADDING: 10,

  // SECONDARY BUTTON
  SECONDARY_BUTTON_COLOR: "#fafcff",

  // CHECKBOX
  CHECKBOX_TEXT_COLOR: "#fff",

  // INPUT
  INPUT_BOX_SHADOW: "0 0 0 2px #0d494e",
  INPUT_VALIDATION_ERROR: "#FF4349",

  // RADIO
  RADIO_BORDER_COLOR: "#cacaca",

  // SELECT
  SELECT_BORDER_COLOR: "#fff",
  SELECT_BOX_SHADOW: "0 0 0 2px #0d494e",
  SELECT_OPTION_HOVER: "#f4f5fa",

  // NAVBAR
  NAVBAR_BG: "#181e22",

  WHITE: "#fff",
  TRANSPARENT: "transparent",
});
