import React from "react";
import Dropdown from "shared/components/DropdownField";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import { Product } from "models/Product/product.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import VariantField from "shared/components/VariantField";
import { useVariantMeta } from "context/MetaContext";

import "./addVariant.scss";
import { Tooltip } from "antd";
import { Info } from "shared/icons";

interface AddVariantProps {
  product: Product;
  subCategories: SubCategory[];
  name: string;
}
const AddVariant = ({ subCategories, name, product }: AddVariantProps) => {
  const { colors, materials, finishes } = useVariantMeta();
  const variantFields =
    subCategories?.find(({ id }) => id === product?.subCategory?.id)
      ?.variantFields || [];

  return (
    <div className="add-variant">
      <div>
        <div className="color-label-container">
          <p className="text-medium label color-label">Color</p>
          <Tooltip title="Choose the closest color that is applicable and specify exact item in the description section">
            <Info className="icon--info" />
          </Tooltip>
        </div>
        <Dropdown.Formik
          name={name.concat(".color.id")}
          options={generateDropdownOptions("name", "id", colors)}
        />
      </div>
      <Dropdown.Formik
        label="Material"
        name={name.concat(".material", ".id")}
        options={generateDropdownOptions("name", "id", materials)}
      />
      <Dropdown.Formik
        name={name.concat(".finish", ".id")}
        label="Finish (Optional)"
        options={generateDropdownOptions("name", "id", finishes)}
      />
      {variantFields?.map((variant) => (
        <VariantField key={variant?.id} variant={variant} prefixName={name} />
      ))}
    </div>
  );
};

export default AddVariant;
