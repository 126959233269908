import { useCanAddPin } from "context/CanAddPinContext";
import { DiagramView } from "enums/diagramView";
import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import SpaceService from "services/SpaceService/space.service";
import DualViewContainer from "shared/components/PrototypeCombined";
import {
  addEventListenerEscCancelPinning,
  removeEventListenerEscCancelPinning,
} from "utils";

const SpaceViewCombined = () => {
  const { loading, space, showSpace } = SpaceService();
  const { prototypeId, planId, spaceId, zoneId } = useParams();
  const navigate = useNavigate();
  const breadCrumbItemList = [
    {
      title: space?.plan?.prototype?.title?.toUpperCase(),
      href: generatePath(NavigationRoutes.VIEW_PROTOTYPE, { prototypeId }),
    },
    {
      title: space?.plan?.title?.toUpperCase(),
      onClick: () =>
        navigate(generatePath(ApiRoutes?.PROTOTYPE, { id: prototypeId })),
      href: "#",
    },
    {
      title: space?.title?.toUpperCase(),
      href: "#",
    },
  ];
  const { handleSetAddPin } = useCanAddPin();

  useEffect(() => {
    addEventListenerEscCancelPinning(handleSetAddPin);
    return () => {
      removeEventListenerEscCancelPinning(handleSetAddPin);
    };
  }, []);
  return (
    <DualViewContainer
      viewType={DiagramView.SPACE}
      loading={loading}
      subViewListingService={() => {
        showSpace(prototypeId ?? "", planId ?? "", zoneId ?? "", spaceId ?? "");
      }}
      viewDetails={space}
      subViewList={space?.layers}
      breadCrumbItemList={breadCrumbItemList}
    />
  );
};

export default SpaceViewCombined;
