import { ProtoTypeNotification } from "models/ProtoTypeNotification/prototypeNotification.model";
import React from "react";
import "./activityNotificationForm.scss";
import { Info, NotificationDisabled, NotificationEnabled } from "shared/icons";
import Switch from "shared/components/Switch";
import Divider from "shared/components/Divider";

interface ActivityNotificationPopUpProps {
  notification?: ProtoTypeNotification;
  onChange?: (value: ProtoTypeNotification) => void;
}

const SwitchOption = ({
  label,
  checked,
  onChange,
  name,
}: {
  label: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  name: string;
}) => (
  <div className="container__row_switch">
    <div className="row">
      <Switch.Formik
        id={`switch-${name}`}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div className="switchSmall">{label}</div>
    </div>
  </div>
);

const ActivityNotificationForm: React.FC<ActivityNotificationPopUpProps> = ({
  notification,
  onChange,
}) => {
  const overallNotification = notification?.activityNotification?.overall;

  const handleSwitchChange = (name: string, checked: boolean) => {
    if (notification) {
      const updatedNotification = {
        ...notification,
        activityNotification: {
          ...notification.activityNotification,
          [name]: checked,
        },
      };
      onChange?.(updatedNotification);
    }
  };

  return (
    <div>
      <div
        className={`container__${overallNotification ? "active" : "inactive"}`}
      >
        <div className="container__row">
          <div className="container__row_icon">
            {overallNotification ? (
              <>
                <NotificationEnabled className="icon_enabled" />
                <div className="container__text">Enabled</div>
              </>
            ) : (
              <>
                <NotificationDisabled className="icon" />
                <div className="column">
                  <div className="container__text">Disabled</div>
                  <div className="container__subtext">
                    Toggle on the button to notify on the prototype activities
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="container__row_switch">
            <Switch.Formik
              id="switch-status"
              name="notification.activityNotification.overall"
              checked={overallNotification}
              onChange={(checked) => handleSwitchChange("overall", checked)}
            />
          </div>
        </div>
      </div>
      {overallNotification && (
        <div className="container__row_icon">
          <Info className="info-icon" />
          <div className="container__subtext2">
            Toggle on the button to notify on the prototype activities
          </div>
        </div>
      )}
      <Divider />
      {overallNotification && (
        <div>
          <div className="headingSmall">PROTOTYPE</div>
          <SwitchOption
            label="Prototype attributes update activity"
            checked={
              notification?.activityNotification
                ?.protoTypeAttributesUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("protoTypeAttributesUpdateActivity", checked)
            }
            name="notification.activityNotification.protoTypeAttributesUpdateActivity"
          />
          <Divider />
          <div className="headingSmall">PLAN</div>
          <SwitchOption
            label="Plan creation activity"
            checked={notification?.activityNotification?.planCreationActivity}
            onChange={(checked) =>
              handleSwitchChange("planCreationActivity", checked)
            }
            name="notification.activityNotification.planCreationActivity"
          />
          <SwitchOption
            label="Plan attributes update activity"
            checked={
              notification?.activityNotification?.planAttributesUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("planAttributesUpdateActivity", checked)
            }
            name="notification.activityNotification.planAttributesUpdateActivity"
          />
          <SwitchOption
            label="Plan diagram update activity"
            checked={
              notification?.activityNotification?.planDiagramUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("planDiagramUpdateActivity", checked)
            }
            name="notification.activityNotification.planDiagramUpdateActivity"
          />
          <SwitchOption
            label="Plan deletion activity"
            checked={notification?.activityNotification?.planDeletionActivity}
            onChange={(checked) =>
              handleSwitchChange("planDeletionActivity", checked)
            }
            name="notification.activityNotification.planDeletionActivity"
          />
          <Divider />
          <div className="headingSmall">SPACE</div>
          <SwitchOption
            label="Space creation activity"
            checked={notification?.activityNotification?.spaceCreationActivity}
            onChange={(checked) =>
              handleSwitchChange("spaceCreationActivity", checked)
            }
            name="notification.activityNotification.spaceCreationActivity"
          />
          <SwitchOption
            label="Space attributes update activity"
            checked={
              notification?.activityNotification?.spaceAttributesUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("spaceAttributesUpdateActivity", checked)
            }
            name="notification.activityNotification.spaceAttributesUpdateActivity"
          />
          <SwitchOption
            label="Space gallery activity"
            checked={notification?.activityNotification?.spaceGalleryActivity}
            onChange={(checked) =>
              handleSwitchChange("spaceGalleryActivity", checked)
            }
            name="notification.activityNotification.spaceGalleryActivity"
          />
          <SwitchOption
            label="Space pin update activity"
            checked={notification?.activityNotification?.spacePinUpdateActivity}
            onChange={(checked) =>
              handleSwitchChange("spacePinUpdateActivity", checked)
            }
            name="notification.activityNotification.spacePinUpdateActivity"
          />
          <SwitchOption
            label="Space deletion activity"
            checked={notification?.activityNotification?.spaceDeletionActivity}
            onChange={(checked) =>
              handleSwitchChange("spaceDeletionActivity", checked)
            }
            name="notification.activityNotification.spaceDeletionActivity"
          />
          <Divider />

          <div className="headingSmall">Layer</div>
          <SwitchOption
            label="Layer creation activity"
            checked={notification?.activityNotification?.layerCreationActivity}
            onChange={(checked) =>
              handleSwitchChange("layerCreationActivity", checked)
            }
            name="notification.activityNotification.layerCreationActivity"
          />
          <SwitchOption
            label="Layer attributes update activity"
            checked={
              notification?.activityNotification?.layerAttributesUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("layerAttributesUpdateActivity", checked)
            }
            name="notification.activityNotification.layerAttributesUpdateActivity"
          />
          <SwitchOption
            label="Layer diagram update activity"
            checked={
              notification?.activityNotification?.layerDiagramUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("layerDiagramUpdateActivity", checked)
            }
            name="notification.activityNotification.layerDiagramUpdateActivity"
          />
          <SwitchOption
            label="Layer deletion activity"
            checked={notification?.activityNotification?.layerDeletionActivity}
            onChange={(checked) =>
              handleSwitchChange("layerDeletionActivity", checked)
            }
            name="notification.activityNotification.layerDeletionActivity"
          />
          <Divider />

          <div className="headingSmall">Product</div>
          <SwitchOption
            label="Product map creation activity"
            checked={
              notification?.activityNotification?.productMapCreationActivity
            }
            onChange={(checked) =>
              handleSwitchChange("productMapCreationActivity", checked)
            }
            name="notification.activityNotification.productMapCreationActivity"
          />
          <SwitchOption
            label="Product map attributes update activity"
            checked={
              notification?.activityNotification?.productMapUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("productMapUpdateActivity", checked)
            }
            name="notification.activityNotification.productMapUpdateActivity"
          />
          <SwitchOption
            label="Product Pin  update activity"
            checked={
              notification?.activityNotification?.productPinUpdateActivity
            }
            onChange={(checked) =>
              handleSwitchChange("productPinUpdateActivity", checked)
            }
            name="notification.activityNotification.productPinUpdateActivity"
          />
          <SwitchOption
            label="Product map remove activity"
            checked={
              notification?.activityNotification?.productMapRemoveActivity
            }
            onChange={(checked) =>
              handleSwitchChange("productMapRemoveActivity", checked)
            }
            name="notification.activityNotification.productMapRemoveActivity"
          />
          <Divider />
        </div>
      )}
    </div>
  );
};

export default ActivityNotificationForm;
