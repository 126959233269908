import React, { FC, useEffect, useState } from "react";
import "./categoryNavbar.scss";
import { useMeta } from "services/useMetaService/useMeta.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { Category } from "models/Category/category.model";
import ProductService from "services/ProductService/product.service";
import landingImg from "../../../assets/images/landingImage.png";
import { Col, Row, Skeleton } from "antd";
import { useFilterToggle } from "context/FilterToggleContext";
import useRedirect from "shared/hooks/useRedirect";
import { ProductParams } from "models/Product/product.model";
import { CONSTANTS } from "shared/constants";

interface CategoryNavbarType {
  setParams: React.Dispatch<React.SetStateAction<ProductParams>>;
}

function getNumCategories(dimension: { width: number; height: number }) {
  if (dimension.width > CONSTANTS.LARGE_SCREEN_WIDTH) {
    return 11;
  }
  if (dimension.width > CONSTANTS.MEDIUM_SCREEN_WIDTH) {
    return 8;
  }
  return 5;
}

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const CategoryNavbar: FC<CategoryNavbarType> = ({ setParams }) => {
  const { redirectToProductSearch } = useRedirect();
  const { handleFilterOn } = useFilterToggle();
  const { productsMeta, fetchProductsMeta } = ProductService();
  const [numCategories, setNumCategories] = useState(
    getNumCategories(getCurrentDimension())
  );
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const {
    fetchMeta: fetchCategories,
    meta: categories,
    loading: categoriesLoading,
  } = useMeta({
    key: "categories",
    url: ApiRoutes.CATEGORIES,
    serializingClass: Category,
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
      setNumCategories(getNumCategories(getCurrentDimension()));
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    fetchProductsMeta();
    fetchCategories();
  }, []);

  return (
    <div className="category-navbar">
      <div className="category-navbar__content">
        {/* <div
          className="category-navbar__content__product-count"
          onClick={() => {
            redirectToProductSearch();
          }}
        >
          All Products
        </div> */}
        <Skeleton loading={categoriesLoading}>
          <Row>
            {(categories?.length > numCategories
              ? categories?.slice(0, numCategories)
              : categories
            )?.map((category) => (
              <Col
                span={5}
                key={category?.id}
                className="gutter-row category-navbar__content__name"
              >
                <a
                  href={`#${category?.id}`}
                  className=" tw-tracking-wide tw-text-3xl"
                >
                  {category?.name}
                </a>
              </Col>
            ))}
            {categories?.length > numCategories ? (
              <Col
                span={5}
                className="category-navbar__content__name text-color-primary cursor-pointer tw-text-2xl"
                onClick={() => {
                  redirectToProductSearch();
                }}
              >
                View All
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Skeleton>
      </div>
    </div>
  );
};

export default CategoryNavbar;
