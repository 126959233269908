import { DefaultOptionType } from "antd/lib/select";
import axiosInstance from "interceptor/axiosInstance";
import { useState } from "react";
import { deserialize, serialize } from "serializr";

interface useMetaProps<T, U> {
  serializingClass?: new () => T;
  paramsClass?: new () => U;
  key: string;
  url: string;
}

export const useMeta = <
  T extends Partial<DefaultOptionType> | string,
  U extends object
>({
  key,
  serializingClass,
  paramsClass,
  url,
}: useMetaProps<T, U>) => {
  const [loading, setLoading] = useState(false);

  const [meta, setMeta] = useState<T[]>([]);

  const fetchMeta = async (params?: U, fetchUrl = url) => {
    if (!fetchUrl) return;

    try {
      setLoading(true);
      const { data } = await axiosInstance.get(fetchUrl, {
        params:
          params && paramsClass ? serialize(paramsClass, params) : undefined,
      });

      const meta = serializingClass
        ? deserialize(serializingClass, data[key] as T[])
        : data[key];
      setMeta(meta);
      return meta;
    } finally {
      setLoading(false);
    }
  };

  return {
    setMeta,
    fetchMeta,
    loading,
    meta,
  };
};
