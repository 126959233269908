import React, { FC } from "react";
import { Modal, ModalProps } from "antd";

import "./deleteModal.scss";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";
import { DeleteMultiColored } from "shared/icons";

export interface DeleteModalProps extends Omit<ModalProps, "onCancel"> {
  loading?: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void> | void;
  subTitle?: string;
  title?: React.ReactNode;
  deleteText?: string;
  hideTrash?: boolean;
}

const DeleteModal = ({
  deleteText = "Delete",
  loading,
  onCancel,
  onDelete,
  subTitle,
  title = "Are you sure to delete?",
  hideTrash,
  ...props
}: DeleteModalProps) => {
  return (
    <Modal
      closable={false}
      centered
      footer={null}
      className="delete-modal"
      {...props}
    >
      {!hideTrash && (
        <div className="delete-modal__start">
          <DeleteMultiColored />
        </div>
      )}
      <div className="delete-modal__end">
        <span className="text-heavy delete-modal__end__title">{title}</span>
        {subTitle && (
          <span className="delete-modal__end__sub-title">{subTitle}</span>
        )}
        <div className="delete-modal__end__buttons">
          <Button onClick={onCancel} type={ButtonType.DEFAULT}>
            Cancel
          </Button>
          <Button loading={loading} onClick={onDelete} type={ButtonType.DANGER}>
            {deleteText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
