import React, { useState } from "react";
import Input from "../Input";
import { User } from "models/user.model";
import { Check } from "shared/icons";
import { Empty } from "antd";

import "./userList.scss";
export interface UserListProps {
  onSelect: (user: User) => void;
  searchPlaceholder?: string;
  users: User[];
  selectedUser?: User;
}

const UserList = ({
  users,
  searchPlaceholder,
  onSelect,
  selectedUser,
}: UserListProps) => {
  const [search, setSearch] = useState("");

  const handleSearch = (search = "") => setSearch(search.toLowerCase());

  const filteredUsers = users?.filter((user) =>
    user?.name?.toLowerCase()?.includes(search)
  );

  return (
    <div className="user-list">
      <Input.Search
        bordered={false}
        placeholder={searchPlaceholder}
        onSearch={handleSearch}
      />
      <div className="user-list__values">
        {filteredUsers?.length ? (
          filteredUsers?.map((user) => (
            <div
              className={"user-list__values__item".concat(
                user?.id === selectedUser?.id
                  ? " user-list__values__item--active"
                  : ""
              )}
              key={user?.id}
              onClick={() => onSelect(user)}
            >
              <span>{user?.name}</span>
              <Check className="icon--check" />
            </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};

export default UserList;
