import React, { FC } from "react";

import "./status.scss";

interface StatusProps {
  status?: string;
}
const Status: FC<StatusProps> = ({ status }) => {
  if (!status) return <></>;

  const className = `status status--${status?.split("_").join("-")}`;

  return <span className={className}>{status?.split("_")?.join("")}</span>;
};

export default Status;
