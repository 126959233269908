import React, { ComponentProps } from "react";
import { Input as AntInput, Space } from "antd";
import Label from "../Label";
import { Field, useField, ErrorMessage } from "formik";
import Error from "shared/components/Error";

import "./textArea.scss";

export interface TextAreaProps {
  label?: string;
  error?: string;
}

export interface TextAreaWithFormikProps extends TextAreaProps {
  name: string;
}

const TextArea = ({
  error,
  label,
  ...props
}: TextAreaProps & ComponentProps<typeof AntInput.TextArea>) => {
  return (
    <div className="text-area">
      <Label label={label} hasError={!!error} />
      <AntInput.TextArea placeholder="Enter" {...props} />
    </div>
  );
};

const TextAreaWithFormik = ({
  name,
  ...props
}: TextAreaWithFormikProps & ComponentProps<typeof AntInput.TextArea>) => {
  const [{ value }, { error, touched }] = useField(name);

  const status = touched && error ? "error" : "";

  return (
    <Space direction="vertical">
      <Field
        name={name}
        error={touched && error}
        as={TextArea}
        value={value}
        status={status}
        {...props}
      />
      <ErrorMessage name={name}>
        {(msg) => <Error message={msg} />}
      </ErrorMessage>
    </Space>
  );
};

TextArea.Formik = TextAreaWithFormik;

export default TextArea;
