import { serializable, alias, primitive } from "serializr";

export class Color {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(alias("hex_code", primitive()))
  hexCode?: string;
}
