import { ModuleInPrototype, Action, Field } from "enums/prototypeActivity";
import { User } from "models/user.model";
import { alias, date, object, serializable } from "serializr";

export class HierarchyElement {
  @serializable
  id?: string;

  @serializable
  title?: string;
}

export class Hierarchy {
  @serializable
  id?: string;

  @serializable(alias("prototype_title"))
  prototypeTitle?: string;

  @serializable(object(HierarchyElement))
  plan?: HierarchyElement;

  @serializable(object(HierarchyElement))
  zone?: HierarchyElement;

  @serializable(object(HierarchyElement))
  space?: HierarchyElement;

  @serializable(object(HierarchyElement))
  layer?: HierarchyElement;

  @serializable
  variant?: string;
}
export class PrototypeActivity {
  @serializable
  id?: string;

  @serializable(object(User))
  user?: User;

  @serializable
  module?: ModuleInPrototype;

  @serializable
  action?: Action;

  @serializable(alias("field_updated"))
  fieldUpdated?: Field;

  @serializable(object(Hierarchy))
  hierarchy?: Hierarchy;

  @serializable(alias("old_data"))
  oldData?: string;

  @serializable(alias("new_data"))
  newData?: string;

  @serializable(alias("created_at", date()))
  createdAt?: Date;
}
