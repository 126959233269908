import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PlanParams } from "shared/types/routeParams";
import PlanService from "services/PlanService/plan.service";
import { Form, Formik, FormikConfig } from "formik";
import { Plan } from "models/Plans/plans.model";
import PrototypeService from "services/PrototypeService/prototype.service";
import planValidation from "./planValidation";
import Input from "shared/components/Input";
import TextArea from "shared/components/TextArea";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import useRedirect from "shared/hooks/useRedirect";
import UploadInput from "shared/components/UploadInput";
import { Space, Tooltip } from "antd";
import Label from "shared/components/Label";
import UploadedImage from "shared/components/UploadedImage";

import "./planForm.scss";
import { Back, Delete, ViewDescription } from "shared/icons";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import Spinner from "shared/components/Spinner";
import { useSelectedPlan } from "context/SelectedPlanContext";
import AttachmentPreview from "shared/components/AttachmentPreview";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";

const PlanForm = () => {
  const { prototypeId, planId } = useParams<PlanParams>();
  const { handleSetSelectedPlanId, updatePlanDetails } = useSelectedPlan();
  const { plan, createPlan, updatePlan, showPlan, loading, deletePlan } = PlanService();
  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => { return; },
    onDelete: () => { return; },
    subTitle: '',
    title: <></>,
    deleteText: '',
    hideTrash: true,
  });

  const {
    prototype,
    showPrototype,
    loading: prototypeLoading,
  } = PrototypeService();

  const { redirectToPrototypes, redirectToViewPrototype } = useRedirect();

  const handlePlanViewRedirect = () => {
    if (!prototypeId) return;
    redirectToViewPrototype(prototypeId);
  };

  const handleSubmit: FormikConfig<Plan>["onSubmit"] = async (plan) => {
    if (!prototypeId) return;

    const updatedPlan = await (plan?.id ? updatePlan : createPlan)(
      prototypeId,
      plan
    );
    handleSetSelectedPlanId?.(updatedPlan?.id);
    updatedPlan && updatePlanDetails?.(updatedPlan);
    if (!updatedPlan) return;

    handlePlanViewRedirect();
  };

  const handleUpdate = async (submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
    await submitForm();
    setModalProps({ ...modalProps, open: false });
  };

  const handleDelete = async (prototypeId: string, planId: string) => {
    await deletePlan(prototypeId, planId);
    setModalProps({ ...modalProps, open: false });
    handleSetSelectedPlanId?.(undefined)
    redirectToViewPrototype(prototypeId);
  };

  useEffect(() => {
    if (!prototypeId) return;

    if (!planId) showPrototype(prototypeId);
    else showPlan(prototypeId, planId);
  }, [prototypeId, planId]);

  return (
    <Spinner loading={loading || prototypeLoading}>
      <div className="plan-form">
        <Formik
          initialValues={plan}
          onSubmit={handleSubmit}
          validationSchema={planValidation}
        >
          {({ isSubmitting, values, setFieldValue, submitForm, isValid }) => (
            <>
              <Form>
                <div className="plan-form__container">
                  <div className="plan-form__container__buttons">
                    <div
                      className="plan-form__container__buttons__back"
                      onClick={() => { prototypeId ? redirectToViewPrototype(prototypeId) : redirectToPrototypes(); }}
                    >
                      <span
                        className="icon-container"
                      >
                        <Back className="icon--back" />
                        <span className="text-capitalize">back</span>
                      </span>
                    </div>
                    <div className="plan-form__container__buttons__form-action">
                      {plan?.id && <Button
                        type={ButtonType.TEXT}
                        icon={<Delete className="icon-delete" />}
                        className="button--delete"
                        onClick={() => {
                          setModalProps({
                            open: true,
                            hideTrash: false,
                            onDelete: () => { handleDelete(prototypeId ?? '', plan?.id ?? ''); },
                            onCancel: () => { setModalProps({ ...modalProps, open: false }); },
                            deleteText: "Delete",
                            subTitle: "This plan will be permanently deleted.",
                            loading: prototypeLoading,
                          });
                        }}
                      >
                        Delete Plan
                      </Button>}
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={() => {
                          setModalProps({
                            hideTrash: true,
                            onDelete: () => { prototypeId ? redirectToViewPrototype(prototypeId) : redirectToPrototypes(); },
                            onCancel: () => { setModalProps({ ...modalProps, open: false }); },
                            open: true,
                            deleteText: "Discard",
                            title: "Are you sure to discard?",
                            subTitle: "The changes made will not be saved.",
                            width: 340,
                          });
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        htmlType={planId ? undefined : 'submit'}
                        loading={planId ? false : isSubmitting}
                        onClick={() => {
                          plan?.id && setModalProps({
                            hideTrash: true,
                            onDelete: () => {
                              if (isValid) {
                                handleUpdate(submitForm);
                              } else {
                                setModalProps({ ...modalProps, open: false });
                                Notification({
                                  message: "Invalid data provided",
                                  type: NotificationTypes.ERROR
                                });
                              } },
                            onCancel: () => { setModalProps({ ...modalProps, open: false }); },
                            open: true,
                            deleteText: "Update",
                            title: "Are you sure to update?",
                            subTitle: "Updating the plan diagram will remove all the space pins added. Spaces created remain unaffected",
                            loading: isSubmitting,
                            width: 340
                          });
                        }}
                        type={ButtonType.PRIMARY}
                      >
                        {plan?.id ? "Update" : "Create"}
                      </Button>
                    </div>
                  </div>
                  <div className="plan-form__container__img">
                    <div className="display-flex">
                      <div className="mg-r-8">
                        {(values?.image) ? (
                          <UploadedImage
                            expandable
                            width={44}
                            hideRemoveTextOnOverlay
                            src={values?.image?.url}
                            onDelete={() => { setFieldValue('image', undefined); }}
                          />
                        ) : (
                          <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
                            <UploadInput.Formik
                              name={'image'}
                              width={20}
                              height={44}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <Input.Formik name="title" placeholder="Enter Plan Name" />
                    </div>
                  </div>
                  <div className="plan-form__description__container">
                    <div className="plan-form__description__title">
                      <ViewDescription className="icon-view-description" />
                      <span>Plan Description</span>
                    </div>
                    <TextArea.Formik
                      name="description"
                      rows={10}
                      className="plan-form__description__input"
                    />
                  </div>
                  <div className="plan-form__description__diagram">
                    {values?.diagram?.id ? (
                      <Space direction="vertical">
                        <Label label="Plan Diagram" />
                        <AttachmentPreview
                          key={values?.diagram?.id}
                          type="delete"
                          attachment={values?.diagram}
                          onClick={() => setFieldValue("diagram", undefined)}
                        />
                      </Space>
                    ) : (
                      <UploadInput.Formik
                        className="plan-form__container__diagram"
                        primaryTheme
                        label="Plan Diagram"
                        name="diagram"
                        note="Supported formats: JPG, JPEG & PNG and < 50MB"
                        title="Upload plan diagram"
                        options={{
                          restrictions: {
                            maxFileSize: 50 * 1024 * 1024, // 50MB
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form>

              {/* Confirmation modal */}
              <DeleteModal
                {...modalProps}
              />
            </>
          )}
        </Formik>
      </div>
    </Spinner >
  );
};

export default PlanForm;
