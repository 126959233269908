import { Product } from "models/Product/product.model";
import { Variant } from "models/Variant/variant.model";
import React, { useContext, FC, createContext, useState } from "react";
import useToggle from "shared/hooks/useToggle";

interface PicklistToggleContextType {
    picklistIsToggled: boolean;
    togglePicklistOff?: () => void;
    togglePicklistOn?: () => void;

    picklistSelectedProduct?: Product | undefined;
    setPicklistSelectedProduct?: React.Dispatch<React.SetStateAction<Product | undefined>>;
    removePicklistSelectedProduct?: () => void;

    picklistSelectedvariant?: Variant | undefined;
    setPicklistSelectedvariant?: React.Dispatch<React.SetStateAction<Variant | undefined>>;
    removePicklistSelectedvariant?: () => void;
}
const initialValues = {
    picklistIsToggled: false
};
export const PicklistToggleContext = createContext<PicklistToggleContextType>(initialValues);

//context provider
const PicklistToggleState: FC = ({ children }) => {
    const { isToggled: picklistIsToggled, toggleOff: togglePicklistOff, toggleOn: togglePicklistOn } = useToggle(false);
    const [picklistSelectedProduct, setPicklistSelectedProduct] = useState<Product | undefined>(undefined);
    const [picklistSelectedvariant, setPicklistSelectedvariant] = useState<Variant | undefined>(undefined);

    const removePicklistSelectedProduct = () => {
        setPicklistSelectedProduct(undefined);
    };

    const removePicklistSelectedvariant = () => {
        setPicklistSelectedvariant(undefined);
    };

    return <PicklistToggleContext.Provider value={
        {
            picklistIsToggled, togglePicklistOff, togglePicklistOn,
            picklistSelectedProduct, setPicklistSelectedProduct, removePicklistSelectedProduct,
            picklistSelectedvariant, setPicklistSelectedvariant, removePicklistSelectedvariant
        }
    }>
        {children}
    </PicklistToggleContext.Provider>;
};

//using context
export const usePicklistToggle = () => {
    const context = useContext<PicklistToggleContextType>(PicklistToggleContext);
    if (!context)
        throw new Error(
            "PicklistContext must be used within a PicklistContextProvider"
        );

    return {
        ...context,
    };
};

export default PicklistToggleState;
