import React, { FC, useEffect, useState } from "react";
import NumberRangeInput from "views/Products/ProductsFilter/NumberRangeInput";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { MassUnit } from "enums/massUnit.enum";
import { useFormikContext } from "formik";
import { MaxValuesMeta, ProductParams } from "models/Product/product.model";
import { CONSTANTS } from "shared/constants";

import "./dimensionFilters.scss";

interface DimensionsFilterType {
  maxValuesMeta?: MaxValuesMeta;
}

const centiToMilli = (value: number | undefined) => {
  if (!value) { return 0; }
  return value * CONSTANTS.CENTI_TO_MILLI;
};

const centiToInch = (value: number | undefined) => {
  if (!value) { return 0; }
  return parseFloat((value / CONSTANTS.CENTI_TO_IN).toFixed(2));
};

const kgToLbs = (value: number | undefined) => {
  if (!value) { return 0; }
  return parseFloat((value * CONSTANTS.KG_TO_LBS).toFixed(2));
};

const DimensionFilters: FC<DimensionsFilterType> = ({ maxValuesMeta }) => {
  const { values, setValues } = useFormikContext<ProductParams>();
  const [maxValues, setMaxValues] = useState(maxValuesMeta);

  const distanceUnitOptions = Object.values(DistanceUnit).map((name) => ({
    label: name?.toLowerCase(),
    value: name,
  }));

  const massUnitOptions = Object.values(MassUnit).map((name) => ({
    label: name?.toLowerCase(),
    value: name,
  }));

  const handleDistanceMetricUnits = (unit: string) => {
    const updatedUnit = unit as DistanceUnit;

    setValues({
      ...values,
      length: {
        from: 0,
        unit: updatedUnit,
      },
      width: {
        from: 0,
        unit: updatedUnit,
      },
      height: {
        from: 0,
        unit: updatedUnit,
      },
    });
  };

  const handleWeightMetricUnits = (unit: string) => {
    const updatedUnit = unit as MassUnit;

    setValues({
      ...values,
      weight: {
        from: 0,
        unit: updatedUnit,
      },
    });
  };

  const distanceSelectAfterProps = {
    onChange: handleDistanceMetricUnits,
    options: distanceUnitOptions,
  };

  const massSelectAfterProps = {
    onChange: handleWeightMetricUnits,
    options: massUnitOptions,
  };


  useEffect(() => {
    let distanceConverter = (val: number | undefined) => (val || 0);
    let weightConverter = (val: number | undefined) => (val || 0);

    if (values?.length?.unit === 'mm') {
      distanceConverter = centiToMilli;
    } else if (values?.length?.unit === 'in') {
      distanceConverter = centiToInch;
    }

    if (values?.weight?.unit === 'lbs') {
      weightConverter = kgToLbs;
    }

    setMaxValues({
      length: distanceConverter(maxValuesMeta?.length),
      width: distanceConverter(maxValuesMeta?.width),
      height: distanceConverter(maxValuesMeta?.height),
      weight: weightConverter(maxValuesMeta?.weight),
    });
  }, [values?.length?.unit, values?.weight?.unit]);

  return (
    <div className="dimension-filters">
      <div className="product-filter-title dimension-filters__header">
        Dimensions
      </div>
      <div className="dimension-filters__main">
        <NumberRangeInput
          maxVal={maxValues?.length}
          label="Length"
          selectProps={{
            ...distanceSelectAfterProps,
            name: "length.unit",
            showSearch: false
          }}
          fromName="length.from"
          toName="length.to"
          symbol="cm"
        />
        <div className="hr-line" />
        <NumberRangeInput
          maxVal={maxValues?.width}
          label="Width"
          selectProps={{
            ...distanceSelectAfterProps,
            name: "width.unit",
            showSearch: false
          }}
          fromName="width.from"
          toName="width.to"
          symbol="cm"
        />
        <div className="hr-line" />
        <NumberRangeInput
          label="Height"
          maxVal={maxValues?.height}
          selectProps={{
            ...distanceSelectAfterProps,
            name: "height.unit",
            showSearch: false
          }}
          fromName="height.from"
          toName="height.to"
          symbol="cm"
        />
        <div className="hr-line" />
        <NumberRangeInput
          label="Weight"
          maxVal={maxValues?.weight}
          selectProps={{
            ...massSelectAfterProps,
            name: "weight.unit",
            showSearch: false
          }}
          fromName="weight.from"
          toName="weight.to"
          symbol="kg"
        />
      </div>
    </div>
  );
};

export default DimensionFilters;
