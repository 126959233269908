import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { Layer, LayerVariant } from "models/Layer/layer.model";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { serialize, deserialize } from "serializr";
import Notification from "shared/components/Notification";
import useRedirect from "shared/hooks/useRedirect";

const LayerService = () => {
    const [layer, setLayer] = useState<Layer>(new Layer());
    const [layerVariant, setLayerVariant] = useState<LayerVariant>();
    const [updatingPin, setUpdatingPin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { goBack } = useRedirect();
    const createLayer = async (prototypeId: string, planId: string, spaceId: string, payload: Layer) => {
        setLoading(true);
        try {
            const endPoint = generatePath(ApiRoutes?.ADD_LAYER, { prototypeId, planId, spaceId });
            const { data } = await axiosInstance.post(endPoint, { layer: serialize(Layer, payload) });
            const newLayer = deserialize(Layer, data["layer"]);
            setLayer(newLayer);

            Notification({
                message: `Created layer ${newLayer.title}`,
                type: NotificationTypes.SUCCESS,
            });

            return newLayer;
        }
        catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to create layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally {
            setLoading(false);
        }
    };
    const updateLayerDetails = (newLayer: Layer) => {
        setLayer(newLayer);
    };
    const updateLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string, payload: Layer) => {
        setLoading(true);
        try {
            const URL = generatePath(ApiRoutes?.LAYER, { prototypeId, planId, spaceId, layerId });
            const { data } = await axiosInstance.put(URL, { layer: serialize(Layer, payload) });
            const newLayer = deserialize(Layer, data["layer"]);
            setLayer(newLayer);

            Notification({
                message: `Updated layer ${newLayer.title}`,
                type: NotificationTypes.SUCCESS,
            });

            return newLayer;
        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to update layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally { setLoading(false); }
    };
    const deleteLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string) => {
        setLoading(true);
        try {
            const URL = generatePath(ApiRoutes?.LAYER, { prototypeId, planId, spaceId, layerId });
            await axiosInstance.delete(URL);
            setLayer(new Layer());
            Notification({
                message: `Deleted layer`,
                type: NotificationTypes.SUCCESS,
            });
        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to delete layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally { setLoading(false); }
    };
    const getLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string) => {
        setLoading(true);
        try {
            const endPoint = generatePath(ApiRoutes?.LAYER, { prototypeId, planId, spaceId, layerId });
            const { data } = await axiosInstance.get(endPoint);
            const layerDetails = deserialize(Layer, data['layer']);
            setLayer(layerDetails);
        }
        catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to get layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally {
            setLoading(false);
        }
    };
    const addProductToLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string, selectedVariantId: string) => {
        setLoading(true);
        try {
            const payload = {
                layer: {
                    products: [{ variant: selectedVariantId }]
                }
            };
            const { data } = await axiosInstance.patch(generatePath(ApiRoutes?.ADD_PRODUCT_TO_LAYER, { prototypeId, planId, spaceId, layerId }), payload);

            const updatedLayer = deserialize(LayerVariant, data["layer_variant"]);
            Notification({
                message: `Product added`,
                type: NotificationTypes.SUCCESS,
            });
            return updatedLayer;
        }
        catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to add product to layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally {
            setLoading(false);
            goBack();
        }
    };

    const replaceProductFromLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string, layerVariantId: string, selectedVariantId: string) => {
        try {
            setLoading(true);
            const payload = {
                layer: {
                    products: [{ variant: selectedVariantId }]
                }
            };
            const URL = generatePath(ApiRoutes?.LAYER_PRODUCTS, { prototypeId, planId, spaceId, layerId, layerVariantId });
            const { data } = await axiosInstance.patch(URL, payload);
            Notification({
                message: "Product replaced",
                type: NotificationTypes.SUCCESS,
            });
            return deserialize(LayerVariant, data['layer_variant']);

        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to replace product",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    };

    const removeProductFromLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string, layerVariantId: string) => {
        try {
            setLoading(true);
            const URL = generatePath(ApiRoutes?.LAYER_PRODUCTS, { prototypeId, planId, spaceId, layerId, layerVariantId });
            await axiosInstance.delete(URL);
            setLayer({
                ...layer,
                products: layer?.products?.filter(({ id }) => id !== layerVariantId)
            });
            Notification({
                message: "Product removed from layer",
                type: NotificationTypes.SUCCESS,
            });
            return true;
        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to remove product from layer",
                type: NotificationTypes.ERROR,
            });
        } finally {
            setLoading(false);
        }
    };
    const pinProductToLayer = async (prototypeId: string, planId: string, spaceId: string, layerId: string, layerVariantId: string, coordinate: Coordinate, pin = 'add') => {
        setUpdatingPin(true);
        try {
            const url = generatePath(ApiRoutes.PIN_PRODUCT_TO_LAYER, { prototypeId, planId, spaceId, layerId, layerVariantId });
            const payload = {
                coordinate,
                pin
            };
            const res = await axiosInstance?.patch(url, payload);
            Notification({
                message: `${pin === 'remove' ? 'Unpinned' : 'Pinned'} product ${pin === 'remove' ? 'from' : 'to'} the layer`,
                type: NotificationTypes.SUCCESS,
            });
            return (deserialize(LayerVariant, res?.data['product']));
        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to pin product to layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally {
            setUpdatingPin(false);
        }

    };
    const getLayerVariant = async (prototypeId: string, planId: string, spaceId: string, layerId: string, layerVariantId: string) => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get(generatePath(ApiRoutes?.LAYER_PRODUCTS, { prototypeId, planId, spaceId, layerId, layerVariantId }));
            setLayerVariant(deserialize(LayerVariant, data["layer_variant"]));
        } catch (ex) {
            Notification({
                message: (ex as Error)?.message || "Unable to pin product to layer",
                type: NotificationTypes.ERROR,
            });
        }
        finally {
            setLoading(false);
        }
    };
    return {
        layer,
        setLayer,
        loading,
        layerVariant,
        updatingPin,
        createLayer,
        updateLayer,
        getLayer,
        addProductToLayer,
        pinProductToLayer,
        getLayerVariant,
        removeProductFromLayer,
        deleteLayer,
        replaceProductFromLayer,
        updateLayerDetails
    };
};
export default LayerService;