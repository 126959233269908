import React from "react";

const RegisterForm = () => {
  return (
    <div>
            Register Page
    </div>
  );
};

export default RegisterForm;