import { DistanceUnit } from "enums/distanceUnit.enum";
import { Metric } from "../Metric/metric.model";
import { serializable, object } from "serializr";
import { MassUnit } from "enums/massUnit.enum";

export class Measurement {
  @serializable
  id?: string;

  @serializable(object(Metric))
  length? = new Metric(DistanceUnit.MM);

  @serializable(object(Metric))
  width? = new Metric(DistanceUnit.MM);

  @serializable(object(Metric))
  height? = new Metric(DistanceUnit.MM);

  @serializable(object(Metric))
  weight?: Metric<"mass"> = new Metric(MassUnit.POUND);
}
