import React from "react";
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from "antd";
import { Field, useField } from "formik";
import Label from "../Label";

import "./switch.scss";

interface SwitchProps extends AntSwitchProps {
  label?: string;
  checkedLabel?: string;
  unCheckedLabel?: string;
}

interface SwitchWithFormikProps extends SwitchProps {
  name: string;
}

const Switch = ({
  label,
  checkedLabel,
  unCheckedLabel,
  checked,
  ...props
}: SwitchProps) => {
  return (
    <div className="switch">
      <Label label={label} />
      <div className="switch__field">
        {checkedLabel && unCheckedLabel && (
          <span
            className={`switch__field__label switch__field__label--${(checked
              ? checkedLabel
              : unCheckedLabel
            )?.toLowerCase()}`}
          >
            {checked ? checkedLabel : unCheckedLabel}
          </span>
        )}
        <AntSwitch checked={checked} {...props} />
      </div>
    </div>
  );
};

const SwitchWithFormik = ({ name, ...props }: SwitchWithFormikProps) => {
  const [{ value }, , { setValue }] = useField(name);

  return <Field as={Switch} checked={value} onChange={setValue} {...props} />;
};

Switch.Formik = SwitchWithFormik;

export default Switch;
